import React from "react";
import styled from "styled-components";
// import LectureMediaHeader from "./LecturesHeader";
import LectureHeader from "./aa";
import { withRouter } from "react-router";
import LecturesVideo from "@/pages/GT_LecturesVideo";
import LecturesMobieVideo from "@/pages/GT_LecturesVideo/briefing";
import { useTranslation } from "react-i18next";
import SpecialtiesBriefing from "../../pages/GT_Specialtiesvideo";
import GTTodo from "../../pages/GT_Todo";

const LecturesStyle = styled.div`
  .button {
    margin-top: -2%;
    margin-bottom: 3%;
  }
  .showPC {
    display: block;
  }

  .showMedia {
    display: none;
  }
  @media (max-width: 768px) {
    .button {
      display: block;
      padding: 0 3%;
      margin-bottom: 26px;
    }
    .showPC {
      display: none;
    }

    .showMedia {
      display: block;
    }
  }
`;

function GT_Lectures(props) {
  const { t } = useTranslation();
  return (
    <LecturesStyle className="fix-margin-top">
      <LectureHeader {...props}></LectureHeader>
      <div className="showPC">
        <LecturesVideo />
      </div>

      <div className="showMedia">
        <LecturesMobieVideo />
      </div>

      {/* specailist视频组件 */}
      <div className="specialties-container">
        <SpecialtiesBriefing title={t("special.video-title")} />
      </div>
      <GTTodo />
    </LecturesStyle>
  );
}

export default withRouter(GT_Lectures);
