import { Tabs, Timeline } from "antd";
import React, { Component } from "react";
import styled from "styled-components";
import * as dayjs from "dayjs";
import { Translation } from "react-i18next";
import { ImageURL } from "../../../service/config";
import RewardNav from "../RewardNav";
import GTButton from "../../../pages/GT_Button";
import { getNews, getDict } from "../../../service/video";

const { TabPane } = Tabs;

const date = require("@/assets/img/Reward_tabs_date@2x.png");
const Image = function (pic) {
  return ImageURL + pic;
};
const name = (v1, v2, v3) => {
  if (localStorage.getItem("i18nextLng") === "zh-CN") {
    return v1;
  }
  if (localStorage.getItem("i18nextLng") === "en") {
    return v2;
  }
  return v3;
};
export default class RewardTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mypage: 1,
      mysize: 5,
      hasmore: false,
      data: [],
      type: "",
      count: 0,
      isShow: false,
      tabType: [],
    };
  }

  getCount = (e, t) => {
    return getNews(e, t, 10000).then((res) => {
      if (res) {
        this.setState({
          count: res.data.length,
        });
      }
    });
  };

  getData = (e, t) => {
    this.setState({
      mypage: e,
      type: t,
    });
    return getNews(e, t, this.state.mysize)
      .then((res) => {
        if (res.data.length < 5) {
          this.setState({
            isShow: true,
          });
        } else {
          this.setState({
            isShow: false,
          });
        }
        if (e === 1) {
          this.setState({
            hasmore: res.data.length === this.state.mysize,
            data: res.data,
          });
        } else {
          this.setState({
            data: this.state.data.concat(res.data),
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    this.getData(this.state.mypage, this.state.type);
    getDict("NEWS_TYPE").then((res) => {
      if (res.code === 0) {
        const li = [
          {
            code: "",
            id: "",
            nameEn: "All",
            nameZHCN: "全部",
            nameZHTW: "全部",
            parent: "NEWS_TYPE",
            remarks: "全部",
          },
        ];
        res.data.forEach((item) => {
          li.push(item);
        });
        this.setState({
          tabType: li,
        });
      }
    });
  }

  render() {
    const types = [
      "",
      "Housing_loan",
      "Commercial_loans",
      "Financial_Investment",
      "Insurance_Services",
      "Wending_field",
      "Corporate_Culture",
    ];
    const { mypage, type, data } = this.state;
    return (
      <Translation>
        {(t, index) => {
          return (
            <RewardTabsWrapper key={index}>
              {/* 导航面包屑 */}
              {/* <RewardNav /> */}
              <RewardTabsMobieWarpper>
                <Tabs
                  defaultActiveKey={0}
                  type={"card"}
                  tabBarGutter={20}
                  onTabClick={(e) => {
                    this.getData(1, e);
                  }}
                >
                  {this.state.tabType?.map((i, index) => {
                    return (
                      <TabPane
                        tab={name(i.nameZHCN, i.nameEn, i.nameZHTW)}
                        key={i.code}
                        className="minwidthstyle fontstyle_SPR"
                      >
                        {data &&
                          data.slice(0, 1).map((item, index) => {
                            return (
                              <div className="news-content" key={index}>
                                <div
                                  onClick={() => {
                                    this.props.history.push("/reward-detail", {
                                      id: item.id,
                                      title: item.title,
                                      updatedAt: item.updatedAt,
                                      content: item.content,
                                      desc: item.digest,
                                    });
                                  }}
                                >
                                  <img
                                    src={Image(item.pic)}
                                    alt=""
                                    className="news-bgc"
                                  />
                                  <div className="news-title">{item.title}</div>
                                </div>

                                <div
                                  className="separation-line"
                                  style={{ display: "none" }}
                                ></div>
                              </div>
                            );
                          })}
                        <Timeline mode={"left"}>
                          {data &&
                            data.slice(1).map((item, index) => {
                              return (
                                <Timeline.Item
                                  color="#F33500"
                                  onClick={(e) => {
                                    this.props.history.push("/reward-detail", {
                                      id: item.id,
                                      title: item.title,
                                      content: item.content,
                                      updatedAt: item.updatedAt,
                                      desc: item.digest,
                                    });
                                  }}
                                  key={index}
                                >
                                  <div className="content">
                                    <div className="p_left">
                                      <img src={Image(item.pic)} alt="" />
                                    </div>

                                    <div className="p_right">
                                      <div className="title">{item.title}</div>
                                      <div className="article_info">
                                        <div className="p_article_data">
                                          <img src={date} alt="" />
                                          <p>
                                            {dayjs(item.updatedAt)
                                              .locale("zh-CN")
                                              .format("MM-DD-YYYY")}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Timeline.Item>
                              );
                            })}
                        </Timeline>
                        {this.state.isShow === false ? (
                          <div style={{ padding: "5% 0 0 0" }}>
                            <GTButton
                              changeLink={(e) => {
                                this.getData(mypage + 1, type);
                              }}
                              mobieTitle={"home.join_props_title"}
                              poscenter={"center"}
                            />
                          </div>
                        ) : null}
                      </TabPane>
                    );
                  })}
                </Tabs>
              </RewardTabsMobieWarpper>
            </RewardTabsWrapper>
          );
        }}
      </Translation>
    );
  }
}

const RewardTabsWrapper = styled.div`
  padding-bottom: 50px;
  .nav {
    height: 53px;
    display: flex;
    align-items: center;
    margin: 35px 0;

    .ant-breadcrumb-separator {
      margin: 0 20px;
    }
    .active {
      color: #f33500;
    }
  }
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const RewardTabsMobieWarpper = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    margin-bottom: 4%;
    .ant-tabs-nav {
      padding: 10px 0;
      &:before {
        border: 0 !important;
      }
    }
    .ant-tabs-nav-list {
      padding-right: 10px;
      .ant-tabs-tab {
        margin-left: 10px !important;
        border-radius: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
        /* 新闻详情Tab组件导航栏个体宽度样式修改 */
        min-width: 100px;
        height: 30px;
        padding: 18px 10 px !important;
        border: #ccc 1px solid !important;
        font-size: 14px;
        line-height: 19px;
        color: #444953;
        &:hover {
          color: #444953 !important;
        }
      }
      .ant-tabs-tab-active {
        background-color: #f33500 !important;
        border: 1px solid #f33500 !important;
        .ant-tabs-tab-btn {
          color: #fff !important;
        }
      }
    }

    .ant-tabs-content-holder {
      .ant-tabs-content {
        margin: 0 auto;
        width: 92%;
        .news-content {
          width: 100%;
          .news-bgc {
            width: 100%;
            height: 203px;
          }
          .news-title {
            font-size: 24px;
            line-height: 30px;
            max-height: 2.5em;
            overflow: hidden;
            margin: 5px 0;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .separation-line {
            display: block !important;
            height: 2px;
            background-color: #ececec;
            margin: 20px 0;
            margin-bottom: 30px;
          }
        }

        .ant-timeline {
          .ant-timeline-item {
            margin-top: 15px;
            padding: 0;
            .ant-timeline-item-tail {
              top: 5px;
              left: 10px;
              border: 0;
              width: 10px;
            }
          }
        }

        .content {
          display: flex;
          margin: 0;
          padding-top: 10px;

          .p_left {
            flex-basis: 120px;
            height: 67.18px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .p_right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 20px;
            flex: 1;
            .title {
              max-height: 2.5em;
              overflow: hidden;
              line-height: 1.25em;
              font-size: 14px;
              margin: 3px 0;
              display: -webkit-box;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            .article_info {
              display: flex;
              justify-content: flex-start;
              background-color: #f7f7f7;
              margin-top: 5px;
              height: 25px;
              .red_text {
                color: #f33500;
              }
              .p_article_data {
                flex: 1;
                display: flex;
                align-items: center;
                padding-left: 3%;
                height: 100%;
                img {
                  display: inline-block;
                  height: 12px;
                  width: 12px;
                }
                p {
                  font-size: 12px;
                  line-height: 22px;
                  margin-left: 10px;
                }
              }

              .p_article_looks {
                flex: 1;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                p {
                  font-size: 12px;
                  line-height: 22px;
                }
                .buai {
                  display: flex;
                  justify-content: space-evenly;
                  align-items: center;
                }

                .l-list {
                  img {
                    width: 15px;
                    height: 11px;
                  }
                  p {
                    margin-left: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
