import React, { useEffect, useState } from "react";
import { Tabs, Timeline } from "antd";
import styled from "styled-components";
import * as dayjs from "dayjs";
import GTTitle from "../../../pages/GT_Title";
import { useTranslation } from "react-i18next";
import { ImageURL } from "@/service/config";
import GTButton from "../../../pages/GT_Button";
import { getNews, getDict } from "../../../service/video";
const Image = function (pic) {
  return ImageURL + pic;
};
const { TabPane } = Tabs;

const date = require("@/assets/img/Reward_tabs_date@2x.png");
const line = require("@/assets/img/Reward_tabs_line@2x.png");
const title_line = require("../../../assets/img/Title_left@2x.png");

const RewardTabsWrapper = styled.div`
  /* padding: 50px 0; */
  padding-top: 50px;
  .nav {
    height: 53px;
    display: flex;
    align-items: center;
    margin: 35px 0;

    .ant-breadcrumb-separator {
      margin: 0 20px;
    }
    .active {
      color: #f33500;
    }
  }

  .reward-tab-title {
    padding: 25px 0;
    .title {
      font-family: SossBo;
      margin-bottom: 10px;
      font-size: 48px;
      line-height: 64px;
      color: #06325e;
    }

    .redline {
      width: 490px;
    }
  }

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const RewardTabarsWarpper = styled.div`
  display: block;

  .ant-tabs-nav {
    padding-top: 5px;
    &:before {
      border: 0 !important;
    }
  }
  .ant-tabs-nav-wrap {
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        border-radius: 0 !important;
        border: #ccc 2px solid !important;
        margin-left: 0 !important;
        margin-right: 15px;
        min-width: 129px;
        justify-content: center;
        background-color: transparent;

        &:hover {
          color: #444953 !important;
        }
      }
      .ant-tabs-tab-active {
        background-color: #f33500;
        border: 1px solid #f33500 !important;
        .ant-tabs-tab-btn {
          color: #fff;
        }
      }
    }
  }
  .ant-tabs-content-holder {
    font-family: SossProRe;
  }

  .ant-tabs-tabpane {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 40px;
    .news-content {
      margin-right: 60px;
      width: 70%;
      .news-bgc {
        width: 100%;
        height: 299px;

        img {
          width: 100%;
          height: 100%;
        }
      }
      .new-word {
        padding: 0 20px;
        margin: 20px 0;
        .news-title {
          color: black;
          font-size: 24px;
          line-height: 32px;
          height: 1.45em;
          overflow: hidden;
          padding: 0 10px 2px 0;
          border-bottom: 1px solid #ececec;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .news-desc {
          height: 2em;
          margin: 10px 0;
          font-size: 14px;
          line-height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
    }
    .ant-timeline {
      width: 100%;
      .ant-timeline-item {
        .ant-timeline-item-content {
          margin: 0;
          .content {
            display: flex;
            margin-left: 55px;
            margin-bottom: 39px;

            .p_left {
              flex-basis: 209px;
              height: 117px;
              img {
                width: 100%;
                height: 100%;
              }
            }

            .p_right {
              padding-left: 20px;
              flex: 1;
              .title {
                color: black;
                font-size: 22px;
                line-height: 28px;
                font-weight: 500;
                height: 1.2em;
                margin: 1% 0;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
              }

              .desc {
                font-size: 14px;
                line-height: 22px;
                height: 3em;
                overflow: hidden;
                margin: 3% 0;
                display: -webkit-box;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
              .article_info {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 33px;
                background-color: #f7f7f7;
                padding-left: 10px;

                .p_article_data {
                  width: 30%;
                  img {
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                  }
                  span {
                    font-size: 12px;
                    color: #8b8b8b;
                    margin-left: 10px;
                  }
                }
                .p_article_look {
                  width: 25%;
                  .looks {
                    height: 13px;
                  }

                  .space {
                    display: inline;
                    margin: 0 3%;
                  }
                  span {
                    margin: 0 5%;
                  }

                  .line {
                    height: 5px;
                  }

                  .red_text {
                    margin-right: 10px;
                    color: #f33500;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const RewardTabsMobieWarpper = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    .ant-tabs {
      background-color: white;
      .ant-tabs-nav {
        &:before {
          border: 0 !important;
        }
      }
    }
    .ant-tabs-nav-list {
      padding: 10px 0;
      padding-right: 10px;
      .ant-tabs-tab {
        border-radius: 0 !important;
        margin-left: 10px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        /* 新闻详情Tab组件导航栏个体宽度样式修改 */
        min-width: 100px;
        height: 30px;
        padding: 18px 10px !important;
        border: #cccccc 1px solid !important;
        font-size: 14px;
        background-color: transparent !important;
        line-height: 19px;
        color: #444953;
        &:hover {
          color: #444953 !important;
        }
      }
      .ant-tabs-tab-active {
        background-color: #f33500 !important;
        border: 1px solid #f33500 !important;
        .ant-tabs-tab-btn {
          color: #fff !important;
        }
      }
    }

    .ant-tabs-content-holder {
      .ant-tabs-content {
        /* margin: 0 4%; */
        margin: 0 auto;
        width: 92%;
        .news-content {
          width: 100%;
          .news-bgc {
            width: 100%;
            height: 203px;
          }
          .news-title {
            font-size: 24px;
            line-height: 30px;
            max-height: 2.5em;
            overflow: hidden;
            margin-top: 10px;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .separation-line {
            display: block !important;
            height: 2px;
            background-color: #ececec;
            margin: 20px 0;
          }
        }

        .ant-timeline {
          .ant-timeline-item {
            margin-top: 15px;
            padding: 0;
            .ant-timeline-item-tail {
              top: 5px;
              left: 10px;
              border: 0;
              width: 10px;
            }
          }
        }

        .content {
          display: flex;
          margin: 0;
          padding-top: 10px;

          .p_left {
            flex-basis: 120px;
            height: 67.18px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .p_right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 20px;
            flex: 1;
            .title {
              max-height: 2.5em;
              overflow: hidden;
              line-height: 1.25em;
              font-size: 14px;
              display: -webkit-box;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            .article_info {
              display: flex;
              justify-content: flex-start;
              background-color: #f7f7f7;
              flex-wrap: nowrap;
              height: 25px;
              .p_article_data {
                display: flex;
                align-items: center;
                padding-left: 3%;
                flex-wrap: nowrap;
                height: 100%;
                img {
                  display: inline-block;
                  height: 12px;
                  width: 12px;
                }
                p {
                  height: 22px;
                  font-size: 12px;
                  line-height: 22px;
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default function HomeReward(props) {
  const { t } = useTranslation();
  const [mysize, setMysize] = useState(5);
  const [datas, setDatas] = useState([]);
  const [type, setType] = useState("");
  const [tabList, setTabList] = useState([]);

  const name = (v1, v2, v3) => {
    if (localStorage.getItem("i18nextLng") === "zh-CN") {
      return v1;
    }
    if (localStorage.getItem("i18nextLng") === "en") {
      return v2;
    }
    return v3;
  };
  const getData = (e, t) => {
    setType(t);
    return getNews(e, t, mysize)
      .then((res) => {
        setDatas(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData(1, type);
    getDict("NEWS_TYPE").then((res) => {
      if (res.code === 0) {
        const li = [
          {
            code: "",
            id: "",
            nameEn: "All",
            nameZHCN: "全部",
            nameZHTW: "全部",
            parent: "NEWS_TYPE",
            remarks: "全部",
          },
        ];
        res.data.forEach((item) => {
          li.push(item);
        });
        setTabList(li);
      }
    });
  }, []);
  const types = [
    "",
    "Housing_loan",
    "Commercial_loans",
    "Financial_Investment",
    "Insurance_Services",
    "Wending_field",
    "Corporate_Culture",
  ];
  // 新闻请求类型链接数组
  return (
    <RewardTabsWrapper>
      <RewardTabarsWarpper className="wrap-v1">
        <div className="reward-tab-title wrap-v1">
          <div className="title">{t("reward.title")}</div>
          <img src={title_line} alt="" className="redline" />
        </div>

        <Tabs
          defaultActiveKey={0}
          type={"card"}
          tabBarGutter={20}
          onTabClick={(e) => getData(1, e)}
        >
          {tabList?.map((i, index) => {
            return (
              <TabPane
                tab={name(i.nameZHCN, i.nameEn, i.nameZHTW)}
                key={i.code}
                className="minwidthstyle fontstyle_SPR  "
              >
                {datas &&
                  datas.slice(0, 1).map((item, index) => {
                    return (
                      <div className="news-content" key={index}>
                        <div
                          className="news-bgc cursor"
                          onClick={() => {
                            props.history.push("/reward-detail", {
                              id: item.id,
                              title: item.title,
                              content: item.content,
                              updatedAt: item.updatedAt,
                              desc: item.digest,
                            });
                          }}
                        >
                          <img src={Image(item.pic)} alt="" />
                        </div>
                        <div
                          className="new-word"
                          onClick={() => {
                            props.history.push("/reward-detail", {
                              id: item.id,
                              title: item.title,
                              content: item.content,
                              updatedAt: item.updatedAt,
                              desc: item.digest,
                            });
                          }}
                        >
                          <div className="news-title">{item.title}</div>
                          <div className="news-desc colorgrey">
                            {item.digest}
                          </div>
                        </div>
                        <div className="move-button">
                          <GTButton
                            changeLink={() => {
                              props.history.push("/reward-promotion");
                            }}
                            mobieTitle="routes-media.watch"
                          />
                        </div>
                      </div>
                    );
                  })}
                <Timeline mode={"left"}>
                  {datas &&
                    datas.slice(1, 4).map((item, index) => {
                      return (
                        <Timeline.Item
                          color="#F33500"
                          onClick={() => {
                            props.history.push("/reward-detail", {
                              id: item.id,
                              title: item.title,
                              content: item.content,
                              updatedAt: item.updatedAt,
                              desc: item.digest,
                            });
                          }}
                          key={index}
                        >
                          <div className="content">
                            <div className="p_left cursor">
                              <img src={Image(item.pic)} alt="" />
                            </div>

                            <div className="p_right">
                              <div className="title">{item.title}</div>
                              <div className="desc colorgrey">
                                {item.digest}
                              </div>
                              <div className="article_info">
                                <div className="p_article_data">
                                  <img src={date} alt="" />
                                  <span>
                                    {dayjs(item.updatedAt)
                                      .locale("zh-CN")
                                      .format("MM-DD-YYYY")}
                                  </span>
                                </div>
                                <div className="p_article_look">
                                  <span className="red_text cursor">
                                    {t("home.join_props_small_title")}
                                  </span>
                                  <img src={line} alt="" className="line" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Timeline.Item>
                      );
                    })}
                </Timeline>
              </TabPane>
            );
          })}
        </Tabs>
      </RewardTabarsWarpper>

      <RewardTabsMobieWarpper>
        <GTTitle mobieTitle="reward.title" />
        <Tabs
          defaultActiveKey={0}
          type={"card"}
          tabBarGutter={20}
          onTabClick={(e) => getData(1, e)}
        >
          {tabList?.map((i, index) => {
            return (
              <TabPane
                tab={name(i.nameZHCN, i.nameEn, i.nameZHTW)}
                key={i.code}
                className="minwidthstyle fontstyle_SPR"
              >
                {datas &&
                  datas.slice(0, 1).map((item, index) => {
                    return (
                      <div className="news-content" key={item.id}>
                        <img
                          src={Image(item.pic)}
                          alt=""
                          className="news-bgc"
                          onClick={() => {
                            props.history.push("/reward-detail", {
                              id: item.id,
                              title: item.title,
                              content: item.content,
                              updatedAt: item.updatedAt,
                              desc: item.digest,
                            });
                          }}
                        />
                        <div className="news-title">{item.title}</div>
                        <div
                          className="separation-line"
                          style={{ display: "none" }}
                        ></div>
                      </div>
                    );
                  })}
                <Timeline mode={"left"}>
                  {datas &&
                    datas.slice(1, 4).map((item, index) => {
                      return (
                        <Timeline.Item
                          color="#F33500"
                          onClick={(e) => {
                            props.history.push("/reward-detail", {
                              id: item.id,
                              title: item.title,
                              content: item.content,
                              updatedAt: item.updatedAt,
                              desc: item.digest,
                            });
                          }}
                          key={item.id}
                        >
                          <div className="content">
                            <div className="p_left cursor">
                              <img src={Image(item.pic)} alt="" />
                            </div>

                            <div className="p_right">
                              <div className="title">{item.title}</div>
                              <div className="article_info">
                                <div className="p_article_data">
                                  <img src={date} alt="" />
                                  <p>
                                    {" "}
                                    {dayjs(item.updatedAt)
                                      .locale("zh-CN")
                                      .format("MM-DD-YYYY")}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Timeline.Item>
                      );
                    })}
                </Timeline>
              </TabPane>
            );
          })}
        </Tabs>
      </RewardTabsMobieWarpper>

      <div className="specia-button">
        <GTButton
          changeLink={(e) => {
            props.history.push("/reward-promotion");
          }}
          mobieTitle="routes-media.watch"
          poscenter={"center"}
        />
      </div>
    </RewardTabsWrapper>
  );
}
