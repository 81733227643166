import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import YouTube from "react-youtube";
import styled from "styled-components";

import GTModalVideo from "@/pages/GT_ModalVideo";

const homebgc = require("@/assets/img/home_banner.jpg");
const line = require("@/assets/img/Home_line@2x.png");
const position_bgc = require("@/assets/img/Home_Position_bgc@2x.png");
const play_icon = require("../../../assets/img/home-video-play@2x.png");

const HomeHeaderStyle = styled.div`
  position: relative;
  .homebgc {
    width: 100%;
    min-height: 820px;
    object-fit: cover;
  }

  .container {
    color: #002369;

    .item {
      position: absolute;
      top: 15%;
      background: rgba(0, 0, 0, 0.4);
      color: #fff;
      width: ${window.localStorage.getItem("i18nextLng") === "en"
        ? "60%"
        : "40%"};
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 30px;
      .img_style {
        width: 100px;
        height: 4px;
      }
      .title {
        width: 100%;
        font-family: SossBo;
        font-size: 72px;
        line-height: 80px;
      }
      .border_top_left {
        width: 34px;
        height: 34px;
        border-left: 1px solid #fff;
        border-top: 1px solid #fff;
        position: absolute;
        top: 10px;
        left: 10px;
      }
      .border_bottom_right {
        width: 34px;
        height: 34px;
        border-right: 1px solid #fff;
        border-bottom: 1px solid #fff;
        position: absolute;
        bottom: 10px;
        right: 10px;
      }
      .border {
        width: 100%;
        height: 1px;
        margin: 15px 0 20px 0;
        position: relative;
        background: rgba(255, 255, 255, 0.5);
        .border_red {
          width: 140px;
          height: 4px;
          position: absolute;
          background: #fff;
          top: -2px;
        }
      }
      .desc {
        width: 100%;
        overflow: hidden;
        font-size: 18px;
        line-height: 24px;
      }
    }
    .position_bgc {
      position: absolute;
      /* position: relative; */
      bottom: -90px;
      /* height: 386px; */
      cursor: pointer;
      z-index: 10;
      border-radius: 25px;
      border: 20px solid #fff;
      background: #fff;
      // left:60px;
      .video-img {
        width: 900px;
        height: calc(900px * 0.5625);
        position: relative;
        > div {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          > iframe {
            width: 100%;
            height: 100%;
            border-radius: 25px;
          }
        }
        .position {
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          display: none;
        }
        .play-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-40%, -50%);
          width: 92px;
          height: 92px;
          display: none;
        }
      }
    }
  }
  @media (min-width: 1410px) and (max-width: 1600px) {
    .container {
      .item {
        .title {
          font-size: 60px;
        }
      }
      .position_bgc {
        .video-img {
          width: 580px;
          height: calc(580px * 0.5625);
        }
      }
    }
  }
  @media (min-width: 800px) and (max-width: 1400px) {
    .container {
      .item {
        .title {
          font-size: 55px;
        }
      }
      .position_bgc {
        .video-img {
          width: 580px;
          height: calc(580px * 0.5625);
        }
      }
    }
  }
  @media (max-width: 768px) {
    .homebgc {
      min-height: auto;
      height: 240px;
    }
    .container {
      .item {
        top: 6%;
        left: 4%;
        width: 92%;
        color: #fff;
        padding: ${window.localStorage.getItem("i18nextLng") === "en"
          ? "20px"
          : null};
        .img_style {
          width: 19px;
          height: 3px;
        }
        .border {
          margin: ${window.localStorage.getItem("i18nextLng") === "en"
            ? "10px 0 10px 0"
            : null};
          .border_red {
            width: 33px;
          }
        }
        .title {
          font-size: 16px;
          line-height: 1.25em;
        }
        .border_top_left {
          width: 20px;
          height: 20px;
        }
        .border_bottom_right {
          width: 20px;
          height: 20px;
        }
        .desc {
          margin-top: ${window.localStorage.getItem("i18nextLng") === "en"
            ? "-10px"
            : null};
          font-size: 12px;
          line-height: 18px;
          width: 100%;
          // height: 4.5em;
          color: #fff;
          transform: scale(0.8);
          transform-origin: left;
        }
      }
      .position_bgc {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 17px 4%;
        position: static;
        height: 204px;
        box-sizing: border-box;
        border: 0;
        .video-img {
          height: 100%;
          border-radius: 13px;
          position: relative;
          border: 10px solid #fff;
          box-sizing: border-box;
          box-shadow: 11px 6px 17px 11px #dae4f5;
          .position {
            height: 100%;
            display: block;
          }
          .play-icon {
            height: 49px;
            width: 49px;
            display: block;
          }
        }
      }
    }
  }
  @media (max-width: 333px) {
    .position_bgc {
      max-height: 163px;
    }
  }
  @media (max-width: 290px) {
    .homebgc {
      height: 270px;
    }
  }
`;

export default function HomeHeader(props) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [playV, setplayV] = useState();

  function playVideo() {
    const clientWidth = document.body.clientWidth;
    if (clientWidth <= "768") {
      props.history.push("/video-detail", {
        info: { link: "https://youtu.be/ucFKYUsCDds", title: t("title") },
      });
    } else {
      setShow(true);
    }
  }
  const opts = {
    width: "100%",
    playerVars: {
      autoplay: 1,
      rel: 0,
      mute: 1,
      loop: 1,
      playlist: "hvFwlnPCuP4",
    },
  };
  const _onReady = (event) => {
    setplayV(event.target);
  };
  const changeVideo = (e) => {
    console.log(e);
  };
  useEffect(() => {
    return () => {
      console.log(playV);
      // playV.pauseVideo();
    };
  }, []);
  return (
    <HomeHeaderStyle>
      <img src={homebgc} alt="" className="homebgc" />
      <div className="wrap-v1">
        <div className="container">
          <div className="item">
            <div className="border_top_left"></div>
            {/* <img src={line} alt="" className="img_style" /> */}
            <div className="title">{t("home.header_title1")}</div>
            <div className="border">
              <div className="border_red"></div>
            </div>
            <div className="desc">{t("home.header_desc1")}</div>
            <div className="border_bottom_right"></div>
          </div>
          <div className="position_bgc">
            <div
              className="video-img"
              onClick={(e) => {
                // playVideo();
              }}
              id="homeVideoBox"
            >
              <YouTube
                videoId={"hvFwlnPCuP4"}
                opts={opts}
                onReady={_onReady}
                onStateChange={changeVideo}
              />
              {/* <img className="position" src={position_bgc} alt="" />
              <img src={play_icon} alt="" className="play-icon" /> */}
            </div>
          </div>
        </div>

        <GTModalVideo
          videourl={{ link: "https://youtu.be/ucFKYUsCDds", title: t("title") }}
          show={show}
          setShow={setShow}
          id="homeHeader"
        />
      </div>
    </HomeHeaderStyle>
  );
}
