import styled from "styled-components";

const FooterWrapper = styled.div`
padding-top: 30px;
.addressstyle{
  width: 48px;
  height: 48px;

}

.contactstyle{
  width: 50px;
  height: 48px;
}

.messagestyle{
  width: 44px;
  height: 32px;
}

  @media (max-width:768px){
    padding: 0;
  }
`

const FooterMain = styled.div`
padding-bottom: 127px;
display: flex;
justify-content: space-between;

.form {
  flex: 3;
  display: flex;
  flex-wrap: wrap;

  .input-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
	margin-bottom:20px;
    input {
      width: 32%;
      padding: 18px;
      border-radius: 10px;
      background-color: rgba(255,255,255,.6);
      &::placeholder {
        color: #4d4d4d;
        font-size: 16px;
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    }
    }
  }

  textarea {
    width: 100%;
    margin:0 0 20px 0px;
    border-radius: 10px;
    resize: none;
    padding: 18px;
    background-color: rgba(255,255,255,.6);
    &::placeholder {
      color: #4d4d4d;
      line-height: 32px;
      font-size: 16px;
    }
  }
  .gt_footer_button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f33500;
    padding: 18px;
    border-radius: 10px;
    cursor: pointer;
    flex-wrap: nowrap;
    .title {
      font-size: 20px;
      color: #fff;
    }
  }
}

.fd-nav {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding-right: 20px;

  .fd-list {
    display: grid ;
    grid-template-rows: [r1-start] 50px [r1-end r2-start] 70px [r2-end];
    grid-template-columns: [c1-start] 80px [c1-end c2-start] 280px [c2-end];

    .font_opcticy{
      opacity: .5;
    }
    div:first-child {
      grid-row-start: r1-start;
      grid-row-end: r2-end;
      grid-column-start: c1-start;
      grid-column-end: c1-end;
      img{
        /* width: 48px;
        height: 48px; */
        margin: 10px;
        margin-top: 20px;

      }
    }

    div:nth-child(2) {
      font-size: 24px;
      font-weight: bold;
      color: #fff;
    }

    div:nth-child(3) {
      font-size: 16px;
      line-height: 24px;
      color: #fff;
      /* padding: 10px 0; */
    }
  }
}

@media (max-width:768px){
  flex-direction: column;
  margin: 0 4%;
  padding: 8% 0;
  padding-bottom:4%;
  .form{
    margin: 3% 0;
    margin-bottom: 10%;
    .input-group{
      flex-direction: column;
      input{
        width: auto;
        height: 60px;
        /* opacity:.6; */
        margin-bottom: 3%;
        border: 1px solid #fff;
      }
    }

    textarea{
      overflow: hidden;
      margin: auto;
      height: 100px;
      border: 1px solid #fff;
    }

    .gt_footer_button{
      margin-top: 6%;
      justify-content: center;
      align-items: center;

      .title{
        flex-basis: 20%;
        font-size: 14px;
        line-height: 19px;
        height: 19px;
        padding-right: 4%;
      }

      img{
        display: inline-block;
        width: 30px;
        height: 6px;
      }
    }
  }

  .fd-nav{
    align-items: flex-start;
  }
}
@media (max-width:360px){

  .fd-nav {
  .fd-list {
    display: grid ;
    grid-template-rows: [r1-start] 40px [r1-end r2-start] 70px [r2-end];
    grid-template-columns: [c1-start] 70px [c1-end c2-start] 260px [c2-end];

    .font_opcticy{
    }
    div:first-child {

      img{
       margin-top: 10px;

      }
    }

    div:nth-child(2) {
      font-size:19px;
    }

    div:nth-child(3) {
        font-size: 15px;
    }
  }
  }
}

@media (max-width:333px){

  .form{
    textarea{
      padding: 10px 18px;
      &::placeholder {
      line-height: 1.5em;
      }
    }
  }
  .fd-nav {
  .fd-list {
    display: grid ;
    grid-template-rows: [r1-start] 40px [r1-end r2-start] 70px [r2-end];
    grid-template-columns: [c1-start] 80px [c1-end c2-start] 230px [c2-end];

    .font_opcticy{
    }
    div:first-child {

      img{
       margin-top: 0;

      }
    }

    div:nth-child(2) {
      font-size:20px;
    }

    div:nth-child(3) {
        font-size: 13px;
    }
  }
  }
}
`;

const FooterMore = styled.div`
height: 80px;
background-color: #161c36;

.footer {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .footer-left {
    font-size: 18px;
    color: #fff;
    display: flex;
    align-items:center;
    .footer_copyright{
      margin-left: 20px;
      &_link{
        text-decoration: underline;
        color:#2575F2;
      }
    }
  }


  .footer-right {
    padding-right: 20px;
    width: 25%;
    ul {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      li {
        cursor: pointer;
        margin-left:20px;
        &:nth-of-type(5){
          display: block;
        }
        img {
          width: 36px;
          height: 36px;
        }
      }
    }
  }
}

@media (max-width:768px){
  height: auto;
  .footer{
    height: auto;
    flex-direction: column-reverse;
    justify-content: center;

    .footer-left{
      text-align: center;
      font-size: 14px;
      line-height: 22px;
      max-width: 60%;
      padding-bottom: 3%;
      flex-direction: column;
      .footer_copyright{
        margin-left:0;
      }
    }

    .footer-right{
      justify-content: space-between;
      width:90%;
      padding-right: auto;
      padding: 5% 0;

      ul{
        li{
          &:nth-of-type(5){
          display: none;
          }
          img{
            width: 48px;
            height: 48px;
          }
        }
      }
    }
  }
}

@media (max-width:441px){

  .footer{
    .footer-left{
      max-width: 75%;
    }
  }
}
@media (max-width:320px){

  .footer{
    .footer-left{
      max-width: 80%;
    }
  }
}
`;

export {
  FooterWrapper,
  FooterMore,
  FooterMain
}