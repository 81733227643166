import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const logo = require("@/assets/img/Testimonials_Textlogo@2x.png");
const avils = require("@/assets/img/Testmonials_textavils-01.png");
const line = require("@/assets/img/Testmonials_Textline@2x.png");
const dropdown = require("@/assets/img/Home_Test_DropDown@2x.png");
const GTTestTextStyle = styled.div`
  padding-left: 4%;

  &:last-child {
    padding-right: 4%;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 206px;
    background-color: #fff;
    padding: 10px 0;
    .title {
      font-size: 18px;
      line-height: 36px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      .logo {
        position: absolute;
        top: 30%;
        left: -30%;
        width: 14px;
        height: 12px;
      }
      .line {
        height: 3px;
        width: 18px;
        margin: 0;
      }
    }

    .avils {
      border-radius: 50%;
      width: 64px;
      height: 64px;
    }
    .name {
      color: #707070;
      font-size: 18px;
      line-height: 24px;
      margin: 5% 0;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      .desc {
        position: relative;
        display: -webkit-box;
        overflow: hidden;
        margin: 10px 5%;
        font-size: 14px;
        line-height: 22px;
        text-overflow: ellipsis;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
      .btn {
        color: #fff;
        font-size: 16px;
        border-radius: 4px;
        cursor: pointer;
      }

      .exp-text {
        display: none;
      }
      .exp-text:checked + .desc {
        -webkit-line-clamp: 999;
      }
      .exp-text:checked ~ .avils {
        /* -webkit-line-clamp: 999; */
        display: none;
      }
      .exp-text:checked ~ .name {
        /* -webkit-line-clamp: 999; */
        display: none;
      }
    }
    .dropdown {
      display: block;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 0;
      padding-top: 5px;
      img {
        width: 65px;
        height: 16px;
      }
    }
  }
`;
export default function TestimonialsSmallText(props) {
  const { t } = useTranslation();
  const { index } = props;
  console.log(props);
  return (
    <GTTestTextStyle>
      <div className="container">
        <div className="title">
          <div className="fontstyle_SB">
            {/* t("testimonials.small-title.title")}{" " */}
            {props.list.name}
          </div>
          <img src={logo} alt="" className="logo" />
          <img src={line} alt="" className="line" />
        </div>
        <div className="wrapper">
          <input type="checkbox" id={"exp" + index} className="exp-text" />
          <div className="desc">
            {props.list.text}
            {/* t("testimonials.small-title.desc") */}
          </div>
          <label className="btn" htmlFor={"exp" + index}>
            <div className="dropdown">
              <img src={dropdown} alt="" />
            </div>
          </label>
          <img src={props.list.pic} alt="" className="avils" />
          <div className="name">
            {props.list.name}
            {/* t("testimonials.small-title.name") */}
          </div>
        </div>
      </div>
    </GTTestTextStyle>
  );
}
