import React, { useState } from "react";
import styled from "styled-components";
import GTTitle from "@/pages/GT_Title";
import { useTranslation } from "react-i18next";
import GTModalVideo from "@/pages/GT_ModalVideo";
const video_play = require("../../../assets/img/Testimonials_video_play@2x.png");
const img = require("@/assets/img/Specialties_img1@2x.jpg");

const HeaderStyle = styled.div`
  height: 300px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
  
  @media (max-width: 333px) {
    .content {
      .title-words {
        .title {
          font-size: 28px;
        }
  
        .how_title {
          font-size: 20px;
        }
      }
  
      .flex-desc {
        .desc {
          font-size: 16px;
          line-height: 25px;
        }
      }
    }
  }
`;

export default function RewardHeader(props) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  function playVideo() {
    const clientWidth = document.body.clientWidth;
    if (clientWidth <= "768") {
  	props.history.push("/video-detail", {});
    } else {
      setShow(true);
    }
  }
  return (
    <HeaderStyle className="sprite_03">
      <GTTitle
        title={t("reward.title")}
        position="left"
        size="70"
        color="#fff"
        showWhiteBgc={true}
      />
    </HeaderStyle>
  );
}
