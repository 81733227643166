import React from "react";
import { Breadcrumb } from "antd";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

const RewardNavWrapper = styled.div`
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
`;

export default function RewardNav() {
  const { t } = useTranslation();

  return (
    <RewardNavWrapper>
      <div className="nav sprite_04">
        <div className="wrap-v1">
          <Breadcrumb separator={<div style={{ width: "8px", height: "8px", borderRadius: '50%', border: "1px solid red", display: 'inline-block' }}></div>}>
            <Breadcrumb.Item href="/home" className="mian">
              {t("reward.home")}
            </Breadcrumb.Item>
            <Breadcrumb.Item className="active">
              {t("reward.title")}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
    </RewardNavWrapper>
  );
}
