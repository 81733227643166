import React, { useState, useEffect, useRef } from "react";
import GTTitle from "@/pages/GT_Title";
import GTButton from "@/pages/GT_Button";
import GTModalVideo from "@/pages/GT_ModalVideo";
import styled from "styled-components";
import { home_testimonals_info } from "../../../mocks/leter_data";
import { useTranslation } from "react-i18next";
const video_play = require("../../../assets/img/Video-play@2x.png");
const rightbgc = require("@/assets/img/audio1.jpg");
const video_white_play = require("../../../assets/img/Video_white_play@2x.png");
const audioSrc = require("../../../assets/img/Recording 1_Max Ou.mp3");
const audioSrc2 = require("../../../assets/img/Recording_2_May Yin.mp3");
const audioSrc3 = require("../../../assets/img/Recording 3_Ted Ip.mp3");
const audioSrc4 = require("../../../assets/img/Recording 4_Ada.mp3");
const audioSrc5 = require("../../../assets/img/Recordings 5_Samuel Wu.mp3");

const TestStyle = styled.div`
  background-color: #fff;
  padding: 50px 0;
  .test-container {
    display: flex;
    justify-content: space-between;
    padding: 61px 0;
    .content {
      color: black;
      .title {
        font-size: 36px;
        line-height: 48px;
      }
      .desc {
        font-size: 16px;
        line-height: 28px;
        opacity: 0.6;
        margin: 30px 0;
        max-width: 75%;
      }
      .clock {
        padding-top: 100px;
        display: flex;
        .list {
          margin-right: 2%;
          position: relative;
          cursor: pointer;
          .video {
            width: 125px;
            height: 70px;
          }
          .video-play {
            position: absolute;
            width: 23px;
            height: 22px;
            top: 37%;
            left: 38%;
            transform: rotate(360deg);
          }
          .cur {
            animation: rotation 2s linear infinite;
          }
        }
      }
    }
    .rightbgc {
      cursor: pointer;
      position: relative;
      top: -90px;
      right: 64px;
      .right-bgc {
        width: 661px;
        height: 100%;
      }
      .play-bgc {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 74px;
        height: 74px;
        margin-left: -37px;
        margin-top: -37px;
        transform: rotate(360deg);
      }
      .cur {
        animation: rotation 2s linear infinite;
      }
    }
  }
  @keyframes rotation {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @media (min-width: 800px) and (max-width: 1400px) {
    .test-container {
      width: 100%;
      .content {
        width: 50%;
        .clock {
          padding-top: 40px;
        }
      }
      .rightbgc {
        width: 50%;
        right: 0;
        .right-bgc {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
    .test-container {
      flex-direction: column;
      padding: 5% 4%;
      .content {
        margin: 0;
        .title {
          font-size: 18px;
          line-height: 24px;
        }
        .desc {
          color: #333333;
          max-width: 95%;
          opacity: 1;
          margin: 4% 0 4% 0;
        }
        .clock {
          overflow: scroll;
          padding: 3% 0;
          .list {
            padding: 5% 0;
            img {
              width: 100px;
              height: 56px;
            }
          }
        }
      }
      .rightbgc {
        top: 0;
        right: 0;
        .right-bgc {
          width: 100%;
          height: auto;
        }
        .play-bgc {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 43px;
          height: 43px;
          margin-left: -26.5px;
          margin-top: -26.5px;
        }
      }
    }
  }
`;

export default function HomeTestimonials(props) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [rotation, setRotation] = useState(false);
  const [rotation2, setRotation2] = useState("");
  const audioList = document.getElementsByClassName("audioList");
  const audioA = document.getElementById("audioA");
  const audioAt = useRef();
  const audioList1 = useRef();
  const audioList2 = useRef();
  const audioList3 = useRef();
  const audioList4 = useRef();
  const audioList5 = useRef();
  function playVideo() {
    const clientWidth = document.body.clientWidth;
    if (clientWidth <= "768") {
      props.history.push("/video-detail", {});
    } else {
      setShow(true);
    }
  }
  return (
    <TestStyle>
      <GTModalVideo show={show} setShow={setShow} />
      <div className="wrap-v1">
        <GTTitle
          title={t("home.test_props_title")}
          position="left"
          mobieTitle="home.test_props_title"
        />
        <div className="test-container">
          <div className="content">
            {
              // <div className="title fontstyle_SB">{t("home.test_title1")}</div>
            }
            <div className="desc">{t("home.test_desc1")}</div>
            <GTButton
              title={t("home.join_props_title")}
              changeLink={(e) => {
                props.history.push("/testimonials");
              }}
              styled={{ margin: "5% 0" }}
              mobieTitle="routes-media.watch"
            />
            <div className="clock">
              {home_testimonals_info.map((item, index) => {
                return (
                  <div
                    className="list"
                    key={index}
                    onClick={(e) => {
                      audioAt.current.pause();
                      setRotation(false);
                      if (index === 0) {
                        if (audioList1.current.paused) {
                          audioList1.current.play();
                          setRotation2(index);
                        } else {
                          audioList1.current.pause();
                          setRotation2("");
                        }
                        audioList2.current.pause();
                        audioList3.current.pause();
                        audioList4.current.pause();
                        audioList5.current.pause();
                      }
                      if (index === 1) {
                        if (audioList2.current.paused) {
                          audioList2.current.play();
                          setRotation2(index);
                        } else {
                          audioList2.current.pause();
                          setRotation2("");
                        }
                        audioList1.current.pause();
                        audioList3.current.pause();
                        audioList4.current.pause();
                        audioList5.current.pause();
                      }
                      if (index === 2) {
                        if (audioList3.current.paused) {
                          audioList3.current.play();
                          setRotation2(index);
                        } else {
                          audioList3.current.pause();
                          setRotation2("");
                        }
                        audioList1.current.pause();
                        audioList2.current.pause();
                        audioList4.current.pause();
                        audioList5.current.pause();
                      }
                      if (index === 3) {
                        if (audioList4.current.paused) {
                          audioList4.current.play();
                          setRotation2(index);
                        } else {
                          audioList4.current.pause();
                          setRotation2("");
                        }
                        audioList1.current.pause();
                        audioList2.current.pause();
                        audioList3.current.pause();
                        audioList5.current.pause();
                      }
                      if (index === 4) {
                        if (audioList5.current.paused) {
                          audioList5.current.play();
                          setRotation2(index);
                        } else {
                          audioList5.current.pause();
                          setRotation2("");
                        }
                        audioList1.current.pause();
                        audioList2.current.pause();
                        audioList3.current.pause();
                        audioList4.current.pause();
                      }
                    }}
                  >
                    <img src={item.imgUrl} alt="" className="video" />
                    <img
                      src={video_white_play}
                      alt=""
                      className={
                        rotation2 === index ? "video-play cur" : "video-play"
                      }
                    />
                  </div>
                );
              })}
              <audio
                src={audioSrc}
                className="audio_el audioList"
                ref={audioList1}
              >
                您的浏览器不支持 audio 标签。
              </audio>
              <audio
                src={audioSrc2}
                className="audio_el audioList"
                ref={audioList2}
              >
                您的浏览器不支持 audio 标签。
              </audio>
              <audio
                src={audioSrc3}
                className="audio_el audioList"
                ref={audioList3}
              >
                您的浏览器不支持 audio 标签。
              </audio>
              <audio
                src={audioSrc4}
                className="audio_el audioList"
                ref={audioList4}
              >
                您的浏览器不支持 audio 标签。
              </audio>
              <audio
                src={audioSrc5}
                className="audio_el audioList"
                ref={audioList5}
              >
                您的浏览器不支持 audio 标签。
              </audio>
            </div>
          </div>
          <div
            className="rightbgc"
            onClick={(e) => {
              console.log(audioAt);
              for (let i = 0; i < 5; i++) {
                audioList[i].pause();
              }
              if (audioAt.current.paused) {
                audioAt.current.play();
                setRotation(true);
                setRotation2("");
              } else {
                audioAt.current.pause();
                setRotation(false);
              }
            }}
          >
            {/* 到GTModal中区调用组件 */}
            <img src={rightbgc} alt="" className="right-bgc" />
            <img
              src={video_play}
              alt=""
              className={rotation === true ? "play-bgc cur" : "play-bgc"}
            />
            <audio
              src={audioSrc}
              id="audioA"
              className="audio_el"
              ref={audioAt}
            >
              您的浏览器不支持 audio 标签。
            </audio>
          </div>
        </div>
      </div>
    </TestStyle>
  );
}
