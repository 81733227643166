import React from "react";
import AboutGrandTag from "./AboutGrandTag";
import ContectUs from "./ContectUs";
import FAQ from "../../pages/GT_FAQ/contact-faq.jsx";
import { withRouter } from "react-router";
import GTTodo from "../../pages/GT_Todo";
import styled from "styled-components";
import SpecialistVideo from "@/pages/GT_Specialtiesvideo/";
import { useTranslation } from "react-i18next";
const ConnectStyle = styled.div`
  display: block;
  .faq {
    display: block;
  }

  @media(max-width:768px){
    .faq{
      display: none;
    }
  }
`;

function GT_ConnectFAQ(props) {
  const { t } = useTranslation();
  return (
    <ConnectStyle className="fix-margin-top">
      <AboutGrandTag />
      <ContectUs />
      <div className="faq">
        <FAQ {...props} />
      </div>
      <div className="specialties-container">
        <SpecialistVideo title={t("special.video-title")} />
      </div>
      <GTTodo />
    </ConnectStyle>
  );
}
export default withRouter(GT_ConnectFAQ)