import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getVideo } from "@/service/video";
import { ImageURL } from "@/service/config";
const play = require("../../assets/img/video_list_play@2x.png");
const VideoDetailWrapper = styled.div`
  padding-bottom: 10%;
`;

const VideoDetailItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 5%;
  padding: 0 3%;
  border-bottom: 2px solid #ececec;
  .video-img {
    position: relative;
    width: 100%;
    height: 200px;
    .bgc_img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }

    .time {
      position: absolute;
      right: 5%;
      bottom: 5%;
      padding: 1px 5px;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 3px;
    }
    .play {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50px;
      height: 50px;
      transform: translate(-40%, -50%);
    }
  }

  .item {
    padding: 1%;
    padding-bottom: 5%;
    .title {
      max-height: 3.5em;
      padding-top: 10px;
      font-size: 16px;
      overflow: hidden;
      line-height: 24px;
      font-weight: 500;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
    .desc {
      height: 54px;
      padding: 1% 0;
      overflow: hidden;
      font-size: 14px;
      line-height: 24px;
    }
  }
`;

function VideoDetailItem(props) {
  const { info } = props;
  const Image = function (pic) {
    return ImageURL + pic;
  };
  return (
    <VideoDetailItemWrapper className="fix-margin-top">
      <div className="video-img">
        <img src={Image(info.pic)} alt="" className="bgc_img" />
        <img src={play} alt="" className="play" />
      </div>
      <div className="item">
        <div className="title fontstyle_SB">{info.title}</div>
      </div>
    </VideoDetailItemWrapper>
  );
}

export default function GTVideoFirst(props) {
  const { info } = props.location.state;

  const [videodata, setVideoData] = useState([]);
  useEffect(() => {
    getVideo(info.type).then((res) => {
      setVideoData(res.data);
    });
  });

  return (
    <VideoDetailWrapper>
      <div className="container">
        {videodata &&
          videodata.map((item, index) => {
            return (
              <div
                key={item.id}
                onClick={(e) => {
                  props.history.push("/video-detail", {
                    info: item,
                  });
                }}
              >
                <VideoDetailItem info={item} />
              </div>
            );
          })}
      </div>
    </VideoDetailWrapper>
  );
}
