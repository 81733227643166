import React, { useState, useRef } from "react";
import styled from "styled-components";
import GTModalVideo from "@/pages/GT_ModalVideo";
import { useTranslation } from "react-i18next";
const video_play = require("../../../assets/img/Testimonials_video_play@2x.png");
const headerbgc = require("@/assets/img/Testimonials_header@2x.jpg");
const whitelogo = require("@/assets/img/testimonials_whitelogo@2x.png");
const audioSrc = require("../../../assets/img/Recordings 5_Samuel Wu.mp3");
const audio_music_img = require("@/assets/img/Testimonials_audio_icon_music@2x.png");
const active_music_img = require("../../../assets/img/Testimonials_active_music.gif");
const audio_radio_group_right = require("../../../assets/img/Testimonials_audio_radio@2x.png");
const audio_radio_group_left = require("../../../assets/img/Testimonials_audio_radiogroup@2x.png");
// audio
const music1 = require("../../../assets/audio/All Falls.mp3");

let musicArr = [
  {
    imgUrl: audio_music_img,
    music: music1,
  },
  {
    imgUrl: audio_music_img,
    music: music1,
  },
  {
    imgUrl: audio_music_img,
    music: music1,
  },
  {
    imgUrl: audio_music_img,
    music: music1,
  },
  {
    imgUrl: audio_music_img,
    music: music1,
  },
];
const TestimonialsHeaderStyle = styled.div`
  background-color: red;
  position: relative;
  .header-bgc {
    .music_top {
      width: 1242px;
      position: relative;
    }
    .headers-bgc {
      width: 1242px;
    }
    .video-play {
      position: absolute;
      width: 101px;
      height: 101px;
      top: 50%;
      left: 50%;
      margin-left: -50.5px;
      margin-top: -50.5px;
      transform: rotate(360deg);
    }
    .video-play.cur {
      animation: rotation 2s linear infinite;
    }
  }

  @keyframes rotation {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  .content {
    position: absolute;
    top: 20%;
    right: 8%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    .title {
      font-size: 144px;
      line-height: 148px;
      color: #fff;
    }
    .small-title {
      line-height: 144px;
      color: transparent;
      font-size: 120px;
      -webkit-text-stroke: 1px white;
    }
    .white-logo {
      position: absolute;
      bottom: -50%;
      right: 5%;
      img {
        height: 43px;
        width: 43px;
      }
    }
  }
  @media (min-width: 800px) and (max-width: 1400px) {
    .header-bgc {
      .headers-bgc {
        width: calc(100% - 420px);
      }
    }
  }
  @media (max-width: 768px) {
    .header-bgc {
      .music_top {
        width: 100%;
      }
      .headers-bgc {
        width: auto;
        max-height: 176px;
      }
      .video-play {
        width: 46px;
        height: 46px;
        top: 63%;
        left: 42%;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      top: 13%;
      right: 5%;
      .title {
        font-size: 32px;
        line-height: 33px;
      }

      .small-title {
        font-size: 22px;
        line-height: 30px;
      }

      .white-logo {
        margin-top: 20%;
        img {
          width: 10px;
          height: 10px;
        }
      }
    }
  }
`;

export default function TestimonialsHeader(props) {
  const { t } = useTranslation();
  // const { info } = props;
  const [show, setShow] = useState(false);
  const [rotation, setRotation] = useState(false);
  const audioList2 = document.getElementsByClassName("audioList2");
  const audioList3 = document.getElementsByClassName("audioList3");
  const audioDiv = document.getElementById("audioDiv");
  const audio = document.getElementsByTagName("audio");
  const imgs = document.getElementsByClassName("pic_middle");
  const imgs2 = document.getElementsByClassName("audio_pic_bottom");
  const audioDivTop = useRef();
  function playVideo() {
    const clientWidth = document.body.clientWidth;
    if (clientWidth <= "768") {
      props.history.push("/video-detail", {});
    } else {
      setShow(true);
    }
  }
  return (
    <TestimonialsHeaderStyle>
      {/* <GTModalVideo show={show} setShow={setShow} id="TestimonialsHeader" /> */}
      <div
        className="header-bgc cursor"
        onClick={(e) => {
          console.log(audioDivTop);
          for (let i = 0; i < imgs.length; i++) {
            imgs[i].classList.remove("cur");
          }
          for (let i = 0; i < imgs2.length; i++) {
            imgs2[i].classList.remove("cur");
          }
          for (let i = 0; i < 11; i++) {
            if (i === 0) {
              if (audioDivTop.current.paused) {
                audioDivTop.current.play();
                setRotation(true);
                document.getElementById("pic_top").classList.add("cur");
              } else {
                audioDivTop.current.pause();
                setRotation(false);
              }
            } else {
              audio[i].pause();
            }
          }
        }}
      >
        <div className="music_top">
          <img src={headerbgc} alt="" className="headers-bgc" />
          <img
            src={video_play}
            alt=""
            className={rotation === true ? "video-play cur" : "video-play"}
            id="pic_top"
          />
        </div>

        <audio src={audioSrc} id="audioDiv" ref={audioDivTop}>
          您的浏览器不支持 audio 标签。
        </audio>
      </div>
      <div className="content fontstyle_SB">
        <div className="title ">{t("testimonials.header-title")}</div>
        <div className="small-title">
          {t("testimonials.header-small-title")}
        </div>
        <div className="white-logo">
          <img src={whitelogo} alt="" />
        </div>
      </div>
    </TestimonialsHeaderStyle>
  );
}
