import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router";
import LecturesSmallVideo from "./d-video";
import { lectures_volunm_info } from "../../mocks/leter_data";
const LecturesMainStyle = styled.div`
  padding: ${(props) => props.padding} 0;
  .lecturesvideo-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @media (max-width: 768px) {
    padding: 0;
    .lecturesvideo-container {
      flex-wrap: nowrap;
      overflow-x: scroll;
      padding: 8% 0;
      .item {
        margin-left: 3%;
        &:last-child {
          padding-right: 3%;
        }
      }
    }
  }
`;
function GTLecturesVideo(props) {
  const { padding = "60px" } = props;
    
  return (
    <LecturesMainStyle padding={padding}>
      <div className="lecturesvideo-container wrap-v1">
        {lectures_volunm_info.map((item, index) => {
          return (
            <div className="item" key={index} >
              <LecturesSmallVideo {...props} info={item} index={index}/>
            </div>
          );
        })}
      </div>
    </LecturesMainStyle>
  );
}

export default withRouter(GTLecturesVideo);
