import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
// import GTModalVideo from "@/pages/GT_ModalVideo";

import GTTitle from "@/pages/GT_Title";
import GTButton from "@/pages/GT_Button";
const supporting = require("@/assets/img/Home_supportingbgc@2x.jpg");
const detale_line = require("@/assets/img/Detale_line@2x.png");
const dropdown = require("../../../assets/img/Home_Test_DropDown@2x.png");
const play_icon = require("../../../assets/img/Home_whyGT_icon@2x.png");
const advisor = require("../../../assets/img/rcjj.jfif");
const play = require("../../../assets/img/Home_whyGT_play@2x.png");
const close = require("@/assets/img/Modal_video_close@2x.png");
const top = require("@/assets/img/Title_top@2x.png");
const left = require("@/assets/img/Title_left@2x.png");
const audioSrc6 = require("../../../assets/img/Recordings 5_Samuel Wu.mp3");
const HomeGrandtagStyle = styled.div`
  background-color: #fff;
  padding-bottom: 70px;
  .play {
    width: 48px;
    height: 48px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  img {
    cursor: pointer;
  }
  .exp {
    display: none;
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .content {
      flex: 2;
      padding-right: 40px;
      align-self: flex-start;
      .title {
        font-family: SossBo;
        font-size: 48px;
        color: rgb(6, 50, 94);
        line-height: 48px;
        padding: 10px 0;
      }
      .desc {
        width: 95%;
        overflow: hidden;
        font-size: 20px;
        line-height: 36px;
        margin-top: 100px;
        p {
          margin-bottom: 50px;
        }
      }
      .dropdown {
        display: none;
      }

      .detale_lineimg {
        display: block;
        width: 23px;
        height: 23px;
        margin: 10px 0;
      }

      .gtbutton {
        margin-top: 20px;
      }
    }

    .advisor_bgc {
      width: 240px;
      align-self: flex-start;
      margin-right: 3%;
      position: relative;
      top: 40px;
      &:hover {
        display: block;
        background-color: #f33500;
        .advisor_img {
          opacity: 0.15;
        }
        .advisor-title {
          display: none;
        }
        .advisor-icon {
          display: none;
        }

        .hover {
          width: 100%;
          display: block;
          position: absolute;
          top: 30%;
          left: 50%;
          transform: translate(-50%);
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .hover-icon {
            height: 29px;
            img {
              height: 100%;
              width: 100%;
            }
          }
          .title {
            padding-top: 20px;
            text-align: center;
            color: #ffff;
            font-size: 31px;
            line-height: 1.25em;
          }
        }
      }
      .advisor-title {
        display: block;
        font-family: SossBo;
        font-size: 30px;
        line-height: 40px;
        text-align: center;
        margin: 4px 0;
      }
      .advisor-icon {
        position: absolute;
        bottom: 0;
        right: 0;
        display: block;
        transform: rotate(360deg);
      }
      .cur {
        animation: rotation 2s linear infinite;
      }
      @keyframes rotation {
        from {
          -webkit-transform: rotate(0deg);
        }
        to {
          -webkit-transform: rotate(360deg);
        }
      }
      .hover {
        display: none;
      }
      .advisor_img {
        width: 100%;
        height: 478px;
        position: relative;
        .advisor-icon {
          position: absolute;
          right: 10px;
          bottom: 10px;
        }
        .img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .specialist_bgc {
      display: none;
      position: relative;
      .specialist-img {
        width: 104px;
        height: 209px;
        position: relative;
        .specialist_img {
          width: 100%;
          height: 100%;
        }
      }

      .specialist-title {
        text-align: center;
        padding-top: 0.5em;
        line-height: 33px;
        font-size: 19px;
      }
      .specialist-icon {
        position: absolute;
        bottom: 10px;
        right: 10px;
        display: block;
      }
    }
    .supporting_bgc {
      align-self: flex-start;
      position: relative;

      &:hover {
        display: block;
        background-color: #f33500;

        .supporting-img {
          opacity: 0.15;
        }
        .supporting-title {
          color: transparent;
          background-color: #fff;
        }
        .advisor-icon {
          display: none;
        }

        .hover {
          width: 100%;
          display: block;
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translate(-50%);
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .hover-icon {
            height: 29px;
            img {
              height: 100%;
              width: 100%;
            }
          }
          .title {
            padding-top: 20px;
            text-align: center;
            color: #ffff;
            font-size: 30px;
            line-height: 1.25em;
          }
        }
      }

      .supporting-title {
        display: block;
        font-family: SossBo;
        font-size: 30px;
        line-height: 36px;
        text-align: center;
        margin: 0;
        margin-bottom: 5px;
      }
      .hover {
        display: none;
      }
      .supporting-img {
        height: 536px;
        position: relative;
        .supporting-icon {
          position: absolute;
          right: 10px;
          bottom: 10px;
        }
        .supporting_img {
          height: 100%;
        }
      }
    }
  }

  .move-button {
    display: none;
  }
  .content-v2 {
    display: none;
  }
  @media (max-width: 768px) {
    padding: 0;
    padding-bottom: 20px;
    .wap-hide {
      display: none;
    }
    .play {
      width: 20px;
      height: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .container {
      justify-content: space-around;
      flex-wrap: wrap;
      padding: 0 4%;
      .content {
        margin: 0;
        flex: auto;
        padding: 0;
        .detale_lineimg {
          display: none;
        }
        .title {
          font-size: 18px;
          line-height: 24px;
          margin: 0;
          padding: 20px 0;
          padding-bottom: 10px;
        }
        .wrapper {
          display: flex;
          flex-direction: column;
          overflow: hidden;

          .desc {
            position: relative;
            display: -webkit-box;
            overflow: hidden;
            margin-bottom: 10px;
            font-size: 16px;
            line-height: 28px;
            text-overflow: ellipsis;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            margin-top: 20px;
          }
          .btn {
            color: #fff;
            font-size: 16px;
            border-radius: 4px;
            cursor: pointer;
          }

          .exp {
            display: none;
          }
          .exp:checked + .desc {
            -webkit-line-clamp: 999;
          }
        }
        .dropdown {
          display: block;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .advisor_bgc {
        height: 230px;
        width: 130px;
        margin: 0;
        top: 15px;
        &:hover {
          background-color: transparent;
          .advisor_img {
            opacity: 1;
          }
          .advisor-title {
            display: block;
          }
          .advisor-icon {
            display: block;
          }

          .hover {
            display: none;
          }
        }
        .advisor_img {
          height: 100%;
          width: 100%;
          .img {
            width: 100%;
            height: 100%;
          }
          .advisor-icon {
          }
        }
        .advisor-title {
          font-size: 19px;
          line-height: 21px;
          margin-top: 7%;
        }
      }
      .specialist_bgc {
        display: block;
      }
      .supporting_bgc {
        &:hover {
          background-color: transparent;

          .supporting-img {
            opacity: 1;
          }
          .supporting-title {
            color: rgba(0, 0, 0, 0.85);
          }
        }

        .supporting-title {
          font-size: 19px;
          line-height: 21px;
        }
        .supporting-img {
          width: 131px;
          height: 253px;
          margin: 0 auto;
          .supporting_img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .move-button {
      display: block;
      padding-top: 6%;
      margin: 0 4%;
    }
  }
`;

export default function HomeGrandtag(props) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const pRef = useRef();
  const [rotation3, setRotation3] = useState(false);
  const audioList = document.getElementsByClassName("audioList");
  const audioA = document.getElementById("audioA");
  const audioB = document.getElementById("audioB");
  const audioList6 = useRef();
  function playVideo() {
    const clientWidth = document.body.clientWidth;
    if (clientWidth <= "768") {
      props.history.push("/video-detail", {
        name: "",
      });
    } else {
      toggleVideo();
      setShow(true);
    }
  }
  const handleOk = () => {
    toggleVideo("hide");
    setShow(false);
  };

  const handleCancel = () => {
    toggleVideo("hide");
    setShow(false);
  };

  function toggleVideo(state) {
    let divs = document.getElementById("popupVidGrandtag");
    // let iframe = divs.getElementsByTagName("iframe")[0].contentWindow;
    // divs.style.display = state === "hide" ? "none" : "";
    // let func = state === "hide" ? "pauseVideo" : "playVideo";
    // iframe.postMessage(
    //   '{"event":"command","func":"' + func + '","args":""}',
    //   "*"
    // );
  }
  // var audio = document.getElementById("audio");
  //     audio.loop = false;
  //     audio.addEventListener('ended', function () {
  //         alert('播音结束');
  //     }, false);
  return (
    <HomeGrandtagStyle>
      {
        <GTTitle
          // showlogo={true}
          // title={t("home.grandtag_props_title")}
          // position={"left"}
          mobieTitle="home.grandtag_props_title"
          // paddingSize="63px"
        />
      }
      <div className="wrap-v1">
        <Modal
          title={t("title")}
          visible={show}
          onOk={handleOk}
          width={"1000px"}
          onCancel={handleCancel}
          centered
          forceRender={false}
          destroyOnClose={true}
          closable={true}
          footer={null}
          closeIcon={<img src={close} alt="" />}
        >
          <div id="popupVidGrandtag" style={{ height: "100%" }}>
            <iframe
              width="100%"
              height="100%"
              src={"https://www.youtube.com/embed/ZoEcjD8tEuE?enablejsapi=1"}
              title={"GT Wealth"}
              frameBorder="0"
            ></iframe>
          </div>
        </Modal>
        <div className="container">
          <div className="content">
            {
              // <div className="title"> {t("home.grandtag_props_title")}</div>
              // <img src={detale_line} alt="" className="detale_lineimg" />
            }
            <div className="wrapper">
              <div className="content-v1 wap-hide" style={{ width: "95%" }}>
                <img
                  src={top}
                  className="showlogo"
                  alt=""
                  style={{ width: "64px" }}
                />
                <div className="title">{t("home.grandtag_props_title")}</div>
                <img src={left} alt="" style={{ width: "100%" }} />
              </div>
              <input type="checkbox" id="exp1" className="exp" />
              <div className="desc" ref={pRef}>
                {t("home.grandtag_desc1")}
              </div>
              <label className="btn" htmlFor="exp1">
                <div className="dropdown">
                  <img src={dropdown} alt="" />
                </div>
              </label>
            </div>
            <div className="gtbutton">
              <GTButton
                title={t("home.join_props_title")}
                changeLink={(e) => {
                  props.history.push("/vision-career");
                }}
              />
            </div>
          </div>

          <div className="advisor_bgc cursor">
            <div
              className="advisor_img"
              onClick={(e) => {
                // audioA.pause();
                for (let i = 0; i < 5; i++) {
                  audioList[i].pause();
                }
                if (audioList6.current.paused) {
                  audioList6.current.play();
                  setRotation3(true);
                } else {
                  audioList6.current.pause();
                  setRotation3(false);
                }
              }}
            >
              <img src={advisor} alt="" className="img" />
              <div
                className={
                  rotation3 === true
                    ? "advisor-icon play cur"
                    : "advisor-icon play"
                }
              >
                <img src={play} alt="" />
              </div>
            </div>
            <div className="advisor-title">{t("home.grandtag_Team")}</div>
            <div className="hover">
              <div className="hover-icon">
                <img src={play_icon} alt="" />
              </div>
              <div className="title fontstyle_SB">
                {t("home.grandtag_Team")}
              </div>
            </div>
            <audio
              src={audioSrc6}
              id="audioB"
              className="audio_el"
              ref={audioList6}
            >
              您的浏览器不支持 audio 标签。
            </audio>
          </div>
          <div className="supporting_bgc">
            <div className="supporting-title">
              {t("home.grandtag_supporting1")}
              <br />
              {t("home.grandtag_supporting2")}
            </div>
            <div className="hover">
              <div className="hover-icon">
                <img src={play_icon} alt="" />
              </div>
              <div className="title fontstyle_SB">
                {t("home.grandtag_supporting1")}
                <br />
                {t("home.grandtag_supporting2")}
              </div>
            </div>
            <div className="supporting-img">
              <img src={supporting} alt="" className="supporting_img" />
              {/* <div className="supporting-icon play">
                <img src={play} alt="" />
              </div> */}
            </div>
          </div>
        </div>
        <div className="move-button">
          <GTButton
            changeLink={(e) => {
              props.history.push("/vision-career");
            }}
            title={t("home.join_props_title")}
            mobieTitle="routes-media.watch"
          />
        </div>
      </div>
    </HomeGrandtagStyle>
  );
}
