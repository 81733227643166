import React, { memo } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router";
import SpecialtiesVideo from "../../pages/GT_Specialtiesvideo/briefingIteration";
import SpecialtiesHeader from "./Specialties_header";
import GTTodo from "../../pages/GT_Todo";
const SpecialtiesStyle = styled.div`
  background-color: #f7f9fc !important;
  padding-bottom: 64px;
  .button {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    display: block;
  }

  @media (max-width: 768px) {
    padding: 0;
    .button {
      display: none;
    }
  }
`;

 function GT_Specialties(props) {
  const { t } = useTranslation();
  return (
    <SpecialtiesStyle className="fix-margin-top">
      <SpecialtiesHeader {...props} />
      <div className="wrap-v1">
        {/* 视频播放组件后续插入 */}
        <SpecialtiesVideo title={t("special.video-title")} color={"#002369"} />
      </div>
      <GTTodo />
    </SpecialtiesStyle>
  );
}
export default withRouter(memo(GT_Specialties));