/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Menu, Dropdown } from "antd";
import styled from "styled-components";
import { DownOutlined } from "@ant-design/icons";
import I18n from "@/I18n";
import { withRouter } from "react-router";
import _ from "lodash";

const I18nButtonWrapper = styled.div`
  font-size: 16px;
  color: #fff;
  height: 47px;
  width: 114px;
  background-color: #f33500;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function I18nButton(props) {
  const [language, setLanguage] = useState("English");
  const [active, setActive] = useState(1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getIndex = () => {
    return _.findIndex(languageArr, (o) => o.language === I18n.language);
  };

  useEffect(() => {
    setActive(getIndex);
    const languageA = I18n.language;
    console.log(window);
    if (languageA === "en" && props.history.location.pathname !== "/en") {
      props.history.push("/en");
    }
    if (languageA === "en") {
      setLanguage("English");
    } else if (languageA === "zh-TW") {
      setLanguage("中文繁体");
    } else {
      setLanguage("中文简体");
    }
  }, [getIndex]);

  const onClick = ({ key }) => {
    if (key === "en") {
      setLanguage("English");
      localStorage.setItem("language", "en");
      I18n.changeLanguage(key);
      props.history.push("/en");
    } else if (key === "fr") {
      window.location.href = window.FRENCH_LINK;
    } else if (key === "zh-TW") {
      setLanguage("中文繁体");
      localStorage.setItem("language", "zh-TW");
      I18n.changeLanguage(key);
    } else {
      setLanguage("中文简体");
      localStorage.setItem("language", "zh-CN");
      I18n.changeLanguage(key);
    }
  };

  const languageArr = [
    {
      type: "中文简体",
      language: "zh-CN",
    },
    {
      type: "中文繁体",
      language: "zh-TW",
    },
    {
      type: "English",
      language: "en",
    },
    {
      type: "Français",
      language: "fr",
    },
  ];

  const menu = (
    <Menu onClick={onClick}>
      {languageArr.map((item, index) => {
        return (
          <Menu.Item
            key={item.language}
            className={active === index ? "gt_header_index_lang_active" : ""}
          >
            {item.language === "fr" ? (
              <p>{item.type}</p>
            ) : (
              <a href="" target="_self">
                {item.type}
              </a>
            )}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <div>
      {props.location.pathname === "/reward-detail" ? null : (
        <Dropdown overlay={menu}>
          <I18nButtonWrapper
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            {/* {props.history.location.pathname == "/en" ? "English" : language} */}
            {language}
            &nbsp;
            <DownOutlined />
          </I18nButtonWrapper>
        </Dropdown>
      )}
    </div>
  );
}

export default withRouter(I18nButton);
