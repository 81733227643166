// 按钮组件
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
const redlogo = require("../../assets/img/Nextlogo@2x.png");
const modia_icon = require("../../assets/img/icon@2x.png");
const whitelogo = require("../../assets/img/Up_logo@2x.png");
const lectures_video = require("@/assets/img/Lecture_buttonicon@2x.png");

const ButtonStyle = styled.div`
  .mobie-container {
    display: none;
  }
  @media (max-width: 768px) {
    .mobie-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      border: 1px solid #f33500;
      width: 100%;
      flex-wrap: nowrap;
      .mobie-title {
        font-size: 14px;
        line-height: 19px;
        color: #f33500;

        height: 19px;
        width: 90px;
      }

      .mobie-img {
        display: inline-block;
        width: 30px;
        height: 7px;
      }
    }
  }
`;

const BgcWhiteStyle = styled.div`
  height: 60px;
  width: 213px;
  color: #f33500;
  border: 2px #f33500 solid;
  transition: all 0.5s ease-out;
  &:hover {
    background-color: red;
    border: 2px #fff solid;
    color: #fff;
  }
  @media (max-width: 768px) {
    display: none;
  }
  button {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    cursor: pointer;

    .button-title {
      font-size: 16px;
      font-weight: 700;
      line-height: 21px;
    }
    #button-img {
      z-index: 100;
      width: 33px;
      height: 7px;
    }
  }
`;

const BgcRedStyle = styled.div`
  height: 60px;
  width: 213px;
  border: 2px #fff solid;

  @media (max-width: 768px) {
    display: none;
  }
  button {
    width: 100%;
    height: 100%;
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    .button-title {
      font-size: 16px;
      font-weight: 700;
      line-height: 21px;
      color: #fff;
    }
    #button-imgs {
      width: 22px;
      height: 26px;
    }
  }
`;

function BgcRed({ bgcColor = "#F33500", changeLink }) {
  const { t } = useTranslation();
  return (
    <BgcRedStyle>
      <button style={{ backgroundColor: `${bgcColor}` }} onClick={changeLink}>
        <div className="button-title">{t("home.join_props_title")}</div>
        <div>
          <img src={redlogo} alt="" id="button-imgs" />
        </div>
      </button>
    </BgcRedStyle>
  );
}

function BgcWhite({ bgcColor = "transparent", changeLink }) {
  const { t } = useTranslation();
  const imgRef = useRef();

  return (
    <BgcWhiteStyle>
      <button
        style={{ backgroundColor: `${bgcColor}` }}
        onClick={changeLink}
        onMouseOver={(e) => {
          imgRef.current.src = lectures_video;
        }}
        onMouseOut={(e) => {
          imgRef.current.src = whitelogo;
        }}
      >
        <div className="button-title">{t("home.join_props_title")}</div>
        <img src={whitelogo} alt="" id="button-img" ref={imgRef} />
      </button>
    </BgcWhiteStyle>
  );
}
function MobieButton({ mobieTitle, changeLink, bgcColor }) {
  const { t } = useTranslation();
  return (
    <div
      className="mobie-container"
      onClick={changeLink}
      style={{ backgroundColor: `${bgcColor}` }}
    >
      <div className="mobie-title">{t(mobieTitle)}</div>
      <img src={modia_icon} alt="" className="mobie-img" />
    </div>
  );
}

function GT_Button(props) {
  const {
    bgcColor = "white",
    mobieTitle,
    poscenter = "flex-start",
    width = "100%",
  } = props;
  return (
    <ButtonStyle style={{ width: `${width}` }}>
      {mobieTitle ? <MobieButton {...props} /> : null}

      <div style={{ display: "flex", justifyContent: poscenter }}>
        {bgcColor === "white" ? <BgcWhite {...props} /> : <BgcRed {...props} />}
      </div>
    </ButtonStyle>
  );
}

export default GT_Button;
