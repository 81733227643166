import React from "react";
import styled from "styled-components";
import GTSpecialties from "./d-video";
import { withRouter } from "react-router";
import GTButton from "../GT_Button";
import { video_volunm_info } from "../../mocks/leter_data";
import { useTranslation } from "react-i18next";
const SpecialistsVideoStyle = styled.div`
  .title {
    font-size: 48px;
    line-height: 2em;
    padding: 3% 0;
    /* padding-top: 4%; */
    text-align: center;
  }

  .item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 768px) {
    padding: 0;
    .title {
      font-size: 20px;
      background-color: #fff;
    }
    .item {
      flex-wrap: nowrap;
      overflow: auto;
      background-color: #fff;
    }
  }
`;

function Index(props) {
  const { title = "home.speciale-title", color = "black" } = props;
  const { t } = useTranslation();
  return (
    <SpecialistsVideoStyle>
      <div className="wrap-v1">
        <div className="title fontstyle_SB" style={{ color: `${color}` }}>
          {t(title)}
        </div>
        <div className="item">
          {video_volunm_info
            ? video_volunm_info.map((item, index) => {
                return <GTSpecialties info={item} key={index} {...props} />;
              })
            : []}
        </div>
      </div>

      {/* 移动端显示该按钮 */}
      <div className="specia-button">
        <GTButton
          changeLink={(e) => {
            props.history.push("/specialties");
          }}
          mobieTitle="routes-media.watch"
          poscenter={"center"}
        />
      </div>
    </SpecialistsVideoStyle>
  );
}

export default withRouter(Index);
