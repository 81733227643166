import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router";
import { ImageURL } from "@/service/config";
import { getEnglishList } from "@/service/video";
import styled from "styled-components";
import I18n from "@/I18n";
import _ from "lodash";

import bg from "@/assets/img/enBg.png";

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  .bg {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
  }
  * {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      background-color: #fff;
    }
    &::-webkit-scrollbar {
      width: 0.06rem;
      height: 0.05rem;
      background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #d9d9d9;
      border: none;
      border-radius: 0.06rem;
    }
  }
  .center {
    width: 100%;
    max-width: 1000px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 20px 0;
    position: relative;
    z-index: 1;
    .item {
      width: 100%;
      position: relative;
      cursor: pointer;
      overflow: hidden;
      border-radius: 10px;
      display: flex;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .name {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: auto;
        p {
          text-align: center;
          font-weight: bold;
          font-size: 20px;
          color: #fff;
        }
      }
      .remark {
        width: 100%;
        height: 100%;
        overflow: auto;
        padding: 10px;
        font-size: 14px;
        color: #fff;
        font-weight: 500;
        position: absolute;
        z-index: 10;
        left: 0;
        top: -100%;
        text-align: center;
        background: rgb(243, 53, 0);
        transition: all 0.3s;
        white-space: pre-wrap;
        word-break: break-word;
        display: flex;
        justify-content: center;
      }
      &:hover {
        .remark {
          top: 0;
        }
      }
    }
    .item.item_1 {
      max-height: 600px;
    }
    .item.item_2 {
      width: calc(50% - 10px);
      height: 250px;
      margin-bottom: 20px;
      &:nth-child(3) {
        width: 100%;
      }
    }
    .item.item_4 {
      width: 490px;
      height: 310px;
      &:nth-child(2) {
        height: 250px;
      }
      &:nth-child(3) {
        height: 250px;
        margin-top: 20px;
      }
      &:nth-child(4) {
        margin-top: -40px;
      }
    }
    .item.item_5 {
      width: calc((100% - 40px) / 3);
      height: 180px;
      &:nth-child(1) {
        width: 540px;
        height: 180px;
      }
      &:nth-child(2) {
        width: calc(100% - 540px - 20px);
        height: 250px;
      }
      &:nth-child(3) {
        width: 540px;
        height: 200px;
        margin-top: -46px;
      }
      &:nth-child(4) {
        width: calc(100% - 540px - 20px);
        height: 130px;
        margin-top: 20px;
      }
      &:nth-child(5) {
        width: 100%;
        height: 160px;
        margin-top: 20px;
      }
    }
    .item.item_6 {
      width: calc((100% - 40px) / 3);
      margin-top: 20px;
      &:nth-child(1) {
        width: calc((100% - 40px) / 3) !important;
        height: 180px !important;
        margin-top: 0;
      }
      &:nth-child(2) {
        width: calc((100% - 40px) / 3) !important;
        height: 360px !important;
        margin-top: 0;
      }
      &:nth-child(3) {
        width: calc((100% - 40px) / 3) !important;
        height: 180px !important;
        margin-top: 0;
      }
      &:nth-child(4) {
        width: calc((100% - 40px) / 3) !important;
        height: 360px !important;
        margin-top: -160px;
      }
      &:nth-child(5) {
        width: calc((100% - 40px) / 3) !important;
        height: 180px !important;
        margin-top: 20px;
      }
      &:nth-child(6) {
        width: calc((100% - 40px) / 3) !important;
        height: 360px !important;
        margin-top: -160px;
      }
    }
    .item.item_6.item_7 {
      width: calc((100% - 20px) / 2);
      height: 250px;
      &:last-child {
        width: 100%;
        height: 180px;
      }
    }
    .item.item_6.item_8 {
      width: calc((100% - 20px) / 2);
      height: 250px;
    }
  }
  @media (max-width: 768px) {
    .center {
      padding: 0 20px;
      .item {
        img {
        }
        .name {
          p {
            font-size: 16px;
          }
        }
        .remark {
          font-size: 12px;
        }
      }
      .item.item_2 {
        width: 100%;
        height: 250px;
        margin-bottom: 20px;
        &:nth-child(3) {
          width: 100%;
        }
      }
      .item.item_4 {
        width: calc((100% - 20px) / 2);
        height: 250px;
        &:nth-child(2) {
          height: 200px;
        }
        &:nth-child(3) {
          height: 200px;
          margin-top: 20px;
        }
        &:nth-child(4) {
          margin-top: -30px;
        }
      }
      .item.item_5 {
        width: calc((100% - 40px) / 3);
        height: 140px;
        &:nth-child(4),
        &:nth-child(5) {
          width: 100%;
          margin-top: 20px;
        }
      }
      .item.item_6 {
        width: calc((100% - 40px) / 3);
        margin-top: 20px;
        &:nth-child(1) {
          width: calc((100% - 20px) / 2) !important;
          height: 250px !important;
          margin-top: 0;
        }
        &:nth-child(2) {
          width: calc((100% - 20px) / 2) !important;
          height: 250px !important;
          margin-top: 0;
        }
        &:nth-child(3) {
          width: 100% !important;
          height: 180px !important;
          margin-top: 20px;
        }
        &:nth-child(4) {
          width: calc((100% - 20px) / 2) !important;
          height: 250px !important;
          margin-top: 20px;
        }
        &:nth-child(5) {
          width: calc((100% - 20px) / 2) !important;
          height: 250px !important;
          margin-top: 20px;
        }
        &:nth-child(6) {
          width: 100% !important;
          height: 180px !important;
          margin-top: 20px;
        }
      }
      .item.item_6.item_7 {
        width: calc((100% - 20px) / 2);
        height: 250px;
        &:last-child {
          width: 100%;
          height: 180px;
        }
      }
      .item.item_6.item_8 {
        width: calc((100% - 20px) / 2);
        height: 250px;
      }
    }
  }
`;
function En_Page(props) {
  const [list, setList] = useState([]);
  const [isOdd, setIsOdd] = useState(false);

  const languageArr = [
    {
      type: "中文简体",
      language: "zh-CN",
    },
    {
      type: "中文繁体",
      language: "zh-TW",
    },
    {
      type: "English",
      language: "en",
    },
    {
      type: "Français",
      language: "fr",
    },
  ];
  const getIndex = () => {
    return _.findIndex(languageArr, (o) => o.language === I18n.language);
  };
  useEffect(() => {
    getEnglishList().then((res) => {
      setList(res.data);
      if (res.data.length % 2 === 0) {
        setIsOdd(false);
      } else {
        setIsOdd(true);
      }
    });
  }, []);

  useEffect(() => {
    const language = I18n.language;
    if (language !== "en") {
      props.history.push("/");
    }
  }, [getIndex]);
  return (
    <PageWrapper>
      <img src={bg} alt="" className="bg" />
      <div className="center">
        {list.length === 1
          ? list.map((item) => (
              <a href={item.link} className="item item_1" key={item.id}>
                <img alt="" src={ImageURL + item.img} />
                <div className="name">
                  <p>{item.name}</p>
                </div>
                {item.remark ? <p className="remark">{item.remark}</p> : null}
              </a>
            ))
          : null}
        {list.length === 2 || list.length === 3
          ? list.map((item) => (
              <a href={item.link} className="item item_2" key={item.id}>
                <img alt="" src={ImageURL + item.img} />
                <div className="name">
                  <p>{item.name}</p>
                </div>
                {item.remark ? <p className="remark">{item.remark}</p> : null}
              </a>
            ))
          : null}
        {list.length === 4
          ? list.map((item) => (
              <a href={item.link} className="item item_4" key={item.id}>
                <img alt="" src={ImageURL + item.img} />
                <div className="name">
                  <p>{item.name}</p>
                </div>
                {item.remark ? <p className="remark">{item.remark}</p> : null}
              </a>
            ))
          : null}
        {list.length === 5
          ? list.map((item) => (
              <a href={item.link} className="item item_5" key={item.id}>
                <img alt="" src={ImageURL + item.img} />
                <div className="name">
                  <p>{item.name}</p>
                </div>
                {item.remark ? <p className="remark">{item.remark}</p> : null}
              </a>
            ))
          : null}
        {list.length === 6
          ? list.map((item) => (
              <a href={item.link} className="item item_6" key={item.id}>
                <img alt="" src={ImageURL + item.img} />
                <div className="name">
                  <p>{item.name}</p>
                </div>
                {item.remark ? <p className="remark">{item.remark}</p> : null}
              </a>
            ))
          : null}
        {list.length > 6 && isOdd
          ? list.map((item) => (
              <a href={item.link} className="item item_6 item_7" key={item.id}>
                <img alt="" src={ImageURL + item.img} />
                <div className="name">
                  <p>{item.name}</p>
                </div>
                {item.remark ? <p className="remark">{item.remark}</p> : null}
              </a>
            ))
          : null}

        {list.length > 6 && !isOdd
          ? list.map((item) => (
              <a href={item.link} className="item item_6 item_8" key={item.id}>
                <img alt="" src={ImageURL + item.img} />
                <div className="name">
                  <p>{item.name}</p>
                </div>
                {item.remark ? <p className="remark">{item.remark}</p> : null}
              </a>
            ))
          : null}
      </div>
    </PageWrapper>
  );
}
export default withRouter(En_Page);
