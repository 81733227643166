import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import YouTube from "react-youtube";

const close = require("@/assets/img/Modal_video_close@2x.png");

const Ifr = styled.div`
  width: 100%;
  height: 100%;
  .ifr_box {
    width: 100%;
    > div {
      width: 100%;
      height: 100%;
      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
`;
export default function GTModalVideo(props) {
  const { show, videourl, setShow, id } = props;
  const [link, setLink] = useState("");
  const { t } = useTranslation();
  if (show) {
    toggleVideo();
  }

  const handleOk = () => {
    toggleVideo("hide");
    setShow(false);
  };

  const handleCancel = () => {
    toggleVideo("hide");
    setShow(false);
  };

  function toggleVideo(state) {
    let div = document.getElementById("popupVid" + id);
    // let iframe = div.getElementsByTagName("iframe")[0].contentWindow;
    // div.style.display = state === "hide" ? "none" : "";
    // let func = state === "hide" ? "pauseVideo" : "playVideo";
    // iframe.postMessage(
    //   '{"event":"command","func":"' + func + '","args":""}',
    //   "*"
    // );
  }
  const sett = {
    width: "100%",
    playerVars: {
      autoplay: 1,
      rel: 0,
      loop: 1,
      playlist: link ? link : "ZoEcjD8tEuE",
    },
  };
  useEffect(() => {
    let name = videourl?.link.substring(videourl?.link.lastIndexOf("/") + 1);
    setLink(name);
  }, []);
  return (
    <>
      <Modal
        title={videourl ? videourl.title : t("title")}
        visible={show}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        forceRender={false}
        destroyOnClose={true}
        closable={true}
        footer={null}
        closeIcon={<img src={close} alt="" />}
      >
        <Ifr>
          <div
            className="ifr_box"
            id={`${"popupVid" + id}`}
            style={{ height: "100%" }}
          >
            {/* <iframe
              width="100%"
              height="100%"
              src={
                videourl
                  ? videourl.link + "?enablejsapi=1"
                  : "https://www.youtube.com/embed/ZoEcjD8tEuE?enablejsapi=1"
              }
              title={videourl ? videourl.title :  t("title")}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe> */}
            {videourl ? (
              <YouTube
                videoId={link}
                opts={sett}
                // onReady={this._onReady}
              />
            ) : (
              <YouTube
                videoId={"ZoEcjD8tEuE"}
                opts={sett}
                // onReady={this._onReady}
              />
            )}
          </div>
        </Ifr>
      </Modal>
    </>
  );
}
