import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ImageURL } from "@/service/config";
import { Modal } from "antd";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import GTModalVideo from "@/pages/GT_ModalVideo";
import { getVideo } from "../../../service/video";
const icon = require("@/assets/img/Speialist_icon@2x.png");
const play = require("@/assets/img/Specialist_play@2x.png");

const right_icon = require("../../../assets/img/rights-icon@2x.png");
const play1 = require("@/assets/img/video-detail-play@2x.png");
const close = require("@/assets/img/Modal_video_close@2x.png");
const backicon = require("@/assets/img/back_icon@2x.png");

const DVideoStyle = styled.div`
  width: 320px;
  height: 372px;
  background-color: #fff;
  border-radius: 12px;
  transition: all ease-in-out 0.4s;
  margin-bottom: 65px;
  transform-origin: center center;

  .play_img {
    position: relative;
    bottom: 75%;
    left: 50%;
    transform: translate(-50%);
    cursor: pointer;
    width: 52px;
    height: 52px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 12px;
    cursor: pointer;
    .bgc_img {
      height: 230px;
      position: relative;
      .image_bg {
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
      .cover-bgc {
        border-radius: 12px;
        width: 100%;
        height: 100%;
        transform: scale(1.1);
        position: relative;
        bottom: -30px;
      }
      .fixed-icon {
        display: block;
        width: 62px;
        height: 62px;
        border-radius: 50%;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 31px;
        bottom: -31px;
        .icon2xStyle {
          display: block;
        }
        .icon3xStyle {
          display: none;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .item {
      margin: 15px 35px;
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      flex: 1;
      .title {
        text-align: start;
        font-size: 18px;
        line-height: 24px;
        flex: 1;
        align-self: flex-start;
      }
      .btn {
        background-color: transparent;
        border: 2px solid #b2b2b2;
        width: 131px;
        height: 32px;
        cursor: pointer;
        display: flex;
        justify-content: space-around;
        align-items: center;
        align-self: flex-start;
        .btn-title {
          font-size: 12px;
          line-height: 20px;
        }

        .icon_img {
          height: 4px;
          width: 18px;
        }
      }
    }
  }

  &:hover {
    transform: scale(1.17, 1.17);
    .container {
      box-shadow: 7px 8px 13px 3px rgba(0, 0, 0, 0.17);
    }
    .play_img {
      display: none;
    }
  }
  @media (min-width: 800px) and (max-width: 1400px) {
    width: 23%;
    .container {
      .item {
        .title {
          font-size: 16px;
        }
      }
    }
  }
  @media (max-width: 768px) {
    height: 173px;
    margin-bottom: 5px;
    margin-left: 4%;

    &:last-child {
      padding-right: 4%;
    }
    &:hover {
      .container {
        box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 17%);
      }
      .play_img {
        display: block;
      }
    }
    .container {
      border-radius: 6px !important;
      justify-content: flex-start;
      box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 17%);
      .bgc_img {
        width: 161px;
        height: 115px;

        .cover-bgc {
          border-radius: 0;
          position: initial;
        }
        .fixed-icon {
          width: 30px;
          height: 30px;
          position: absolute;
          right: 15px;
          bottom: -15px;

          .icon2xStyle {
            display: none;
          }
          .icon3xStyle {
            display: block;
          }
        }
      }
      .item {
        margin: 13px 10px;
        margin-bottom: 0;
        align-items: flex-start;
        .title {
          padding: 0;
          padding-top: 3%;
          font-size: 12px;
          line-height: 17px;
          color: black;
        }
        .btn {
          display: none;
        }
      }
    }
    .play_img {
      display: block;
      width: 38px;
      height: 38px;
    }
    &:hover {
      transform: none;
    }
  }
`;

const GTVideoDetailItemWrapper = styled.div`
  color: #fff;
  cursor: pointer;
  .container {
    display: flex;
    flex-direction: column;
    width: 320px;
    margin-left: 1px;

    .play-page {
      position: relative;
      width: 100%;
      .play-bgc {
        width: 100%;
        height: 229px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .play-icon {
        position: absolute;
        top: 50%;
        left: 40%;
        transform: translateY(-50%);
        img {
          width: 52px;
          height: 52px;
        }
      }

      .time {
        position: absolute;
        bottom: 6px;
        right: 6px;
        font-size: 14px;
        line-height: 24px;
        padding: 3px 6px;
        background-color: rgba(0, 0, 44, 0.5);
        color: #fff;
      }
    }

    .play-title {
      padding: 3% 0;
      width: 100%;
      overflow: hidden;
      max-height: 3.5em;
    }
  }
  @media (min-width: 800px) and (max-width: 1400px) {
    .container {
      width: 220px;
      .play-page {
        .play-bgc {
          height: 200px;
        }
      }
    }
  }
`;

// 子弹窗内部item  home页面的展示组件
function GTVideoDetailItem(props) {
  const { info } = props;
  const [show, setShow] = useState(false);

  const Image = function (pic) {
    return ImageURL + pic;
  };
  return (
    <GTVideoDetailItemWrapper>
      <GTModalVideo
        show={show}
        setShow={setShow}
        videourl={info}
        id={props.id}
      ></GTModalVideo>
      <div
        className="container"
        onClick={() => {
          setShow(true);
        }}
      >
        <div className="play-page">
          <div className="play-bgc">
            <img src={Image(info.pic)} alt="" />
          </div>
          <div className="play-icon">
            <img src={play1} alt="" />
          </div>
          <div className="time">{info.updated}</div>
        </div>
        <div className="play-title">{info.title}</div>
      </div>
    </GTVideoDetailItemWrapper>
  );
}

export default function DVideo(props) {
  const { t } = useTranslation();
  const { info } = props;
  const [isModalVisibleItem, setIsModalVisibleItem] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [typeVideo, setTypeVideo] = useState([]);

  const clickShowModel = function (e) {
    let curType = e.currentTarget.getAttribute("data-type");
    if (document.body.clientWidth <= "768") {
      if (typeVideo.length === 1) {
        props.history.push("/video-detail", {
          info: typeVideo[0],
        });
      } else {
        props.history.push("/video-frist", {
          info: info,
        });
      }
    } else {
      // if (typeVideo.length === 1) {
      //   showModal();
      // } else {
      //   showModalItem();
      // }
      if (curType === "1" && typeVideo.length !== 0) {
        showModal();
      } else {
        showModalItem();
      }
    }
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const showModalItem = () => {
    setIsModalVisibleItem(true);
  };

  const handleOkItem = () => {
    setIsModalVisibleItem(false);
  };

  const handleCancelItem = () => {
    setIsModalVisibleItem(false);
  };

  const item = {
    color: "white",
    height: "50px",
    display: "flex",
    fontSize: "20px",
    marginRight: "6%",
    justifyContent: "flex-end",
  };

  const settings = {
    infinite: false,
    slidesToShow: 3.5,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          height: "60%",
          margin: "4% 0",
          right: "40px",
          top: "60%",
        }}
        onClick={onClick}
      >
        <img
          src={right_icon}
          alt=""
          style={{ width: "24px", height: "48px" }}
        />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          height: "60%",
          margin: "4% 0",
          left: "40px",
          top: "60%",
        }}
        onClick={onClick}
      >
        {/* <img src={right_icon} alt="" style={{ width: '24px', height: '48px' }} /> */}
        <div style={{ width: "44px", height: "48px" }} />
      </div>
    );
  }

  useEffect(() => {
    getVideo(info.type).then((res) => {
      setTypeVideo(res.data);
    });
  }, []);
  return (
    <DVideoStyle>
      {/* 多个视频是弹出列表框进行列表展示在对其进行单视频播放 */}
      <Modal
        title={t(info.title)}
        visible={isModalVisibleItem}
        onOk={handleOkItem}
        onCancel={handleCancelItem}
        centered
        forceRender={false}
        destroyOnClose={true}
        closable={true}
        footer={null}
        closeIcon={<img src={close} alt="" />}
      >
        {typeVideo?.length > 3 ? (
          <div style={item}>
            {t("specialtiesvideo.videoswait")}{" "}
            {typeVideo ? typeVideo.length : null}
            {t("specialtiesvideo.videosnums")}
          </div>
        ) : null}
        <Slider {...settings}>
          {typeVideo &&
            typeVideo.map((item, index) => {
              return (
                <GTVideoDetailItem info={item} key={item.id} id={item.id} />
              );
            })}
        </Slider>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            color: "#fff",
            alignItems: "center",
            margin: "2% 0",
          }}
        >
          <div
            onClick={handleCancelItem}
            style={{ width: "140px", cursor: "pointer" }}
          >
            <img
              src={backicon}
              alt=""
              style={{
                width: "36px",
                height: "36px",
                marginRight: "10%",
                display: "inline-block",
              }}
            />
            <span style={{ fontSize: "18px" }}>
              {t("specialtiesvideo.back")}
            </span>
          </div>
        </div>
      </Modal>

      {/* 当后台只有一个视频存在时进行判断播放盒 */}
      {
        //typeVideo? typeVideo.slice(0, 1).map((item) => {
        typeVideo
          ? typeVideo.slice(0, 1).map((item) => {
              return (
                <GTModalVideo
                  show={isModalVisible}
                  setShow={setIsModalVisible}
                  id={item.id + "homespecialas"}
                  key={item.id}
                  videourl={item}
                />
              );
            })
          : []
      }

      {/* 最外层静态数据 ,item点击触发showmodal*/}
      <div className="container">
        <div className="bgc_img" onClick={clickShowModel} data-type="1">
          <div className="image_bg">
            <img
              src={
                window.localStorage.getItem("i18nextLng") == "en"
                  ? info.imgUrl2
                  : info.imgUrl
              }
              alt=""
              className="cover-bgc"
            />
          </div>

          <div className="fixed-icon">
            <img src={info.icon2xUrl} alt="" className="icon2xStyle" />
            <img src={info.icon3xUrl} alt="" className="icon3xStyle" />
          </div>
        </div>
        <div className="item">
          <div className="title fontstyle_SB">{t(info.title)}</div>
          <button className="btn" onClick={clickShowModel} data-type="2">
            <span className="btn-title">{t("home.specials-item-learn")}</span>
            {/* 表图标是随机获取的 */}
            <img src={icon} alt="" className="icon_img" />
          </button>
        </div>
      </div>
      <div className="play_img" onClick={clickShowModel} data-type="1">
        <img src={play} alt="" />
      </div>
    </DVideoStyle>
  );
}
