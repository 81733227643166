import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router";
import LecturesSmallVideo from "./d-video/briefing";
import { lectures_volunm_info } from "../../mocks/leter_data";
const LecturesMainStyle = styled.div`
  padding: ${(props) => props.padding} 0;
  background-color: #F7F9FC;
  .lecturesvideo-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @media (max-width: 768px) {
    padding: 0;
    .lecturesvideo-container {
      justify-content: space-evenly;
      flex-wrap: wrap;
      padding-top: 8%;
      padding-bottom: 3%;
      .item {
        margin-bottom: 6%;
      }
    }
  }
  @media (max-width: 411px) {
    .lecturesvideo-container {
      justify-content: space-evenly;
      flex-wrap: wrap;
      .item {
      }
    }
  }

  @media (max-width:321px){
    .lecturesvideo-container {
      .item {
        margin-bottom: 12%;
      }
    }
  }
`;
function GTLecturesVideo(props) {
  const { padding = "60px" } = props;

  return (
    <LecturesMainStyle padding={padding}>
      <div className="lecturesvideo-container wrap-v1">
        {lectures_volunm_info.map((item, index) => {
          return (
            <div className="item" key={index}>
              <LecturesSmallVideo {...props} info={item} index={index} />
            </div>
          );
        })}
      </div>
    </LecturesMainStyle>
  );
}

export default withRouter(GTLecturesVideo);
