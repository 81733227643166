// 信息展开更多组件
import React from "react";

import { Collapse } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import GTButton from "../../pages/GT_Button";

import GTTitle from "@/pages/GT_Title";

const { Panel } = Collapse;

const FAQStyle = styled.div`
  padding: 50px 0;
  position: relative;
  background-color: #fff;
  .container {
    .move-button {
      display: none;
    }
    .right_button {
      display: block;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 20px 60px 0 0;
      .title {
        font-family: SossBo;
        font-size: 42px;
        line-height: 57px;
      }
    }
    .collapse-item {
      display: flex;
      justify-content: space-between;

      .mobie-show {
        display: none !important;
      }
      .space {
        padding-left: 5%;
        display: block;
      }
      .faq-collapse {
        flex-wrap: wrap;
        display: block;
        margin: 3% 0;
        flex: 1;

        .ant-collapse-header {
          height: 120px;
          font-size: ${window.localStorage.getItem('i18nextLng') === 'en'?'18px':'24px'};
          // line-height: 50px;
          font-weight: 500;
          background-color: #f7f9fc;
          margin: 0;
          margin-right: 3%;
          margin-bottom: 17px;
          display:flex;
          align-items:center;
          &:hover {
            background-color: #fdd6cc;
            /* color: #f33500 !important; */
          }
        }

        .ant-collapse-item-active {
          /* position: relative; */
          .ant-collapse-header {
            color: red !important;
          }
        }

        .ant-collapse-item {
          .ant-collapse-content {
            .ant-collapse-content-box {
              width: 80%;
              font-size: 18px;
              line-height: 2.1em;
              height: 150px;
              overflow-y: scroll;
              margin-bottom: 30px;
              padding: 5px 0 0px 50px;
              background-color: white;
              &::-webkit-scrollbar{/*滚动条整体部分，其中的属性有width,height,background,border等（就和一个块级元素一样）（位置1）*/
              		width:4px;
              		height:4px;
              }
              &::-webkit-scrollbar-button{/*滚动条两端的按钮，可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果（位置2）*/
              		display:none;
              }
              &::-webkit-scrollbar-track{/*外层轨道，可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果（位置3）*/
              		background:rgb(241,241,241);
              }
              &::-webkit-scrollbar-track-piece{/*内层轨道，滚动条中间部分（位置4）*/
              		background:rgb(241,241,241);
              }
              &::-webkit-scrollbar-thumb{/*滚动条里面可以拖动的那部分（位置5）*/
              	background:rgb(193,193,193);
              }
              &::-webkit-scrollbar-corner {/*边角（位置6）*/
              	display:none;
              }
              &::-webkit-scrollbar-resizer  {/*定义右下角拖动块的样式（位置7）*/
              	bdisplay:none;
              }
              // &::-webkit-scrollbar {
              //   display: none;
              // }
            }
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    padding: 10px 0;
    .container {
      .move-button {
        display: block;
        margin: 20px 4%;
      }
      .right_button {
        display: none;
      }
      .collapse-item {
        display: flex;
        justify-content: space-between;
        .space {
          padding: 0;
          display: none;
        }

        .mobie-show {
          display: block !important;
        }
        .faq-collapse {
          display: none;
          flex-wrap: wrap;
          flex: 1;
          margin: 0;

          .ant-collapse-header {
            height: 80px;
            font-size: ${window.localStorage.getItem('i18nextLng') === 'en'?'12px':'14px'};;
            // line-height: 2.5em;
            background-color: #f7f9fc;
            font-weight: 500;
            margin: 0 4%;
            margin-top: 12px;
            &:hover {
              background-color: #f7f9fc;
            }
          }

          .ant-collapse-item {
            .ant-collapse-content {
              margin: 20px 0;
              margin-bottom: 0;
              .ant-collapse-content-box {
                font-size: 12px;
                height: 85px;
                overflow-y: scroll;
                background-color: white;
                margin: 0;
                max-height: 88px;
                // &::-webkit-scrollbar {
                //   display: none;
                // }
              }
            }
          }

          .ant-collapse-item-active {
            position: relative;
            .ant-collapse-header {
              color: red !important;
            }
          }
        }
      }
    }
  }
  @media (max-width: 310px) {
    padding: 10px 0;
    .container {
      .move-button {
        display: block;
        margin: 20px 4%;
      }
      .right_button {
        display: none;
      }
      .collapse-item {
        display: flex;
        justify-content: space-between;
        .space {
          padding: 0;
          display: none;
        }

        .mobie-show {
          display: block !important;
        }
        .faq-collapse {
          display: none;
          flex-wrap: wrap;
          flex: 1;
          margin: 0;

          .ant-collapse-header {
            height: 60px;
            font-size: 12px;
            line-height: 2.5em;
            background-color: #f7f9fc;
            font-weight: 500;
            margin: 0 1%;
            margin-top: 12px;
            &:hover {
              background-color: #f7f9fc;
            }
          }

          .ant-collapse-item {
            .ant-collapse-content {
              margin: 20px 0;
              margin-bottom: 0;
              .ant-collapse-content-box {
                font-size: 12px;
                height: 85px;
                overflow-y: scroll;
                background-color: white;
                margin: 0;
                max-height: 88px;
                // &::-webkit-scrollbar {
                //   display: none;
                // }
              }
            }
          }
        }
      }
    }
  }
`;

export default function GTFAQ(props) {
  const { t } = useTranslation();
  const lng = window.localStorage.getItem('i18nextLng');
  return (
    <FAQStyle >
      <GTTitle
        title={t("contect_faq.main_title")}
        position="left"
        mobieTitle={"contect_faq.main_title"}
      />
      <div className="container wrap-v1">
        <div className="right_button">
          <div className="title">{t("home.contact_faq_title")}</div>
          <GTButton
            title={t("home.join_props_title")}
            mobieTitle="routes-media.watch"
            width="auto"
            changeLink={(e) => {
              props.history.push("/contect-faq");
            }}
          />
        </div>
        {
          <div className="collapse-item">
            <Collapse
              destroyInactivePanel
              expandIconPosition={"right"}
              expandIcon={({ isActive }) =>
                isActive ? (
                  <MinusOutlined style={{ color: "red", fontSize: 25 }} />
                ) : (
                  <PlusOutlined style={{ fontSize: 25 }} />
                )
              }
              accordion={true}
              bordered={false}
              // defaultActiveKey={["0"]}
              className="faq-collapse"
              ghost
            >
              <Panel
                forceRender={true}
                key="1"
                header={'01.' + t("faq.title1")}
              >
                <div dangerouslySetInnerHTML={{__html: t("faq.desc1")}}></div>
              </Panel>
              <Panel
                forceRender={true}
                key="3"
                header={'03.' + t("faq.title3")}
              >
                <div dangerouslySetInnerHTML={{__html: t("faq.desc3")}}></div>
              </Panel>
              <Panel
                forceRender={true}
                key="5"
                header={'05.' + t("faq.title5")}
              >
                <div dangerouslySetInnerHTML={{__html: t("faq.desc5")}}></div>
              </Panel>
            </Collapse>
            <Collapse
              destroyInactivePanel
              expandIconPosition={"right"}
              expandIcon={({ isActive }) =>
                isActive ? (
                  <MinusOutlined style={{ color: "#F33500", fontSize: 25 }} />
                ) : (
                  <PlusOutlined style={{ fontSize: 25 }} />
                )
              }
              accordion={true}
              bordered={false}
              className="faq-collapse space"
              ghost
            >
              <Panel
                forceRender={true}
                key="2"
                header={'02.' + t("faq.title2")}
              >
                <div dangerouslySetInnerHTML={{__html: t("faq.desc2")}}></div>
              </Panel>
              <Panel
                forceRender={true}
                key="4"
                header={'04.' + t("faq.title4")}
              >
                <div dangerouslySetInnerHTML={{__html: t("faq.desc4")}}></div>
              </Panel>
              <Panel
                forceRender={true}
                key="6"
                header={'06.' + t("faq.title6")}
              >
                <div dangerouslySetInnerHTML={{__html: t("faq.desc6")}}></div>
              </Panel>
            </Collapse>
            <Collapse
              destroyInactivePanel
              expandIconPosition={"right"}
              expandIcon={({ isActive }) =>
                isActive ? (
                  <MinusOutlined style={{ color: "#F33500", fontSize: 25 }} />
                ) : (
                  <PlusOutlined style={{ fontSize: 25 }} />
                )
              }
              accordion={true}
              bordered={false}
              className="faq-collapse space mobie-show"
              ghost
            >
              {["01", "02", "03"].map((item, index) => {
                return (
                  <Panel
                    forceRender={true}
                    header={`${item}` + t(`faq.title${index+1}`)}
                    key={index}
                  >
                    <div dangerouslySetInnerHTML={{__html: t(`faq.desc${index+1}`)}}></div>
                  </Panel>
                );
              })}
            </Collapse>
          </div>
        }
        <div className="move-button">
          <GTButton
            changeLink={(e) => {
              props.history.push("/faq");
            }}
            title={t("home.join_props_title")}
            mobieTitle="routes-media.watch"
          />
        </div>
      </div>
    </FAQStyle>
  );
}
