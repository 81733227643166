import request from '.';
// import I18n from "@/I18n";
// 语言分类
//zh-CN
//en
//zh-TW


// 视频分类数据  https://72.14.183.204:8080/com/video/type/en
export function getSixVidelColom() {
    return request({
        url: '/com/video/type/en'
    })
}

//视频数据  https://72.14.183.204:8080/com/video/list?language=zh-CN&type=Lectures&num=5
export async function getVideo(type) {
    return await request({
        url: "/com/video/list",
        method: "GET",
        params: {
            "language": localStorage.getItem("i18nextLng"),
            // 接口必填num 可和后端交流去除num字段请求所有数据
            "num": 11115,
            type
        }
    })
}

// https://72.14.183.204:8080/com/news/list POST请求
export function getNews(page, type, size) {
    // language为en才能请求到新闻数据
    return request({
        url: '/com/news/list',
        method: "POST",
        data: {
            "language": localStorage.getItem("i18nextLng"),
            "page": page,
            "size": size,
            "type": type
        }
    })
}
export function saveInfo(selfName, selfPhone, selfEmail, content, friendName, friendPhone, friendEmail) {
    return request({
        url: '/public/saveInfo',
        method: "POST",
        data: {
            selfName: selfName,
            selfPhone: selfPhone,
            selfEmail: selfEmail,
            content: content,
            friendName: friendName,
            friendPhone: friendPhone,
            friendEmail: friendEmail,
        }
    })
}



// 新闻分类标题
// https://72.14.183.204:8080/com/news/type/zh-CN
export function getnewColunm(language) {
    return request({
        url: '/com/news/type/' + language
    })
}


// 根据ID查询新闻
export function getIdData() {
    return request({
        url: '/com/news/get/65163fbda8dc8e6255b6554470095db2'
    })
}
// 字典
export function getDict(params) {
    const type = params ? params : 'NEWS_TYPE';
    return request({
        url: `/public/dict/${type}`,
        method: "get",
    })
}

// 英文版图片列表
export function getEnglishList(params) {
    return request({
        url: '/public/programList',
        method: "get",
    })
}