/**
 * 路由地址配置
 */

export const routes_info = [
  {
    link: "/home",
    title: "routes.Home",
  },
  {
    link: "/specialties",
    title: "routes.Specialties",
  },
  {
    link: "/testimonials",
    title: "routes.Testimonials",
  },
  {
    link: "/lectures",
    title: "routes.Lectures",
  },
  {
    link: "/vision-career",
    title: "routes.Vision_Career",
  },
  {
    link: "/reward-promotion",
    title: "routes.Reward_Promotion",
  },
  {
    link: "/contect-faq",
    title: "routes.Contect_FAQ",
  },
];

export const routes_media_info = [
  {
    link: "/home",
    title: "routes-media.Home",
  },
  {
    link: "/specialties",
    title: "routes-media.Specialties",
  },
  {
    link: "/testimonials",
    title: "routes-media.Testimonials",
  },
  {
    link: "/lectures",
    title: "routes-media.Lectures",
  },
  {
    link: "/vision-career",
    title: "routes-media.Vision_Career",
  },
  {
    link: "/reward-promotion",
    title: "routes-media.Reward_Promotion",
  },
  {
    link: "/contect-faq",
    title: "routes-media.Contect",
  },
  {
    link: "/faq",
    title: "routes-media.Faq",
  },
];

// 第一级分类
export const video_volunm_info = [
  {
    title: "special.colunm.title1",
    type: "Taxman_Secret",
    imgUrl: require("../assets/img/Poster 01.jpg"),
    imgUrl2: require("../assets/img/Poster 02.jpg"),
    icon2xUrl: require("../assets/img/come1@2x.png"),
    icon3xUrl: require("../assets/img/come1@3x.png"),
  },
  {
    title: "special.colunm.title2",
    type: "Corporate_Tax Strategies",
    imgUrl: require("../assets/img/Poster 03.jpg"),
    imgUrl2: require("../assets/img/Poster 04.jpg"),
    icon2xUrl: require("../assets/img/come2@2x.png"),
    icon3xUrl: require("../assets/img/come2@3x.png"),
  },
  {
    title: "special.colunm.title3",
    type: "Executor Tactics",
    imgUrl: require("../assets/img/Poster 05.jpg"),
    imgUrl2: require("../assets/img/Poster 06.jpg"),
    icon2xUrl: require("../assets/img/come3@2x.png"),
    icon3xUrl: require("../assets/img/come3@3x.png"),
  },
  {
    title: "special.colunm.title4",
    type: "Ways_Property",
    imgUrl: require("../assets/img/Poster 37.jpg"),
    imgUrl2: require("../assets/img/Poster 37.jpg"),
    icon2xUrl: require("../assets/img/come4@2x.png"),
    icon3xUrl: require("../assets/img/come4@3x.png"),
  },
  {
    title: "special.colunm.title5",
    type: "RRSP_Tax",
    imgUrl: require("../assets/img/Poster 09.jpg"),
    imgUrl2: require("../assets/img/Poster 10.jpg"),
    icon2xUrl: require("../assets/img/come5@2x.png"),
    icon3xUrl: require("../assets/img/come5@3x.png"),
  },
  {
    title: "special.colunm.title6",
    type: "Life_Long",
    imgUrl: require("../assets/img/Poster 11.jpg"),
    imgUrl2: require("../assets/img/Poster 12.jpg"),
    icon2xUrl: require("../assets/img/come6@2x.png"),
    icon3xUrl: require("../assets/img/come6@3x.png"),
  },
  {
    title: "special.colunm.title7",
    type: "Rebound_Investment",
    imgUrl: require("../assets/img/Poster 13.jpg"),
    imgUrl2: require("../assets/img/Poster 14.jpg"),
    icon2xUrl: require("../assets/img/come7@2x.png"),
    icon3xUrl: require("../assets/img/come7@3x.png"),
  },
  {
    title: "special.colunm.title8",
    type: "DIY_Tax",
    imgUrl: require("../assets/img/Poster 15.jpg"),
    imgUrl2: require("../assets/img/Poster 16.jpg"),
    icon2xUrl: require("../assets/img/come8@2x.png"),
    icon3xUrl: require("../assets/img/come8@3x.png"),
  },
];

export const lectures_volunm_info = [
  {
    title: "lectures.colunm.title1",
    type: "Taxman_Secret",
    imgUrl: require("../assets/img/Poster 17.jpg"),
    imgUr2: require("../assets/img/Poster 18.jpg"),
    zhCn: "https://youtu.be/h4UHfRzVOtk",
    zhTw: "https://youtu.be/OYbe0C06V98",
    en: "https://youtu.be/h4UHfRzVOtk",
  },
  {
    title: "lectures.colunm.title2",
    type: "Corporate_Tax Strategies",
    imgUrl: require("../assets/img/Poster 19.jpg"),
    imgUr2: require("../assets/img/Poster 20.jpg"),
    zhCn: "https://youtu.be/6bSp1kJT5GM",
    zhTw: "https://youtu.be/gA05XS0Fpco",
    en: "https://youtu.be/6bSp1kJT5GM",
  },
  {
    title: "lectures.colunm.title3",
    type: "Executor Tactics",
    imgUrl: require("../assets/img/Poster 21.jpg"),
    imgUr2: require("../assets/img/Poster 22.jpg"),
    zhCn: "https://youtu.be/4IkXKcTumy0",
    zhTw: "https://youtu.be/zbi8Ee3Fqu0",
    en: "https://youtu.be/4IkXKcTumy0",
  },
  {
    title: "lectures.colunm.title4",
    type: "Ways_Property",
    imgUrl: require("../assets/img/Poster 35.jpg"),
    // imgUr2: require("../assets/img/Poster 24.jpg"),
    imgUr2: require("../assets/img/Poster 35.jpg"),
    zhCn: "https://youtu.be/8zIpReVJ1Zg",
    zhTw: "https://youtu.be/8zIpReVJ1Zg",
    en: "https://youtu.be/8zIpReVJ1Zg",
  },
  {
    title: "lectures.colunm.title5",
    type: "RRSP_Tax",
    imgUrl: require("../assets/img/Poster 25.jpg"),
    imgUr2: require("../assets/img/Poster 26.jpg"),
    zhCn: "https://youtu.be/PbIOtwgPfXQ",
    zhTw: "https://youtu.be/_sK39Rm0_UQ",
    en: "https://youtu.be/_sK39Rm0_UQ",
  },
  {
    title: "lectures.colunm.title6",
    type: "Life_Long",
    imgUrl: require("../assets/img/Poster 27.jpg"),
    imgUr2: require("../assets/img/Poster 28.jpg"),
    zhCn: "https://youtu.be/f4QTR_3k790",
    zhTw: "https://youtu.be/yDNfDz9NUAw",
    en: "https://youtu.be/f4QTR_3k790",
  },
  {
    title: "lectures.colunm.title7",
    type: "Rebound_Investment",
    imgUrl: require("../assets/img/Poster 29.jpg"),
    imgUr2: require("../assets/img/Poster 30.jpg"),
    zhCn: "https://youtu.be/smDCKdxmTAI",
    zhTw: "https://youtu.be/UQ5ZnUxB56Q",
    en: "https://youtu.be/smDCKdxmTAI",
  },
  {
    title: "lectures.colunm.title8",
    type: "DIY_Tax",
    imgUrl: require("../assets/img/Poster 36.jpg"),
    // imgUr2: require("../assets/img/Poster 32.jpg"),
    imgUr2: require("../assets/img/Poster 36.jpg"),
    // zhCn: "https://youtu.be/4IkXKcTumy0",
    zhCn: "https://youtu.be/qrlDJEC7Q-A",
    zhTw: "https://youtu.be/qrlDJEC7Q-A",
    en: "https://youtu.be/qrlDJEC7Q-A",
  },
];

export const home_testimonals_info = [
  {
    imgUrl: require("../assets/img/CS recognition 1.jpg"),
    link: require("../assets/img/Recording 1_Max Ou.mp3"),
  },
  {
    imgUrl: require("../assets/img/CS recognition 2.jpg"),
    link: require("../assets/img/Recording_2_May Yin.mp3"),
  },
  {
    imgUrl: require("../assets/img/CS recognition 3.jpg"),
    link: require("../assets/img/Recording 3_Ted Ip.mp3"),
  },
  {
    imgUrl: require("../assets/img/CS recognition 4.jpg"),
    link: require("../assets/img/Recording 4_Ada.mp3"),
  },
  {
    imgUrl: require("../assets/img/CS recognition 5.jpg"),
    link: require("../assets/img/Recordings 5_Samuel Wu.mp3"),
  },
];

export const testimonials_pic_info = [
  {
    id: "picinfo1",
    imgUrl: require("../assets/img/CS recognition 1.jpg"),
    title: "testimonials.pic.title1",
    samlltitle: "Max",
    link: require("../assets/img/Recording 1_Max Ou.mp3"),
  },
  {
    id: "picinfo2",
    imgUrl: require("../assets/img/CS recognition 2.jpg"),
    title: "testimonials.pic.title2",
    samlltitle: "May",
    link: require("../assets/img/Recording_2_May Yin.mp3"),
  },
  {
    id: "picinfo3",
    imgUrl: require("../assets/img/CS recognition 3.jpg"),
    title: "testimonials.pic.title3",
    samlltitle: "Ted",
    link: require("../assets/img/Recording 3_Ted Ip.mp3"),
  },
  {
    id: "picinfo4",
    imgUrl: require("../assets/img/CS recognition 4.jpg"),
    title: "testimonials.pic.title4",
    samlltitle: "Ada",
    link: require("../assets/img/Recording 4_Ada.mp3"),
  },
  {
    id: "picinfo5",
    imgUrl: require("../assets/img/CS recognition 5.jpg"),
    title: "testimonials.pic.title5",
    samlltitle: "Samuel",
    link: require("../assets/img/Recordings 5_Samuel Wu.mp3"),
  },
];
