import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Popover } from "antd";

import I18nButton from "../I18nButton";
const wechat = require("@/assets/img/wechat.png");
const icon_call = require("@/assets/img/icon_call@2x.png");
const icon_facebook = require("@/assets/img/icon_facebook@2x.png");
const icon_inst = require("@/assets/img/icon_Instagram@2x.png");
const icon_twitter = require("@/assets/img/icon_twitter@2x.png");
const icon_wechart = require("@/assets/img/icon_wechart@2x.png");
const icon_Youtub = require("@/assets/img/icon-youtube2.png");

const content = <img src={wechat} alt="" style={{ width: "200px" }} />;

function TopBanner() {
  const { t } = useTranslation();
  return (
    <TopbannerWrapper className="sprite_09">
      <div className="wrap-v1 flex">
        <TopbannerLeft>
          {/* <ul>
            <li>{t("topbanner.wellcome")}</li>
            <li>{t("topbanner.fax")}</li>
            <li>{t("topbanner.phone")}</li>
          </ul> */}
        </TopbannerLeft>
        <TopbannerRight>
          <div className="img_item">
            <img
              src={icon_call}
              alt=""
              onClick={(e) => {
                window.open("tel:6047612888");
              }}
              className="call-img c"
            />
            <Popover
              content={content}
              title={t("topbanner.wechat_connect")}
              placement="bottom"
              overlayInnerStyle={{
                textAlign: "center",
                width: "100%",
                height: "100%",
                fontSize: "16px",
              }}
            >
              <img src={icon_wechart} alt="" className="wechart-img" />
            </Popover>

            <img
              src={icon_facebook}
              alt=""
              className="facebook-img"
              onClick={(e) => {
                window.open("https://www.facebook.com/GTWealthCanada/");
              }}
            />
            <img
              src={icon_Youtub}
              alt=""
              className="facebook-img"
              onClick={(e) => {
                window.open("https://www.youtube.com/user/GrandtagGroup");
              }}
            />
            {/* <img
              src={icon_twitter}
              alt=""
              className="twitter-img"
              onClick={(e) => {
                window.open("https://www.youtube.com/watch?v=ZoEcjD8tEuE");
              }}
            />
            <img
              src={icon_inst}
              alt=""
              className="inst-img"
              onClick={(e) => {
                window.open("https://www.youtube.com/watch?v=ZoEcjD8tEuE");
              }}
            /> */}
          </div>
        </TopbannerRight>
        <I18nButton />
      </div>
    </TopbannerWrapper>
  );
}

const TopbannerWrapper = styled.div`
  height: 40px;
  font-size: 14px;
  color: #fff;
  .flex {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 768px) {
    display: ${window.location.pathname === "/en" ? "" : "none"};
  }
`;

const TopbannerLeft = styled.div`
  flex: 3;
  ul {
    width: 60%;
    display: flex;
    justify-content: space-between;
  }
  @media (min-width: 800px) and (max-width: 1400px) {
    ul {
      width: 67%;
    }
  }
`;

const TopbannerRight = styled.div`
  .img_item {
    margin-right: 38px;
    cursor: pointer;
    img {
      margin: 0 7px;
      cursor: pointer;
    }
    .call-img {
      width: 20.33px;
      height: 20.37px;
    }
    .facebook-img {
      width: 23.25px;
      height: 23.11px;
    }
    .google-img {
      width: 19.13px;
      height: 19.5px;
    }
    .inst-img {
      width: 20px;
      height: 20px;
    }
    .twitter-img {
      width: 24px;
      height: 19.49px;
    }
    .wechart-img {
      width: 27px;
      height: 22px;
    }
  }
`;

export default memo(TopBanner);
