import styled from "styled-components";
export const AboutGrandTagStyle = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 56px 0;
  .container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    margin-right: 40px;
    padding: 0 33px 0 50px;
    background-color: #f2f5f7;
    flex: 1;
    .title {
      display: block;
      padding-top: 10px;
      font-size: 48px;
      color: #002369;
      line-height: 101px;
    }

    .gt_mobie_title {
      display: none;
    }
    .desc {
      font-size: 20px;
      line-height: 40px;
      color: #182848;
    }

    img {
      width: 100%;
    }
  }

  .man-bgc {
    width: 452px;
    height: 857px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 0;
    .container {
      flex-direction: column-reverse;
      margin-right: auto;
      padding: 0;
      .content {
        background-color: #fff;
      }
      .gt_position_title {
        position: relative;
      }
      .title {
        display: none;
      }

      .gt_mobie_title {
        display: block;
        position: absolute;
        color: #fff;
        left: 4%;
        bottom: -5%;
        max-width: 30%;
        font-size: 20px;
        line-height: 24px;
        padding: 4%;
        background-color: #f33500;
        opacity: 0.9;
      }
      .desc {
        margin: 8% 4%;
        margin-bottom: 0;
        font-size: 18px;
        line-height: 30px;
      }
      img {
        height: 230px;
      }
    }
    .man-bgc {
      display: none;
    }
  }

  @media (max-width: 376px) {
    padding: 0;
    .container {
      flex-direction: column-reverse;
      margin-right: auto;
      padding: 0;
      .content {
        background-color: #fff;
      }
      .gt_position_title {
        position: relative;
      }
      .title {
        display: none;
      }

      .gt_mobie_title {
        display: block;
        position: absolute;
        color: #fff;
        left: 4%;
        bottom: -5%;
        max-width: 35%;
        font-size: 20px;
        line-height: 24px;
        padding: 4%;
        background-color: #f33500;
        opacity: 0.9;
      }
      .desc {
        font-size: 18px;
        line-height: 30px;
      }
      img {
        height: 230px;
      }
    }
    .man-bgc {
      display: none;
    }
  }
`;
