import React, { useRef } from "react";

import styled from "styled-components";
import { Carousel } from "antd";
import GTTestText from "./TestimonialsSmallText";
import GTTestMobieText from "./TestimonialsSmallText/Mobie";

import avils1 from "@/assets/img/Testmonials_textavils-01.png";
import avils2 from "@/assets/img/Testmonials_textavils-02.png";
import avils3 from "@/assets/img/Testmonials_textavils-03.png";

const TestimonialsTextStyle = styled.div`
  background-color: #f2f5f7;
  padding: 132px 0;
  .title {
    padding-bottom: 35px;
    font-size: 48px;
    font-weight: 700;
    line-height: 101px;
    text-align: center;
  }
  .testimonials-text {
    display: block;
    width: 100%;
    padding: 0 !important;
    .page {
      background: #f2f5f7;
      display: flex !important;
      justify-content: space-between;
      align-items: center;
    }
    .list {
      display: flex;
    }
  }
  .testimonials-mobie-text {
    display: none;
  }

  @media (max-width: 768px) {
    padding: 10% 0;
    background-color: #f7f9fc;
    .title {
      padding: 0;
      font-size: 20px;
      line-height: 2em;
    }
    .testimonials-text {
      margin: 0 3%;
      display: none !important;
    }
    .testimonials-mobie-text {
      display: block;

      .page {
        display: flex;
        overflow: scroll;
      }
    }
  }
`;

export default function TestimonialsText() {
  const pageRef = useRef();
  let arr = [
    {
      pic: avils3,
      name: "Michelle",
      text: "讲解好清晰, 市場分析數據清楚, 解答到了一些自己的投资误区👍🏻👍",
    },
    {
      pic: avils2,
      name: "Amber",
      text: "很有幫助的講座, 講師清楚講解實用案例, 即場解答觀眾問題!",
    },
    {
      pic: avils1,
      name: "Anna",
      text: "Valuable lesson to learn. Increasing my view and planning of life. I like join more such Meeting",
    },
  ];
  return (
    <TestimonialsTextStyle>
      <div className="wrap-v1">
        <Carousel dots={true} className="testimonials-text" ref={pageRef}>
          {[0].map((item, index) => {
            return (
              <div key={index} className="page">
                {arr.slice(item * 3, (item + 1) * 3).map((item, index) => {
                  return (
                    <div key={item}>
                      <GTTestText list={item} index={item} />
                    </div>
                  );
                })}
              </div>
            );
          })}
        </Carousel>
        <div className="testimonials-mobie-text">
          <div className="page">
            {arr.map((item, index) => {
              return <GTTestMobieText list={item} key={index} index={index} />;
            })}
          </div>
        </div>
      </div>
    </TestimonialsTextStyle>
  );
}
