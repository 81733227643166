import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const logo = require("@/assets/img/Testimonials_Textlogo@2x.png");
const avils1 = require("@/assets/img/Testmonials_textavils-01.png");
const avils2 = require("@/assets/img/Testmonials_textavils-02.png");
const avils3 = require("@/assets/img/Testmonials_textavils-03.png");
const line = require("@/assets/img/Testmonials_Textline@2x.png");
const dropdown = require("@/assets/img/Home_Test_DropDown@2x.png");
const GTTestTextStyle = styled.div`
  max-width: 400px;
  height: 450px;
  background-color: #fff;
  position: relative;
  padding: 3%;
  padding-top: 6%;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    .logo {
      position: absolute;
      top: 5%;
      left: 8%;
      width: 35px;
      height: 29px;
    }
    .titles {
      font-size: 32px;
      line-height: 36px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .line {
        margin-top: 14px;
        width: 35px;
        height: 3px;
      }
    }

    .avils {
      width: 103px;
      height: 103px;
      border-radius: 50%;
      margin: 2% 0;
      margin-bottom: 0;
    }
    .name {
      margin: 6px 0;
      color: #707070;
      font-size: 18px;
      line-height: 24px;
    }
    .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      .desc {
        position: relative;
        display: -webkit-box;
        overflow: hidden;
        margin: 6% 4%;
        margin-bottom: 2%;
        font-size: 18px;
        line-height: 1.6em;
        text-overflow: ellipsis;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }
      .btn {
        color: #fff;
        font-size: 16px;
        border-radius: 4px;
        cursor: pointer;
      }
    }
    .showwrapper {
      .desc {
        -webkit-line-clamp: 999 !important;
      }
      .avils {
        display: none;
      }
      .name {
        display: none;
      }
    }
    .dropdown {
      display: block;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 0;
      img {
        width: 65px;
      }
    }
  }
  @media (min-width: 800px) and (max-width: 1400px) {
    max-width: 330px;
  }
`;
export default function TestimonialsSmallText(props) {
  const { t } = useTranslation();
  const WrapperRef = useRef();
  let currentIndex = false;
  return (
    <GTTestTextStyle>
      <div className="container">
        <img src={logo} alt="" className="logo" />
        <div className="titles">
          <div className="fontstyle_SB">
            {/* t("testimonials.small-title.title")}{" " */}
            {props.list.name}
          </div>
          <img src={line} alt="" className="line" />
        </div>
        <div className="wrapper" ref={WrapperRef}>
          <div className="desc">
            {props.list.text}
            {/* t("testimonials.small-title.desc") */}
          </div>
          {/* <div
            className="btn"
            onClick={(e) => {
              if (!currentIndex) {
                currentIndex = true;
                WrapperRef.current.className = "wrapper showwrapper";
              } else {
                currentIndex = false;
                WrapperRef.current.className = "wrapper";
              }
            }}
          >
            <div className="dropdown">
              <img src={dropdown} alt="" />
            </div>
          </div> */}
          <img src={props.list.pic} alt="" className="avils" />
          <div className="name">
            {props.list.name}
            {/* t("testimonials.small-title.name") */}
          </div>
        </div>
      </div>
    </GTTestTextStyle>
  );
}
