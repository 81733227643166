import React, { useState } from "react";
import styled from "styled-components";
import RewardHeader from "./RewardHeader";
import SpecialistBriefingVideo from "@/pages/GT_Specialtiesvideo/";
import RewardTabs from "./RewardTabs/index";
import RewardMobieTabs from "./RewardTabs/mobie";
import { useTranslation } from "react-i18next";
import GTTodo from "../../pages/GT_Todo";
import { withRouter } from "react-router";
// import { renderRoutes } from "react-router-config";

// import routes from "@/routes";
const RewardWrapper = styled.div``;

function GT_RewardPromontion(props) {
  const { t } = useTranslation();
  const ispc = document.body.clientWidth >= 768 ? true : false;
  return (
    <RewardWrapper className="fix-margin-top">
      <RewardHeader {...props} />
      {ispc ? <RewardTabs {...props} /> : <RewardMobieTabs {...props} />}
      <div className="specialties-container">
        <SpecialistBriefingVideo title={t("special.video-title")} />
      </div>
      <GTTodo />
    </RewardWrapper>
  );
}

export default withRouter(GT_RewardPromontion);
