import React, { useState, useRef } from "react";
import styled from "styled-components";
import { withRouter } from "react-router";
import TestimonialsHeader from "./TestimonialsHeader";
import TestimonialsText from "./TestimonialsText";
import GTTodo from "@/pages/GT_Todo";
import SpecialtiesBriefingVideo from "../../pages/GT_Specialtiesvideo";

// import TestimonialsAudioWrapper from "./TestimonialsAudio";
// import TestimonialsPicWrapper from "./TestimonialsPic";
import TestimonialsReferralWrapper from "./TestimonialsReferral";
import { useTranslation } from "react-i18next";
import GTModalVideo from "@/pages/GT_ModalVideo";
import { testimonials_pic_info } from "../../mocks/leter_data";

const video_play = require("../../assets/img/Testimonials_video_play@2x.png");
const headerbgc = require("@/assets/img/Testimonials_header@2x.jpg");
const whitelogo = require("@/assets/img/testimonials_whitelogo@2x.png");

const audio_music_img = require("@/assets/img/Testimonials_audio_icon_music@2x.png");
const active_music_img = require("../../assets/img/Testimonials_active_music.gif");
const audio_radio_group_right = require("../../assets/img/Testimonials_audio_radio@2x.png");
const audio_radio_group_left = require("../../assets/img/Testimonials_audio_radiogroup@2x.png");
const audioSrc1 = require("../../assets/img/Recording 1_Max Ou.mp3");
const audioSrc2 = require("../../assets/img/Recording_2_May Yin.mp3");
const audioSrc3 = require("../../assets/img/Recording 3_Ted Ip.mp3");
const audioSrc4 = require("../../assets/img/Recording 4_Ada.mp3");
const audioSrc5 = require("../../assets/img/Recordings 5_Samuel Wu.mp3");

const pic_icon = require("../../assets/img/Video_white_play@2x.png");
const video_white_play = require("../../assets/img/Video_white_play@2x.png");

const TestimonialsStyle = styled.div``;
let musicArr = [
  {
    imgUrl: audio_music_img,
    music: require("../../assets/img/Recording 1_Max Ou.mp3"),
  },
  {
    imgUrl: audio_music_img,
    music: require("../../assets/img/Recording_2_May Yin.mp3"),
  },
  {
    imgUrl: audio_music_img,
    music: require("../../assets/img/Recording 3_Ted Ip.mp3"),
  },
  {
    imgUrl: audio_music_img,
    music: require("../../assets/img/Recording 4_Ada.mp3"),
  },
  {
    imgUrl: audio_music_img,
    music: require("../../assets/img/Recordings 5_Samuel Wu.mp3"),
  },
];

function GT_Testimonials(props) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [rotation, setRotation] = useState(false);
  const [rotation2, setRotation2] = useState("");
  const [rotation3, setRotation3] = useState("");
  // const audioList = document.getElementsByClassName("audioList2");
  // const audioList2 = document.getElementsByClassName("audioList2");
  // const audioList3 = document.getElementsByClassName("audioList3");
  const audioDiv = document.getElementById("audioDiv");
  const audio = document.getElementsByTagName("audio");
  const imgs = document.getElementsByClassName("pic_middle");
  const imgs2 = document.getElementsByClassName("audio_pic_bottom");

  const audioA = useRef();
  const audioList1 = useRef();
  const audioList2 = useRef();
  const audioList3 = useRef();
  const audioList4 = useRef();
  const audioList5 = useRef();
  const audioList6 = useRef();
  const audioList7 = useRef();
  const audioList8 = useRef();
  const audioList9 = useRef();
  const audioList10 = useRef();
  const stopFn = (e) => {
    if (e === 1) {
      setRotation(false);
    } else if (e === 2) {
      setRotation2("");
    } else {
      setRotation3("");
    }
  };
  return (
    <TestimonialsStyle className="fix-margin-top">
      {/* Testimonals顶部页面区域 */}
      {/* <TestimonialsHeader {...props} /> */}
      <TestimonialsHeaderStyle>
        {/* <GTModalVideo show={show} setShow={setShow} id="TestimonialsHeader" /> */}
        <div
          className="header-bgc cursor"
          onClick={(e) => {
            audioList1.current.pause();
            audioList2.current.pause();
            audioList3.current.pause();
            audioList4.current.pause();
            audioList5.current.pause();
            audioList6.current.pause();
            audioList7.current.pause();
            audioList8.current.pause();
            audioList9.current.pause();
            audioList10.current.pause();
            setRotation2("");
            setRotation3("");
            if (audioA.current.paused) {
              audioA.current.play();
              setRotation(true);
            } else {
              audioA.current.pause();
              setRotation(false);
            }
          }}
        >
          <div className="music_top">
            <img src={headerbgc} alt="" className="headers-bgc" />
            <img
              src={video_play}
              alt=""
              className={rotation === true ? "video-play cur" : "video-play"}
              id="pic_top"
            />
          </div>

          <audio
            onEnded={() => {
              stopFn(1);
            }}
            src={audioSrc5}
            id="audioDiv"
            ref={audioA}
          >
            您的浏览器不支持 audio 标签。
          </audio>
        </div>
        <div className="content fontstyle_SB">
          <div className="title ">{t("testimonials.header-title")}</div>
          <div className="small-title">
            {t("testimonials.header-small-title")}
          </div>
          <div className="white-logo">
            <img src={whitelogo} alt="" />
          </div>
        </div>
      </TestimonialsHeaderStyle>

      {/* Testimonals页面下的视图片展示区域 */}
      {/* <TestimonialsPicWrapper {...props} /> */}
      <TestimonialsPicWrapper className="wrap-v1">
        {testimonials_pic_info.map((item, index) => (
          <div className="content" key={index}>
            {/* <GTModalVideo show={show} setShow={setShow} id={"testimonialsPic" + index} /> */}
            <div
              className="position_bgc"
              onClick={(e) => {
                audioA.current.pause();
                audioList6.current.pause();
                audioList7.current.pause();
                audioList8.current.pause();
                audioList9.current.pause();
                audioList10.current.pause();
                setRotation(false);
                setRotation3("");
                if (index === 0) {
                  if (audioList1.current.paused) {
                    audioList1.current.play();
                    setRotation2(index);
                  } else {
                    audioList1.current.pause();
                    setRotation2("");
                  }
                  audioList2.current.pause();
                  audioList3.current.pause();
                  audioList4.current.pause();
                  audioList5.current.pause();
                }
                if (index === 1) {
                  if (audioList2.current.paused) {
                    audioList2.current.play();
                    setRotation2(index);
                  } else {
                    audioList2.current.pause();
                    setRotation2("");
                  }
                  audioList1.current.pause();
                  audioList3.current.pause();
                  audioList4.current.pause();
                  audioList5.current.pause();
                }
                if (index === 2) {
                  if (audioList3.current.paused) {
                    audioList3.current.play();
                    setRotation2(index);
                  } else {
                    audioList3.current.pause();
                    setRotation2("");
                  }
                  audioList1.current.pause();
                  audioList2.current.pause();
                  audioList4.current.pause();
                  audioList5.current.pause();
                }
                if (index === 3) {
                  if (audioList4.current.paused) {
                    audioList4.current.play();
                    setRotation2(index);
                  } else {
                    audioList4.current.pause();
                    setRotation2("");
                  }
                  audioList1.current.pause();
                  audioList2.current.pause();
                  audioList3.current.pause();
                  audioList5.current.pause();
                }
                if (index === 4) {
                  if (audioList5.current.paused) {
                    audioList5.current.play();
                    setRotation2(index);
                  } else {
                    audioList5.current.pause();
                    setRotation2("");
                  }
                  audioList1.current.pause();
                  audioList2.current.pause();
                  audioList3.current.pause();
                  audioList4.current.pause();
                }
              }}
            >
              <div className="pic_bgc">
                <img src={item.imgUrl} alt="" />
              </div>
              <img
                src={pic_icon}
                alt=""
                className={
                  rotation2 === index
                    ? "pic_icon pic_middle cur"
                    : "pic_icon pic_middle"
                }
              />
            </div>
            <div className="title">{t(item.title)}</div>
            <div className="small_title">{t(item.samlltitle)}</div>
          </div>
        ))}
        <div>
          <audio
            onEnded={() => {
              stopFn(2);
            }}
            src={audioSrc1}
            className="audio_el audioList"
            ref={audioList1}
          >
            您的浏览器不支持 audio 标签。
          </audio>
          <audio
            onEnded={() => {
              stopFn(2);
            }}
            src={audioSrc2}
            className="audio_el audioList"
            ref={audioList2}
          >
            您的浏览器不支持 audio 标签。
          </audio>
          <audio
            onEnded={() => {
              stopFn(2);
            }}
            src={audioSrc3}
            className="audio_el audioList"
            ref={audioList3}
          >
            您的浏览器不支持 audio 标签。
          </audio>
          <audio
            onEnded={() => {
              stopFn(2);
            }}
            src={audioSrc4}
            className="audio_el audioList"
            ref={audioList4}
          >
            您的浏览器不支持 audio 标签。
          </audio>
          <audio
            onEnded={() => {
              stopFn(2);
            }}
            src={audioSrc5}
            className="audio_el audioList"
            ref={audioList5}
          >
            您的浏览器不支持 audio 标签。
          </audio>
        </div>
      </TestimonialsPicWrapper>

      {/* Testimonals页面下的音频展示区域,功能不知道点击能不能播放音乐,但得实现一个动态效果 */}
      {/* <TestimonialsAudioWrapper /> */}
      <TestimonialsAudioWrapper>
        <div className="container sprite_07">
          <img
            src={audio_radio_group_left}
            alt=""
            className="audio_radio_left"
          />
          <div className="wrap-v1" style={{ height: "100%" }}>
            <div className="content">
              {musicArr.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="audio_item"
                    onClick={() => {
                      audioA.current.pause();
                      audioList1.current.pause();
                      audioList2.current.pause();
                      audioList3.current.pause();
                      audioList4.current.pause();
                      audioList5.current.pause();
                      setRotation2("");
                      setRotation(false);
                      if (index === 0) {
                        if (audioList6.current.paused) {
                          audioList6.current.play();
                          setRotation3(index);
                        } else {
                          audioList6.current.pause();
                          setRotation3("");
                        }
                        audioList7.current.pause();
                        audioList8.current.pause();
                        audioList9.current.pause();
                        audioList10.current.pause();
                      }
                      if (index === 1) {
                        if (audioList7.current.paused) {
                          audioList7.current.play();
                          setRotation3(index);
                        } else {
                          audioList7.current.pause();
                          setRotation3("");
                        }
                        audioList6.current.pause();
                        audioList8.current.pause();
                        audioList9.current.pause();
                        audioList10.current.pause();
                      }
                      if (index === 2) {
                        if (audioList8.current.paused) {
                          audioList8.current.play();
                          setRotation3(index);
                        } else {
                          audioList8.current.pause();
                          setRotation3("");
                        }
                        audioList6.current.pause();
                        audioList7.current.pause();
                        audioList9.current.pause();
                        audioList10.current.pause();
                      }
                      if (index === 3) {
                        if (audioList9.current.paused) {
                          audioList9.current.play();
                          setRotation3(index);
                        } else {
                          audioList9.current.pause();
                          setRotation3("");
                        }
                        audioList6.current.pause();
                        audioList7.current.pause();
                        audioList8.current.pause();
                        audioList10.current.pause();
                      }
                      if (index === 4) {
                        if (audioList10.current.paused) {
                          audioList10.current.play();
                          setRotation3(index);
                        } else {
                          audioList10.current.pause();
                          setRotation3("");
                        }
                        audioList6.current.pause();
                        audioList7.current.pause();
                        audioList8.current.pause();
                        audioList9.current.pause();
                      }
                    }}
                  >
                    <div
                      className={
                        rotation3 === index
                          ? "audio_pic_bottom cur"
                          : "audio_pic_bottom"
                      }
                    >
                      <img
                        src={audio_music_img}
                        className="audio_music blur"
                        alt=""
                      />
                      <img
                        src={active_music_img}
                        className="audio_music active"
                        alt=""
                      />
                    </div>
                  </div>
                );
              })}
              <div>
                <audio
                  onEnded={() => {
                    stopFn(3);
                  }}
                  src={audioSrc1}
                  className="audio_el audioList"
                  ref={audioList6}
                >
                  您的浏览器不支持 audio 标签。
                </audio>
                <audio
                  onEnded={() => {
                    stopFn(3);
                  }}
                  src={audioSrc2}
                  className="audio_el audioList"
                  ref={audioList7}
                >
                  您的浏览器不支持 audio 标签。
                </audio>
                <audio
                  onEnded={() => {
                    stopFn(3);
                  }}
                  src={audioSrc3}
                  className="audio_el audioList"
                  ref={audioList8}
                >
                  您的浏览器不支持 audio 标签。
                </audio>
                <audio
                  onEnded={() => {
                    stopFn(3);
                  }}
                  src={audioSrc4}
                  className="audio_el audioList"
                  ref={audioList9}
                >
                  您的浏览器不支持 audio 标签。
                </audio>
                <audio
                  onEnded={() => {
                    stopFn(3);
                  }}
                  src={audioSrc5}
                  className="audio_el audioList"
                  ref={audioList10}
                >
                  您的浏览器不支持 audio 标签。
                </audio>
              </div>
            </div>
          </div>
          <img
            src={audio_radio_group_right}
            alt=""
            className="audio_radio_right"
          />
        </div>
      </TestimonialsAudioWrapper>

      {/* Testimonals页面下结合antd中的走马灯组件编写而成细节部分还未调整 */}
      <TestimonialsText />

      {/* Testimonals页面下的Referral区域样式布局 */}
      <TestimonialsReferralWrapper {...props} />

      <div className="specialties-container">
        <SpecialtiesBriefingVideo title={t("special.video-title")} />
      </div>
      <GTTodo />
    </TestimonialsStyle>
  );
}

export default withRouter(GT_Testimonials);
const TestimonialsHeaderStyle = styled.div`
  background-color: red;
  position: relative;
  .header-bgc {
    .music_top {
      width: 1242px;
      position: relative;
    }
    .headers-bgc {
      width: 1242px;
    }
    .video-play {
      position: absolute;
      width: 101px;
      height: 101px;
      top: 50%;
      left: 50%;
      margin-left: -50.5px;
      margin-top: -50.5px;
      transform: rotate(360deg);
    }
    .video-play.cur {
      animation: rotation 2s linear infinite;
    }
  }

  @keyframes rotation {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  .content {
    position: absolute;
    top: 20%;
    right: 8%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    .title {
      font-size: 144px;
      line-height: 148px;
      color: #fff;
    }
    .small-title {
      line-height: 144px;
      color: transparent;
      font-size: 120px;
      -webkit-text-stroke: 1px white;
    }
    .white-logo {
      position: absolute;
      bottom: -50%;
      right: 5%;
      img {
        height: 43px;
        width: 43px;
      }
    }
  }
  @media (min-width: 800px) and (max-width: 1400px) {
    .header-bgc {
      .headers-bgc {
        width: calc(100% - 420px);
      }
    }
  }
  @media (max-width: 768px) {
    .header-bgc {
      .music_top {
        width: 100%;
      }
      .headers-bgc {
        width: auto;
        max-height: 176px;
      }
      .video-play {
        width: 46px;
        height: 46px;
        top: 63%;
        left: 42%;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      top: 13%;
      right: 5%;
      .title {
        font-size: 32px;
        line-height: 33px;
      }

      .small-title {
        font-size: 22px;
        line-height: 30px;
      }

      .white-logo {
        margin-top: 20%;
        img {
          width: 10px;
          height: 10px;
        }
      }
    }
  }
`;
const TestimonialsPicWrapper = styled.div`
  padding: 100px 0;
  display: flex;
  justify-content: space-between;
  .content {
    width: 240px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .position_bgc {
      position: relative;
      cursor: pointer;
      height: 160px;
      .pic_bgc {
        border-radius: 10px;
        width: 100%;
        height: 100%;
        &:after {
          content: "";
          border-radius: 10px;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.34);
        }
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          object-fit: cover;
        }
      }
      .pic_icon {
        position: absolute;
        top: 37%;
        left: 38%;
        width: 50px;
        transform: rotate(360deg);
      }
      .cur {
        animation: rotation 2s linear infinite;
      }
      @keyframes rotation {
        from {
          -webkit-transform: rotate(0deg);
        }
        to {
          -webkit-transform: rotate(360deg);
        }
      }
    }

    .title {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      padding: 5% 0;
      text-align: center;
    }
    .small_title {
      font-size: 14px;
      color: #707070;
      line-height: 17px;
      font-weight: 400;
    }
  }
  @media (min-width: 800px) and (max-width: 1400px) {
    .content {
      width: 18%;
    }
  }
  @media (max-width: 768px) {
    flex-wrap: nowrap;
    overflow-x: scroll;
    padding: 10% 0;
    padding-bottom: 6%;
    .content {
      padding-left: 4%;
      .position_bgc {
        .pic_bgc {
          width: 127px;
          height: 100%;
        }
        .pic_icon {
          width: 26px;
          height: auto;
        }
      }

      &:last-child {
        padding-right: 4%;
      }

      .title {
        font-size: 14px;
        // line-height: 7px;
        margin: 5% 0;
      }

      .small_title {
        font-size: 12px;
        // line-height: 5px;
      }
    }
  }
`;

const TestimonialsAudioWrapper = styled.div`
  .container {
    height: 320px;
    position: relative;

    .audio_radio_left {
      position: absolute;
      left: 4%;
      bottom: 7%;
      width: 86px;
      height: 53px;
    }
    .audio_radio_right {
      top: 7%;
      right: 4%;
      position: absolute;
      width: 46px;
      height: 75px;
    }
    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      height: 100%;
      .audio_music {
        width: 200px;
        height: 200px;
      }
    }
  }
  .audio_pic_bottom {
    .active {
      display: none;
    }
    .blur {
      display: block;
    }
  }
  .audio_pic_bottom.cur {
    .active {
      display: block;
    }
    .blur {
      display: none;
    }
  }
  @media (max-width: 768px) {
    margin-top: 20px;
    .container {
      height: 125px;

      .audio_radio_left {
        display: none;
      }

      .audio_radio_right {
        display: none;
      }

      .content {
        overflow-x: scroll;
        .audio_item {
          flex-wrap: nowrap;
          flex-shrink: 0;
          padding-left: 4%;

          &:last-child {
            padding-right: 4%;
          }
          .audio_music {
            width: 91px;
            height: 91px;
          }
        }
      }
    }
  }
`;
