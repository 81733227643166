import React, { useState, useEffect } from "react";
import styled from "styled-components";
import GTTitle from "@/pages/GT_Title";
import YouTube from "react-youtube";
import { useTranslation } from "react-i18next";
import GTModalVideo from "@/pages/GT_ModalVideo";
const video_play = require("../../../assets/img/Testimonials_video_play@2x.png");
const img1 = require("@/assets/img/Poster 33.jpg");
const img2 = require("@/assets/img/Poster 34.jpg");

const HeaderStyle = styled.div`
  // height: 300px;
  // display: flex;
  // align-items: center;

  // @media (max-width: 768px) {
  //   display: none;
  // }
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  padding: 50px 100px 50px 100px;
  align-items: center;
  background-color: #f7f9fc;
  margin-bottom: 20px;
  .content {
    display: flex;
    flex-direction: column;
    margin-right: 100px;
    flex: 1;
    .title-words {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      .title {
        font-size: 76px;
        line-height: 121px;
        color: #fff;
        text-shadow: -1px 1px 0 #f33500, 1px 1px 0 #f33500, 1px -1px 0 #f33500,
          -1px -1px 0 #f33500;
      }
      .how_title {
        font-size: 36px;
        color: #002369;
        line-height: 48px;
      }
    }

    .desc {
      font-size: 16px;
      color: #7d7d7d;
      p {
        padding-top: 2em;
      }
    }

    .ss_bgc {
      display: none;
    }
  }

  .specialties_bgc {
    width: 640px;
    height: calc(640px * 0.5625);
    overflow: hidden;
    position: relative;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      transform: scale(1.4);
    }
    img.video-play {
      width: 100px;
      height: 100px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  @media (min-width: 800px) and (max-width: 1400px) {
    align-items: flex-start;
    .content {
      width: calc(60% - 100px);
      .title-words {
        .title {
          font-size: 66px;
        }
        .how_title {
          font-size: 30px;
        }
      }
      .desc {
        font-size: 14px;
      }
    }
    .specialties_bgc {
      width: 40%;
      height: 464px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      margin-top: 74px;
    }
  }
  @media (max-width: 768px) {
    padding: 0;
    width: 100%;
    align-items: flex-end;
    background-color: #fff;
    border-bottom: 20px #f7f9fc solid;
    .content {
      margin: 0 4%;
      width: 100%;
      justify-content: flex-start;
      height: auto;
      .title-words {
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        padding: 3% 0;
        .title {
          font-size: 30px;
          line-height: 1em;
          padding-right: 30px;
        }

        .how_title {
          font-size: 24px;
          line-height: 22px;
          margin: 0;
          margin: 3% 0;
        }
      }

      .ss_bgc {
        display: block;
        position: relative;
        overflow: hidden;
        img {
          width: 100%;
          height: 220px;
          z-index: -1;
          transform: scale(1.5);
        }
        img.video-play {
          width: 60px;
          height: 60px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
        }
      }

      .flex-desc {
        display: flex;
        flex-direction: column;
        .desc {
          color: #333333;
          font-size: 18px;
          line-height: 30px;
          overflow: hidden;
          padding-bottom: 1.5em;
          p {
            padding-top: 1em;
          }
        }
      }
    }

    .specialties_bgc {
      display: none;
    }
  }

  @media (max-width: 333px) {
    .content {
      .title-words {
        .title {
          font-size: 28px;
        }

        .how_title {
          font-size: 20px;
        }
      }

      .flex-desc {
        .desc {
          font-size: 16px;
          line-height: 25px;
        }
      }
    }
  }
`;

export default function RewardHeader(props) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const lng = window.localStorage.getItem("i18nextLng");
  const [playV, setplayV] = useState();
  const [isPlay, setIsPlay] = useState(true);
  function playVideo() {
    const clientWidth = document.body.clientWidth;
    if (clientWidth <= "768") {
      props.history.push("/video-detail", {
        info: {
          link:
            window.localStorage.getItem("i18nextLng") === "en"
              ? "https://youtu.be/egy40V0Ve80"
              : window.localStorage.getItem("i18nextLng") === "zh-CN"
              ? "https://youtu.be/KD37I7O-Dtc"
              : "https://youtu.be/egy40V0Ve80",
          title: t("testimonials.ref_title"),
        },
      });
    } else {
      setShow(true);
    }
  }
  const opts = {
    width: "100%",
    heigt: "100%",
    playerVars: {
      autoplay: 1,
      rel: 0,
      mute: 1,
      loop: 1,
      playlist: `${
        window.localStorage.getItem("i18nextLng") === "en"
          ? "egy40V0Ve80"
          : window.localStorage.getItem("i18nextLng") === "zh-CN"
          ? "KD37I7O-Dtc"
          : "egy40V0Ve80"
      }`,
    },
  };
  const _onReady = (event) => {
    setplayV(event.target);
  };
  const changeVideo = (e) => {
    if (e.data === 1) {
      setIsPlay(false);
    }
  };
  const pl = (e) => {
    setIsPlay(false);
    playV.playVideo();
  };
  useEffect(() => {
    return () => {
      console.log(playV);
      // playV.pauseVideo();
    };
  }, []);
  return (
    <HeaderStyle className="">
      {/* <GTTitle
        title={t("reward.title")}
        position="left"
        size="70"
        color="#fff"
        showWhiteBgc={true}
      /> */}
      <div className="content">
        <div className="title-words">
          <div className="title fontstyle_SB">
            {" "}
            $1000 {/* t("specialties.title") */}
          </div>
          <div className="how_title fontstyle_SB">
            {/* t("specialties.how_title1") */}- {t("other.Reward")}
          </div>
        </div>
        <div
          className="ss_bgc"
          onClick={(e) => {
            playVideo();
          }}
        >
          <img src={lng == "en" ? img2 : img1} alt="" />
          <img src={video_play} alt="" className="video-play " />
        </div>
        <div className="flex-desc">
          <div className="desc fontstyle_SPR">
            <p>{t("specialties.desc_p1")}</p>
            <p>{t("specialties.desc_p2")}</p>
          </div>
        </div>
      </div>
      <div
        className="specialties_bgc"
        onClick={(e) => {
          pl();
        }}
      >
        {isPlay === true ? (
          <>
            <img src={lng == "en" ? img2 : img1} alt="" />
            <img src={video_play} alt="" className="video-play " />
          </>
        ) : null}

        <YouTube
          videoId={`${
            window.localStorage.getItem("i18nextLng") === "en"
              ? "40V0Ve80"
              : window.localStorage.getItem("i18nextLng") === "zh-CN"
              ? "KD37I7O-Dtc"
              : "egy40V0Ve80"
          }`}
          opts={opts}
          onReady={_onReady}
          onStateChange={changeVideo}
        />
      </div>
      <GTModalVideo
        show={show}
        setShow={setShow}
        id="TestimonialsHeader"
        videourl={{
          link:
            window.localStorage.getItem("i18nextLng") === "en"
              ? "https://youtu.be/egy40V0Ve80"
              : window.localStorage.getItem("i18nextLng") === "zh-CN"
              ? "https://youtu.be/KD37I7O-Dtc"
              : "https://youtu.be/egy40V0Ve80",
          title: t("testimonials.ref_title"),
        }}
      />
    </HeaderStyle>
  );
}
