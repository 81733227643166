
import React, { memo, useState, useEffect } from "react";

import { renderRoutes } from "react-router-config";
import { withRouter } from "react-router";

import routes from "@/routes";
import GTHeader from '@/pages/GT_Header'
import GTFooter from '@/pages/GT_Footer'
import GTMobieHeader from './pages/GT_Mobie_header'
import { useTranslation } from "react-i18next";
import { getDict } from "@/service/video";
import I18n from "@/I18n";
function App(props) {
  const { location } = props;
  const [currentWidth, setCurrentWidth] = useState(0)
  const pathname = location.pathname;
  const { t } = useTranslation();
  useEffect(() => {
    // 进行浏览器刷新的功能
    setCurrentWidth(document.body.clientWidth)

  }, [setCurrentWidth])

  // 获取字典中的法语链接
  useEffect(() => {
    if (!window.FRENCH_LINK) {
      getDict('LINK_CONFIGURATION').then((res) => {
        res?.data?.forEach(item => {
          if (item.code == 'FRENCH_LINK') {
            window.FRENCH_LINK = item.vals;
            console.log(item.vals)
            if (I18n.language == 'fr') {
              // window.location.href = window.FRENCH_LINK ?? "";
              // I18n.changeLanguage('zh-CN');
            }
          }
        });
      })
    }
    if (window.FRENCH_LINK && I18n.language == 'fr') {
      // window.location.href = window.FRENCH_LINK ?? "";
      // I18n.changeLanguage('zh-CN');
    }
  }, [])
  // 根据路由匹配国际化标题 
  const matchRouter = (routes) => {
    for (let i in routes) {
      if (pathname === routes[i].path) {
        if (pathname === "/video-frist") {
          return location.state.info.title
        } else {
          return routes[i].name
        }
      }
    }

  }

  const showPage = (routes) => {
    if (pathname === '/en') {
      return (
        <>
          <GTHeader type="enHide" />
          {renderRoutes(routes)}
        </>
      )
    }
    if (currentWidth <= '768') {
      console.log('小屏')
      if (pathname === '/home' || pathname === '/') {
        return (
          <>
            <GTHeader />
            {renderRoutes(routes)}
            < GTFooter />
          </>
        )
      } else {
        return (
          <>
            <GTMobieHeader title={t(matchRouter(routes))} />
            {renderRoutes(routes)}
            {
              // 定义另外组件
              pathname === '/video-detail' || pathname === "/reward-detail" || pathname === "/video-frist" ? null : <GTFooter />
            }
          </>
        )
      }
    } else {
      console.log('大屏')
      return (
        <>
          <GTHeader />
          {renderRoutes(routes)}
          < GTFooter />
        </>
      )
    }
  }
  return (
    <>
      {showPage(routes)}
    </>
  )
}


export default withRouter(memo(App));