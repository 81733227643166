import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import YouTube from 'react-youtube';

export default function VideoFrame(props) {
    const { videourls } = props;
    const { t } = useTranslation();
    const [link, setLink] = useState()
    useEffect(()=>{
      console.log(123)
      let name=videourls?.link.substring(videourls?.link.lastIndexOf("/")+1);
      setLink(name)
      console.log(props)
    })
    const zjset = {
      width: '100%',
      playerVars: { 
        autoplay: 1,
        rel:0,
        loop:1,
        playlist:link?link:'ZoEcjD8tEuE'
      }
    };
    return (
      <>
        {/* <iframe
            width="100%"
            height="306px"
            src={videourls
                ? videourls.link + "?enablejsapi=1"
                : "https://www.youtube.com/embed/ZoEcjD8tEuE?enablejsapi=1"}
            title={videourls ? videourls.title : t("title")}
            frameBorder="0"
            allow="accelerometer; aDutoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        ></iframe> */}
        {
          videourls?
          <YouTube
            videoId={link}
            opts={zjset}
            // onReady={this._onReady}
          />:
          <YouTube
            videoId={'ZoEcjD8tEuE'}
            opts={zjset}
            // onReady={this._onReady}
          />
        }
      </>
    )
}
