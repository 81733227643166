import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
const left = require("@/assets/img/Title_left@2x.png");
const white_left = require("../../assets/img/Reward_white_left_icon@2x.png");
const right = require("@/assets/img/Title_right@2x.png");
const top = require("@/assets/img/Title_top@2x.png");
const home_video_icon = require("../../assets/img/Home_videoicon@2x.png");

const TitleStyle = styled.div`
  .container {
    position: relative;
    min-height: 110px;
  }
  .mobie-container {
    display: none;
  }
  .title {
    font-family: SossBo;
    padding: 10px 0;
  }

  img {
    width: 490px;
  }

  .content-v2 {
    position: absolute;
    right: 50px;
    .img_top {
      position: absolute;
      right: 20px;
      width: 64px;
      height: 28px;
    }
  }

  .content-v1 {
    .showlogo {
      right: 0;
      width: 64px;
      height: 28px;
    }
  }
  @media (min-width: 800px) and (max-width: 1400px) {
    img {
      width: 35%;
    }
  }
  @media (max-width: 768px) {
    height: 55px;
    .container {
      display: none !important;
    }
    .mobie-container {
      background-color: #f7f9fc;
      color: #06325e;
      display: flex;
      align-items: center;
      padding: 0 4%;
      height: 100%;
      .mobie_img {
        width: 14px;
        height: 13px;
        margin-right: 3%;
      }

      .mobie-title {
        font-size: 19px;
        line-height: 25px;
      }
    }

    .content-v1 {
      padding: 0;
    }
  }

  @media (max-width: 575px) {
    .mobie-container {
      .mobie-title {
        font-size: 17px;
      }
    }
  }

  @media (max-width: 400px) {
    .mobie-container {
      .mobie-title {
        font-size: 15px;
      }
    }
  }
`;

function TitleLeft({
  title,
  size = 48,
  color = "#06325e",
  showlogo = false,
  showImg = "block",
  showWhiteBgc = false,
  paddingSize,
}) {
  const { t } = useTranslation();
  return (
    <div className="content-v1" style={{ paddingTop: `${paddingSize}` }}>
      {showlogo ? <img src={top} className="showlogo" alt="" /> : null}
      <div
        className="title"
        style={{ fontSize: `${size}px`, color: `${color}` }}
      >
        {t(title)}
      </div>
      {showWhiteBgc ? (
        <img src={white_left} alt="" style={{ display: `${showImg}` }} />
      ) : (
        <img src={left} alt="" style={{ display: `${showImg}` }} />
      )}
    </div>
  );
}
function TitleRight({ title, size = 48, color = "#06325e", showlogo = false }) {
  const { t } = useTranslation();
  return (
    <div className="content-v2">
      <img src={top} alt="" className="img_top" />
      <div
        className="title"
        style={{
          fontSize: `${size}px`,
          color: `${color}`,
          textAlign: "right",
          marginTop: "20px",
          marginRight: "20px",
        }}
      >
        {t(title)}
      </div>
      <img src={right} alt="" style={{ position: "absolute", right: 0 }} />
    </div>
  );
}

export default function GT_Title(props) {
  const { position, mobieTitle, show = "block" } = props;
  const { t } = useTranslation();
  return (
    <TitleStyle className="wrap-v1">
      <div className="container" style={{ display: `${show}` }}>
        {position === "left" ? (
          <TitleLeft {...props} />
        ) : (
          <TitleRight {...props} />
        )}
      </div>
      <div className="mobie-container fontstyle_SB">
        <img src={home_video_icon} alt="" className="mobie_img" />
        <div className="mobie-title">{t(mobieTitle)}</div>
      </div>
    </TitleStyle>
  );
}
