/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { memo, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { Drawer } from "antd";
import styled from "styled-components";
import I18n from "@/I18n";
import _ from "lodash";
import { routes_media_info } from "@/mocks/leter_data";
const logo = require("@/assets/img/GTHeader_logo@2x.png");
const back = require("../../assets/img/Video_detail_back@2x.png");
const header_tabbar_icon = require("../../assets/img/Header_tabber_icon@2x.png");
const header_icon_clone = require("../../assets/img/header_icon_clone@2x.png");

export const HeaderStyle = styled.div`
  color: #000000;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 12px solid #f5f7fb;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #fff;
  .header {
    height: 66px;
    display: flex;
    padding: 0 4%;
    justify-content: space-between;
    align-items: center;
  }
`;

export const HeaderLeft = styled.div`
  .back {
    height: 24px;
    width: 24px;
    line-height: 24px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .logo {
    height: 50.94px;
    line-height: 50.94px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    .back {
      display: block !important;
      margin: 10px;
      height: 24px;
      width: 24px;
    }
    .logo {
      width: 146.2px;
      height: 41.21px;
      line-height: 41.21px;
    }
  }
`;

export const HeaderCenter = styled.div`
  margin: 0 3%;
  margin-right: 0;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  font-family: SossBo;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
`;

export const HeaderRight = styled.div`
  width: 45px;
  height: 45px;
  justify-content: center;
  align-items: center;
  display: flex;

  .select-item {
    .link {
      margin: 0 20px;
      color: #000000;
    }
  }

  .active {
    padding: 0 5px 10px 5px;
    border-bottom: 3px #f33500 solid;
    font-weight: bold;
  }
  .header_tabber_icon {
    display: block;
    width: 22px;
    height: 18px;
    margin-right: 3%;
  }

  .header_icon_clone {
    display: block;
    width: 20px;
    height: 19px;
  }

  @media (max-width: 768px) {
    /* 定义侧边栏 */
    order: 3;
    .select-item {
      display: none;
    }

    .header_tabber_icon {
      display: block;
      width: 24px;
      height: 24px;
      margin-right: 3%;
    }

    .header_icon_clone {
      display: block;
      width: 24px;
      height: 24px;
    }
  }
`;

function GT_Mobie_Header({ isShow = "visible", history, title }) {
  const [visible, setVisible] = useState(false);
  const [active, setActive] = useState(1);
  const { t } = useTranslation();
  const ElRef = useRef();
  const centerRef = useRef();
  const headerMobieRef = useRef();

  const onClose = () => {
    setVisible(false);
    centerRef.current.hidden = false;
    ElRef.current.src = header_tabbar_icon;
    ElRef.current.className = "header_icon_clone";
    // headerMobieRef.current.children[3].style = { display: "none" };
    if (headerMobieRef && headerMobieRef.current) {
      headerMobieRef.current.children[3].hidden = true;
    }
  };
  const getIndex = () => {
    return _.findIndex(languageArr, (o) => o.language === I18n.language);
  };
  useEffect(() => {
    setActive(getIndex);
    headerMobieRef.current.children[3].hidden = true;
  }, []);

  const showDrawer = () => {
    if (!visible) {
      setVisible(true);
      centerRef.current.hidden = true;
      // 关闭状态
      ElRef.current.src = header_icon_clone;
      ElRef.current.className = "header_tabber_icon";
      headerMobieRef.current.children[3].hidden = false;
    } else {
      setVisible(false);
      centerRef.current.hidden = false;
      ElRef.current.src = header_tabbar_icon;
      ElRef.current.className = "header_icon_clone";
      headerMobieRef.current.children[3].hidden = true;
    }
  };
  const onMObieClick = (key) => {
    if (key === "en") {
      localStorage.setItem("language", "en");
      I18n.changeLanguage(key);
      window.location.href = "/en";
    } else if (key === "fr" && window.FRENCH_LINK) {
      window.location.href = window.FRENCH_LINK ?? "";
    } else if (key === "zh-TW") {
      I18n.changeLanguage(key);
      localStorage.setItem("language", "zh-TW");
    } else {
      localStorage.setItem("language", "zh-CN");
      I18n.changeLanguage(key);
    }
  };

  const languageArr = [
    {
      type: "简体",
      language: "zh-CN",
    },
    {
      type: "繁体",
      language: "zh-TW",
    },
    {
      type: "English",
      language: "en",
    },
    {
      type: "Français",
      language: "fr",
    },
  ];
  const aStyle = {
    display: "block",
    color: "#333333",
    width: "100%",
    height: "100%",
  };

  return (
    <HeaderStyle>
      <div className="wrap-v1 header" ref={headerMobieRef}>
        <HeaderLeft>
          {!visible ? (
            <div
              className="back"
              onClick={() => {
                history.goBack(-1);
              }}
            >
              <img src={back} alt="" />
            </div>
          ) : (
            <div className="logo">
              <img src={logo} alt="" />
            </div>
          )}
        </HeaderLeft>

        <HeaderCenter ref={centerRef}>{title}</HeaderCenter>
        <HeaderRight style={{ visibility: `${isShow}` }}>
          <img
            src={header_tabbar_icon}
            alt=""
            className="header_tabber_icon"
            ref={ElRef}
            onClick={(e) => {
              showDrawer();
            }}
          />
        </HeaderRight>

        <Drawer
          placement={"left"}
          closable={false}
          onClose={onClose}
          visible={visible}
          maskClosable={true}
          className="drawerclass"
          getContainer={false}
          style={{ display: "block" }}
        >
          {routes_media_info.map((item) => {
            return (
              <div key={item.title} className="mobie-select-item">
                <a
                  className="link"
                  onClick={() => {
                    history.push({
                      pathname: item.link,
                    });
                    setVisible(false);
                    showDrawer();
                  }}
                >
                  {t(item.title)}
                </a>
              </div>
            );
          })}
          <div className="change-language">
            <div className="title">{t("routes-media.choose_a_language")}</div>
            <div className="button-group">
              {languageArr.map((item, index) => {
                return (
                  <button
                    key={index}
                    className={
                      active === index ? "gt_header_index_lang_active" : ""
                    }
                    onClick={(e) => {
                      onMObieClick(item.language);
                    }}
                  >
                    {item.language === "fr" ? (
                      <p style={aStyle}>{item.type}</p>
                    ) : (
                      <a href="" target="_self" style={aStyle}>
                        {item.type}
                      </a>
                    )}
                  </button>
                );
              })}
            </div>
          </div>
        </Drawer>
      </div>
    </HeaderStyle>
  );
}

export default withRouter(memo(GT_Mobie_Header));
