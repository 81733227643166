import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs'
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import resources from "./resource"


require('./dayjs-en');
require('./dayjs-zh-cn');
dayjs.extend(LocalizedFormat);

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // fallbackLng: 'en',
        lng: localStorage.getItem("language"),
        debug: true,
        resources: resources,
        interpolation: {
            escapeValue: false,
        },
		react: {			useSuspense: false		}
    });


export default i18n;