import React, { useState, useRef } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import GTModalVideo from "@/pages/GT_ModalVideo";
import YouTube from "react-youtube";

const VisionMainWrapper = styled.div`
  .gt_mobie_flex {
    padding-top: 20px;
    display: flex;
    align-items: center;
  }

  .sign-icon {
    height: 33px;
    width: 33px;
    margin-left: 5%;
  }

  .title {
    font-family: SossBo;
    font-size: 24px;
    line-height: 30px;
    color: #002369;
  }
  .desc {
    color: #333333;
    font-size: 16px;
    line-height: 28px;
    margin: 0;
    margin-bottom: 6%;
  }
  .video {
    position: relative;
  }
  .video-there {
    width: 100%;
    height: 300px;
    margin-top: 10px;
    .poster {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      .posterImg {
        width: 100%;
        height: 100%;
      }
      .posterIcon {
        width: 50px;
        height: 50px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .poster.cur {
      display: none;
    }
    > div {
      width: 100%;
      height: 100%;
      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
  .bgc {
    height: 204px;
    width: 100%;
    margin: 5% 0;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .play-icon {
    width: 48px;
    height: 48px;
    position: absolute;
    left: calc(50% - 32px);
    top: calc(50% - 32px);
    img {
      width: 100%;
      height: 100%;
    }
  }
  .play-icon.cur {
    img {
      animation: rotation 2s linear infinite;
    }
  }
  .footer-desc {
    font-size: 16px;
    line-height: 28px;
    padding-bottom: 6%;
  }
  @keyframes rotation {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @media (max-width: 321px) {
    .gt_mobie_flex {
      padding-top: 12px;
    }
    .title {
      font-size: 18px;
    }

    .desc {
      font-size: 15px;
      line-height: 1.8em;
    }
  }
`;

const RegionOneWrapper = styled.div`
  padding: 0 4%;
  border-bottom: 20px #f7f9fc solid;
  @media (max-width: 321px) {
    padding: 0 3%;
  }
`;

const RegionTwoWrapper = styled.div`
  padding: 0 4%;
  border-bottom: 20px #f7f9fc solid;
  @media (max-width: 321px) {
    padding: 0 3%;
  }
`;

const RegionThereWrapper = styled.div`
  padding: 0 4%;
  border-bottom: 20px #f7f9fc solid;
  @media (max-width: 321px) {
    padding: 0 3%;
  }
`;
const video_one = require("@/assets/img/Vision_video1@2x.jpg");
const video_two = require("@/assets/img/rcjjpic.jpg");
const video_there = require("@/assets/img/Vision_video3@2x.jpg");
const play = require("../../../assets/img/Vision_play@2x.png");
const sign1 = require("@/assets/img/Vision_sign1@2x.png");
const sign2 = require("@/assets/img/Vision_sign2@2x.png");
const sign3 = require("@/assets/img/Vision_sign3@2x.png");
const poster = require("../../../assets/img/8fm.jpg");
const audioSrc1 = require("../../../assets/img/Recording 4_Ada.mp3");
const audioSrc2 = require("../../../assets/img/Recording 3_Ted Ip.mp3");

export default function VisionDescWrapper(props) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [rotation, setRotation] = useState("");
  const [playV, setplayV] = useState();
  const [videoState, setVideoState] = useState(false);
  const audioC = useRef();
  const audioD = useRef();
  function playVideo() {
    const clientWidth = document.body.clientWidth;
    if (clientWidth <= "768") {
      props.history.push("/video-detail", {});
    } else {
      setShow(true);
    }
  }
  const _onReady = (event) => {
    setplayV(event.target);
  };
  const pl = (e) => {
    setVideoState(true);
    playV.playVideo();
  };
  const onPlayerStateChange = (e) => {
    if (e.data === 1) {
      setVideoState(true);
    }
  };
  const opts = {
    width: "100%",
    playerVars: {
      autoplay: 0,
      rel: 0,
      mute: 1,
      loop: 1,
      playlist: "XYiRFVAnnNo",
    },
  };
  const stopFn = (e) => {
    setRotation("");
  };
  return (
    <>
      <VisionMainWrapper className="wrap-v1">
        <RegionOneWrapper>
          <div className="content">
            <div className="gt_mobie_flex">
              <div className="title">
                {t("vision.header.contentOne.mobie-title1")}
                <br />
                {t("vision.header.contentOne.mobie-title2")}
              </div>
              <img src={sign1} alt="" className="sign-icon" />
            </div>
            <div
              className="video-one video"
              // onClick={(e) => {
              //   audioD.current.pause();
              //   if(audioC.current.paused){
              //     audioC.current.play();
              //     setRotation(1)
              //   }else{
              //     audioC.current.pause();
              //     setRotation('')
              //   }
              // }}
            >
              <div className="one-bgc bgc">
                <img src={video_one} alt="" />
              </div>
              {/* <div className={rotation === 1?'play-icon play cur':'play-icon play'} id="vis_icon_1">
                <img src={play} alt="" />
              </div> */}
            </div>
            <audio
              onEnded={() => {
                stopFn(1);
              }}
              src={audioSrc1}
              ref={audioC}
            >
              您的浏览器不支持 audio 标签。
            </audio>
            <div className=" desc">{t("vision.header.contentOne.desc")}</div>
          </div>
        </RegionOneWrapper>

        <RegionTwoWrapper>
          <div className="content">
            <div className="gt_mobie_flex">
              <div className="title">
                <div className="headline-title title">
                  {" "}
                  {t("vision.header.contentTwo.title")}
                </div>
              </div>
              <img src={sign2} alt="" className="sign-icon " />
            </div>
            <div
              className="video-two video cursor"
              // onClick={(e) => {
              //   audioC.current.pause();
              //   if(audioD.current.paused){
              //     audioD.current.play();
              //     setRotation(2)
              //   }else{
              //     audioD.current.pause();
              //     setRotation('')
              //   }
              // }}
            >
              <div className="two-bgc bgc">
                <img src={video_two} alt="" />
              </div>
              {/* <div className={rotation === 2?'play-icon play cur':'play-icon play'} id="vis_icon_2">
                <img src={play} alt="" />
              </div> */}
            </div>
            <div className=" desc">{t("vision.header.contentTwo.desc")}</div>
            <audio
              onEnded={() => {
                stopFn(2);
              }}
              src={audioSrc2}
              ref={audioD}
            >
              您的浏览器不支持 audio 标签。
            </audio>
          </div>
        </RegionTwoWrapper>

        <RegionThereWrapper>
          <div className="container">
            <div className="content">
              <div className="gt_mobie_flex">
                <div className="title">
                  <div className="headline-title title">
                    {" "}
                    {t("vision.header.contentThere.mobie-title1")}{" "}
                  </div>
                </div>
                <img src={sign3} alt="" className="sign-icon" />
              </div>
              <div
                className="video-there video"
                // onClick={(e) => {
                //   playVideo();
                // }}
              >
                <YouTube
                  videoId={"XYiRFVAnnNo"}
                  opts={opts}
                  onReady={_onReady}
                  onStateChange={onPlayerStateChange}
                />

                {/* <div className="bgc">
                  <img src={video_there} alt="" />
                </div>
                <div className="play-icon">
                  <img src={play} alt="" />
                </div> */}
              </div>
              <div className="desc ">
                {t("vision.header.contentThere.desc1")}
              </div>
            </div>
          </div>
          <div className="footer-desc">
            {t("vision.header.contentThere.more")}
          </div>
        </RegionThereWrapper>
        <GTModalVideo show={show} setShow={setShow} />
      </VisionMainWrapper>
    </>
  );
}
