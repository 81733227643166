import React, { memo } from "react";
import styled from "styled-components";
import VisionDesc from "./VisionDesc";
import VisionMobieDesc from "./VisionDesc/mobie";
import { withRouter } from "react-router";
import SpecialtiesBriefing from "../../pages/GT_Specialtiesvideo/";
import GTTodo from "../../pages/GT_Todo";
import { useTranslation } from "react-i18next";
const VisionStyle = styled.div`
  .gt_desc {
    display: block;
  }

  .gt_mobie_desc {
    display: none;
  }

  @media (max-width: 768px) {
    .gt_desc {
      display: none;
    }

    .gt_mobie_desc {
      display: block;
    }
  }
`;

function GT_VisionCareer(props) {
  const { t } = useTranslation();
  return (
    <VisionStyle className="fix-margin-top">
      <div className="gt_desc">
        <VisionDesc {...props} />
      </div>
      <div className="gt_mobie_desc">
        <VisionMobieDesc {...props} />
      </div>

      <div className="specialties-container">
        <SpecialtiesBriefing title={t("special.video-title")} />
      </div>
      <GTTodo />
    </VisionStyle>
  );
}

export default withRouter(memo(GT_VisionCareer));
