import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import GTModalVideo from "@/pages/GT_ModalVideo";
import YouTube from "react-youtube";

const VisionMainWrapper = styled.div`
  padding-top: 20px;
  .icon {
    width: 93px;
    height: 93px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .title {
    font-family: SossBo;
    font-size: 50px;
    line-height: 1.25em;
    color: #002369;
  }

  .desc {
    padding-top: 2.5em;
    font-size: 17px;
    line-height: 30px;
    color: #7d7d7d;
  }
  .play {
    width: 101px;
    height: 101px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .video {
    width: 58%;
    height: 481px;
    position: relative;

    .hover {
      display: none;
    }
    &:hover {
      background-color: #f33500;
      position: relative;

      .hover {
        position: absolute;
        top: 50%;
        left: 40%;
        min-width: 80%;
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        transform: translate(-40%, -40%);
        .hover-icon {
          height: 29px;

          img {
            width: 100%;
            height: 100%;
          }
        }
        .title {
          margin-top: 1%;
          font-size: 40px;
          line-height: 68px;
          color: #fff;
          font-family: SossSB;
        }
      }

      .play {
        display: none;
      }

      .bgc {
        opacity: 0.15;
      }
    }
    .one-bgc {
      height: 100%;
      img {
        height: 100%;
      }
    }

    .play-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-40%, -50%);
    }
    .play-icon.cur {
      img {
        animation: rotation 2s linear infinite;
      }
    }
  }
  @keyframes rotation {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
    padding-bottom: 0px;
    .bgc {
      margin: 0 4%;
    }
    .icon {
      width: 32px;
      height: 32px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .title {
      font-family: SossBo;
      font-size: 24px;
      line-height: 30 px;
      color: #002369;
    }

    .desc {
      padding: 3% 0;
      font-size: 18px;
      line-height: 30px;
      color: #333333;
    }

    .play {
      width: 41px;
      height: 41px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  @media (min-width: 800px) and (max-width: 1400px) {
    .video {
      width: 55%;
      height: 400px;
      .one-bgc {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
      }
    }
  }
  @media (max-width: 333px) {
    .title {
      font-size: 20px;
    }

    .desc {
      font-size: 15px;
      line-height: 25px;
    }
  }
`;

const RegionOneWrapper = styled.div`
  .container {
    display: flex;
    justify-content: space-between;

    .content {
      position: relative;
      margin: 0 2% 0 1%;
      .sign-icon {
        position: absolute;
        top: 0;
        right: 12%;
      }

      .main-title {
        display: block;
        .headline-title {
          padding-top: 10%;
        }
        .small-title {
          font-size: 48px;
          line-height: 60px;
          color: #fff;
          text-shadow: -1px 1px 0 #f33500, 1px 1px 0 #f33500, 1px -1px 0 #f33500,
            -1px -1px 0 #f33500;
        }
      }

      .media-title {
        display: none;
      }

      .one-desc {
      }
    }
  }
  @media (min-width: 800px) and (max-width: 1400px) {
    .container {
      .content {
        width: 42%;
        .main-title {
          .headline-title {
            font-size: 36px;
          }
          .small-title {
            font-size: 34px;
          }
        }
        .desc {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    .container {
      width: 100%;
      flex-direction: column;

      .content {
        margin: 0;
        padding: 0 3%;
        .sign-icon {
          position: absolute;
          /* top: 15px; */
          top: 0;
          left: 70%;
          right: 0;
        }

        .main-title {
          display: none;
        }

        .media-title {
          display: block;
          font-size: 24px;
          line-height: 30px;
          font-family: SossBo;
          color: #002369;
          .headline-title {
            padding: 0;
          }
          .small-title {
            font-size: 16px;
            line-height: 18px;
            color: #fff;
            text-shadow: -1px 1px 0 #f33500, 1px 1px 0 #f33500,
              1px -1px 0 #f33500, -1px -1px 0 #f33500;
          }
        }

        .one-desc {
        }
      }

      .video-one {
        width: 100%;
        height: 218px;
        .one-bgc {
          img {
            width: 100%;
            height: 100%;
          }
        }

        .play-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-40%, -50%);
        }
      }
    }
  }
  @media (max-width: 333px) {
    .container {
      .content {
        .sign-icon {
          top: 10px;
          left: 80%;
        }
        .media-title {
          font-size: 20px;
        }
      }
    }
  }
`;

const RegionTwoWrapper = styled.div`
  .container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    .content {
      position: relative;
      margin: 1% 5%;
      margin-right: 0;
      .sign-icon {
        position: absolute;
        right: 20%;
      }

      .main-title {
        display: block;
        .headline-title {
          margin-top: 6%;
        }
        .small-title {
          font-size: 48px;
          line-height: 100px;
          color: #fff;
          text-shadow: -1px 1px 0 #f33500, 1px 1px 0 #f33500, 1px -1px 0 #f33500,
            -1px -1px 0 #f33500;
        }
      }

      .media-title {
        display: none;
      }
    }
    .video-two {
      height: 490px;
      position: relative;
      /* margin-left: 6.5%; */
      .two-bgc {
        width: 580px;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .play-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-40%, -50%);
      }
    }
  }
  @media (min-width: 800px) and (max-width: 1400px) {
    .container {
      .content {
        width: 50%;
        margin: 1% 0;
        .main-title {
          .headline-title {
            font-size: 36px;
          }
          .small-title {
            font-size: 34px;
          }
        }
        .desc {
          font-size: 14px;
          line-height: 24px;
        }
      }
      .video-two {
        width: 45%;
        height: 400px;
        .two-bgc {
          width: 100%;
          img {
            width: 100%;
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    .container {
      width: 100%;
      flex-direction: column;
      margin-top: 5%;
      .content {
        margin: 0;
        padding: 0 3%;
        .sign-icon {
          position: absolute;
          top: 0%;
          left: 50%;
          right: 0;
        }

        .main-title {
          display: none;
        }

        .media-title {
          display: block;
          .headline-title {
            padding-top: 2%;
          }
          .small-title {
            font-size: 16px;
            line-height: 18px;
            color: #fff;
            text-shadow: -1px 1px 0 #f33500, 1px 1px 0 #f33500,
              1px -1px 0 #f33500, -1px -1px 0 #f33500;
          }
        }
        .there-desc {
        }
      }

      .video-two {
        width: 100%;
        height: 204px;
        margin: 0;

        .two-bgc {
          img {
            width: 100%;
            height: 100%;
          }
        }

        .play-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-40%, -50%);
        }

        .hover {
          display: none;
        }
      }
    }
  }
`;
const RegionThereWrapper = styled.div`
  .container {
    display: flex;
    justify-content: space-between;
    .content {
      width: 50%;
      position: relative;
      margin: 0 3% 0 1%;
      .sign-icon {
        position: absolute;
        top: 6%;
        right: 0;
      }
      .main-title {
        display: block;
        .headline-title {
          font-size: 41px;

          margin-top: 10%;
        }
        .small-title {
          font-size: 48px;
          line-height: 100px;
          color: #fff;
          text-shadow: -1px 1px 0 #f33500, 1px 1px 0 #f33500, 1px -1px 0 #f33500,
            -1px -1px 0 #f33500;
        }
      }

      .media-title {
        display: none;
      }
      .there-desc {
      }
    }
    .video-there {
      width: 50%;
      height: 436px;
      position: relative;
      .poster {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        .posterImg {
          width: 100%;
          height: 100%;
        }
        .posterIcon {
          width: 100px;
          height: 100px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .poster.cur {
        display: none;
      }
      &:hover {
        background-color: #fff;
        position: relative;
      }
      > div {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        > iframe {
          width: 100%;
          height: 100%;
        }
      }
      .there-bgc {
        height: 100%;
        img {
          height: 100%;
        }
      }

      .play-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-40%, -50%);
      }
    }
  }
  .footer-desc {
    font-size: 17px;
    line-height: 30px;
    color: #7d7d7d;
    padding: 5% 1%;
  }
  @media (min-width: 800px) and (max-width: 1400px) {
    .container {
      .content {
        width: 42%;
        margin: 1% 0;
        .main-title {
          .headline-title {
            font-size: 36px;
          }
          .small-title {
            font-size: 34px;
          }
        }
        .desc {
          font-size: 14px;
          line-height: 24px;
        }
      }
      .video-there {
        width: 55%;
        height: 400px;
        .there-bgc {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          img {
            width: 100%;
          }
        }
      }
    }
    .footer-desc {
      font-size: 14px;
      line-height: 24px;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    .container {
      width: 100%;
      flex-direction: column;
      margin-top: 5%;
      .content {
        margin: 0;
        padding: 0 3%;
        .sign-icon {
          position: absolute;
          top: 0%;
          left: 65%;
          right: 0;
        }

        .main-title {
          display: none;
        }

        .media-title {
          display: block;
          .headline-title {
            padding-top: 2%;
          }
          .small-title {
            font-size: 16px;
            line-height: 18px;
            color: #fff;
            text-shadow: -1px 1px 0 #f33500, 1px 1px 0 #f33500,
              1px -1px 0 #f33500, -1px -1px 0 #f33500;
          }
        }
        .there-desc {
        }
      }
      .video {
        height: auto;
      }

      .video-there {
        width: 100%;
        min-height: 204px;
        .there-bgc {
          img {
            width: 100%;
            height: 100%;
          }
        }

        .play-icon {
          position: absolute;
          top: 50%;
          left: 50%;
        }
      }
    }
    .footer-desc {
      font-size: 18px;
      line-height: 30px;
      color: #333333;
      padding: 3%;
    }
  }
`;
const video_one = require("@/assets/img/Vision_video1@2x.jpg");
const video_two = require("@/assets/img/Vision_video2@2x.jpg");
const video_there = require("@/assets/img/rcjjpic.jpg");
const play = require("../../../assets/img/Vision_play@2x.png");
const sign1 = require("@/assets/img/Vision_sign1@2x.png");
const sign2 = require("@/assets/img/Vision_sign2@2x.png");
const sign3 = require("@/assets/img/Vision_sign3@2x.png");
const hover = require("../../../assets/img/Vision_hover_icon@2x.png");
const poster = require("../../../assets/img/8fm.jpg");

const audioSrc1 = require("../../../assets/img/Recording 4_Ada.mp3");
const audioSrc2 = require("../../../assets/img/Recording 3_Ted Ip.mp3");
export default function VisionDescWrapper(props) {
  // const { info } = props;
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const audioDiv1 = document.getElementById("audioDiv1");
  const audioDiv2 = document.getElementById("audioDiv2");
  const [rotation, setRotation] = useState("");
  const [playV, setplayV] = useState();
  const [videoState, setVideoState] = useState(false);
  const audioA = useRef();
  const audioB = useRef();
  // function playVideo() {
  //   const clientWidth = document.body.clientWidth;
  //   if (clientWidth <= "768") {
  //     props.history.push("/video-detail", {});
  //   } else {
  //     setShow(true);
  //   }
  // }
  const set = {
    width: "100%",
    playerVars: {
      autoplay: 1,
      rel: 0,
      mute: 1,
      loop: 1,
      playlist: "XYiRFVAnnNo",
    },
  };
  const _onReady = (event) => {
    setplayV(event.target);
  };
  const pl = (e) => {
    setVideoState(true);
    playV.playVideo();
  };
  const onPlayerStateChange = (e) => {
    if (e.data === 1) {
      setVideoState(true);
    }
  };
  const stopFn = (e) => {
    setRotation("");
  };
  return (
    <>
      <VisionMainWrapper className="wrap-v1">
        <RegionOneWrapper>
          <div className="container">
            <div className="content">
              <div className="sign-icon icon">
                <img src={sign1} alt="" />
              </div>
              <div className="main-title">
                <div className="headline-title title">
                  {t("vision.header.contentOne.title1")}
                  <br />
                  {t("vision.header.contentOne.title2")}
                </div>
                <div className="small-title">
                  {t("vision.header.contentOne.small-title")}
                </div>
              </div>
              <div className="media-title">
                {t("vision.header.contentOne.mobie-title1")}
                <br />
                {t("vision.header.contentOne.mobie-title2")}
              </div>
              <div className="one-desc desc">
                {t("vision.header.contentOne.desc")}
              </div>
            </div>
            <div
              className="video-one video"
              // onClick={(e) => {
              //   audioB.current.pause();
              //   if(audioA.current.paused){
              //     audioA.current.play();
              //     setRotation(1)
              //   }else{
              //     audioA.current.pause();
              //     setRotation('')
              //   }
              // }}
            >
              <div className="one-bgc bgc">
                <img src={video_one} alt="" />
              </div>
              {/* <div className={rotation === 1?"play-icon play cur":"play-icon play"}>
                <img src={play} alt="" />
              </div> */}
              <div className="hover">
                <div className="hover-icon">
                  <img src={hover} alt="" />
                </div>
                <div className="title">
                  {t("vision.header.contentOne.title1")}
                  {t("vision.header.contentOne.title2")}
                </div>
              </div>
              <audio
                onEnded={() => {
                  stopFn(1);
                }}
                src={audioSrc1}
                id="audioDiv1"
                ref={audioA}
              >
                您的浏览器不支持 audio 标签。
              </audio>
            </div>
          </div>
        </RegionOneWrapper>

        <RegionTwoWrapper>
          <div className="container">
            <div className="content">
              <div className="sign-icon icon">
                <img src={sign2} alt="" />
              </div>
              <div className="main-title">
                <div className="headline-title title">
                  {t("vision.header.contentTwo.title")}
                </div>
              </div>
              <div className="media-title">
                <div className="headline-title title">
                  {" "}
                  {t("vision.header.contentTwo.title")}
                </div>
              </div>
              <div className="two-desc desc">
                {t("vision.header.contentTwo.desc")}
              </div>
            </div>
            <div
              className="video-two video"
              // onClick={(e) => {
              //   audioA.current.pause();
              //   if(audioB.current.paused){
              //     audioB.current.play();
              //     setRotation(2)
              //   }else{
              //     audioB.current.pause();
              //     setRotation('')
              //   }
              // }}
            >
              <div className="two-bgc bgc">
                <img src={video_there} alt="" />
              </div>
              {/* <div className={rotation === 2?"play-icon play cur":"play-icon play"}>
                <img src={play} alt="" />
              </div> */}
              <div className="hover">
                <div className="hover-icon">
                  <img src={hover} alt="" />
                </div>
                <div className="title">
                  {t("vision.header.contentTwo.title")}
                </div>
              </div>
              <audio
                onEnded={() => {
                  stopFn(2);
                }}
                src={audioSrc2}
                id="audioDiv2"
                ref={audioB}
              >
                您的浏览器不支持 audio 标签。
              </audio>
            </div>
          </div>
        </RegionTwoWrapper>

        <RegionThereWrapper>
          <div className="container">
            <div className="content">
              <div className="sign-icon icon">
                <img src={sign3} alt="" />
              </div>
              <div className="main-title">
                <div className="headline-title title">
                  {t("vision.header.contentThere.title1")}
                  <br />
                  {t("vision.header.contentThere.title2")}
                </div>
              </div>
              <div className="media-title">
                <div className="headline-title title">
                  {" "}
                  {t("vision.header.contentThere.mobie-title1")}{" "}
                </div>
              </div>
              <div className="desc there-desc">
                <p>{t("vision.header.contentThere.desc1")}</p>
              </div>
            </div>
            <div
              className="video-there video cursor"
              onClick={(e) => {
                // playVideo();
              }}
            >
              <YouTube
                videoId={"XYiRFVAnnNo"}
                opts={set}
                onStateChange={onPlayerStateChange}
                onReady={_onReady}
                id="vis"
              />

              {/* <div className="there-bgc bgc">
                <img src={video_two} alt="" />
              </div>
              <div className="play-icon play">
                <img src={play} alt="" />
              </div>
              <div className="hover">
                <div className="hover-icon">
                  <img src={hover} alt="" />
                </div>
                <div className="title">
                  {t("vision.header.contentThere.title1")}
                  <br />
                  {t("vision.header.contentThere.title2")}
                </div>
              </div> */}
            </div>
          </div>
          <div className="footer-desc">
            {t("vision.header.contentThere.more")}
          </div>
        </RegionThereWrapper>
        <GTModalVideo show={show} setShow={setShow} />
      </VisionMainWrapper>
    </>
  );
}
