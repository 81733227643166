import { Redirect } from "react-router-dom";

import Home from '@/components/Home'
import Testimonials from '@/components/Testimonials'
import Specialties from '@/components/Specialties'
import Vision_Career from '@/components/Vision_Career'
import Lectures from '@/components/Lectures'
import EnPage from '@/components/EnglishPage'

// 新闻主页
import Reward_Promotion from '@/components/Reward_Promotion'


// 新闻详情
import Reward_PromotionDetail from '../components/Reward_Promotion/d-RewardDetail'

import Contect_FAQ from '@/components/Contect_FAQ'

import GTVideoDetail from '../pages/GTVideoDetail'
import GTVideoFrist from '../pages/GTVideoFirst'
import GTFaq from '../pages/GT_FAQ/contact-faq'

const routes = [
    {
        path: "/",
        exact: true,
        component: Home,
        name: ''
    },
    {
        path: "/home",
        component: Home,
        name: 'routes-media.Home'
    },
    {
        path: "/en",
        component: EnPage,
        name: 'routes-media.English'
    },
    {
        path: "/testimonials",
        component: Testimonials,
        name: 'routes-media.Testimonials'
    },
    {
        path: "/specialties",
        component: Specialties,
        name: 'routes-media.Specialties'
    },
    {
        path: "/vision-career",
        component: Vision_Career,
        name: 'routes-media.Vision_Career'
    },
    {
        path: "/lectures",
        component: Lectures,
        name: 'routes-media.Lectures'
    },
    {
        path: '/contect-faq',
        component: Contect_FAQ,
        name: 'routes-media.Contect'
    },
    {
        path: '/faq',
        component: GTFaq,
        name: 'routes-media.Faq',
    },
    {
        path: "/reward-promotion",
        component: Reward_Promotion,
        name: 'routes-media.Reward_Promotion'
    },
    {
        path: "/reward-detail",
        component: Reward_PromotionDetail,
        name: 'routes-media.Detail'
    },
    // 移动端视频播放路由
    {
        path: '/video-detail',
        component: GTVideoDetail,
        name: 'routes-media.Video_Detail'
    },
    {
        path: '/video-frist',
        component: GTVideoFrist,
        name: "routes-media.frist"
    }

];

export default routes;
