import React, { useState } from "react";
import styled from "styled-components";
import GTModalVideo from "@/pages/GT_ModalVideo";
import { useTranslation } from "react-i18next";
const play = require("../../../assets/img/play_icon@2x.png");
const bgc1 = require("@/assets/img/Poster 33.jpg");
const bgc2 = require("@/assets/img/Poster 34.jpg");
const top_icon = require("@/assets/img/Testimonials_referrals_icon@2x.png");
const TestimonialsReferralWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #182848;
  height: 733px;
  position: relative;
  .box_bg{
	  width:100%;
	  height:100%;
	  position: absolute;
	  left:0;
	  top:0;
	  background:rgba(0,0,0,0.3);
	  z-index:1;
  }
  .content {
    color: #fff;
    .position {
      position: absolute;
      top: 20%;
      left: 7%;
      z-index: 10;
      .title {
        color: rgba(24, 40, 72, 0.2);
        font-size: 80px;
        line-height: 90px;
        -webkit-text-stroke: 1px #fff;
      }
      .desc {
        width: 60%;
        margin-top: 50px;
        opacity: 0.6;
        line-height: 40px;
        font-size: 18px;
      }
    }
    .top_icon {
      position: absolute;
      top: 5%;
      left: 2%;
      width: 236px;
      height: 121px;
	  z-index:2;
    }
  }
  .bgc {
    height: 100%;
    position: relative;
    .bgc_img {
      z-index: 100;
      height: 100%;
      img {
        height: 100%;
      }
    }
    .play_icon {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(-20%);
      z-index: 100;
      img {
        height: 101px;
        width: 101px;
      }
    }
    .honr_line {
      height: 227px;
      width: 12px;
      background-color: rgba(255, 255, 255, 0.6);
      position: absolute;
      right: 5%;
      top: 30%;
    }
    .bottom_line {
      width: 483px;
      height: 12px;
      background-color: rgba(255, 255, 255, 0.6);
      position: absolute;
      bottom: 8%;
      left: -15%;
    }
  }
	@media (min-width:800px) and (max-width: 1400px) {
		.bgc{
			width:70%;
		}
	}
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    .content {
      width:100%;
      position: relative;
      padding-bottom: 20px;
      .position {
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
        .title {
          width:100%
          position: relative;
          color: white;
          padding-top: 55px;
          padding-left: 8%;
          font-size: 22px;
          line-height: 26px;
          -webkit-text-stroke: inherit;
        }

        .desc {
          width:100%;
          position: relative;
          margin-top: 25px;
          padding:0 8%;
          font-size: 10px;
          line-height: 18px;
        }
      }
      .top_icon {
        width: 51px;
        height: 26px;
        top: 6%;
        left: 15px;
      }
    }

    .bgc {
      height: auto;
      z-index: 1;
      width: 100%;
      overflow: hidden;
      .bgc_img {
        img {
          width:100%;
          height:auto;
        }
      }
      .play_icon {
        width: 46px;
        height: 46px;
        top: 50%;
        right: 37%;
        transform: translate(-50%, -50%);
        img {
          width: 46px;
          height: 46px;
        }
      }
      .honr_line {
        height: 48px;
        width: 3px;
        top: 50%;
      }

      .bottom_line {
        width: 23px;
        height: 3px;
        left: -90%;
      }
    }
  }

`;

export default function TestimonialsReferral(props) {
  const { t } = useTranslation();
  const { info } = props;
  const [show, setShow] = useState(false);
  const lng = window.localStorage.getItem("i18nextLng");
  function playVideo() {
    const clientWidth = document.body.clientWidth;
    if (clientWidth <= "768") {
      props.history.push("/video-detail", {
        info: {
          link:
            window.localStorage.getItem("i18nextLng") === "en"
              ? "https://youtu.be/egy40V0Ve80"
              : window.localStorage.getItem("i18nextLng") === "zh-CN"
              ? "https://youtu.be/KD37I7O-Dtc"
              : "https://youtu.be/egy40V0Ve80",
          title: t("testimonials.ref_title"),
        },
      });
    } else {
      setShow(true);
    }
  }
  return (
    <>
      <TestimonialsReferralWrapper>
        <div className="box_bg"></div>
        <div className="content">
          <div className="position">
            <div className="title fontstyle_SB">
              {t("testimonials.referral-title")}
              <br /> {t("testimonials.referral-small-title")}
            </div>
            <div className="desc">
              <p> {t("testimonials.referral-desc1")} </p>
              <p> {t("testimonials.referral-desc2")} </p>
            </div>
          </div>
          <img src={top_icon} alt="" className="top_icon" />
        </div>
        <div
          className="bgc cursor"
          onClick={(e) => {
            playVideo();
          }}
        >
          <div className="bgc_img">
            <img src={lng == "en" ? bgc2 : bgc1} alt="" />
          </div>
          <div className="play_icon">
            <img src={play} alt="" />
          </div>
          <div className="honr_line"></div>
          <div className="bottom_line"></div>
        </div>
      </TestimonialsReferralWrapper>
      <GTModalVideo
        show={show}
        // videourl={info}
        setShow={setShow}
        id={"TestimonialsReferral"}
        videourl={{
          link:
            window.localStorage.getItem("i18nextLng") === "en"
              ? "https://youtu.be/egy40V0Ve80"
              : window.localStorage.getItem("i18nextLng") === "zh-CN"
              ? "https://youtu.be/KD37I7O-Dtc"
              : "https://youtu.be/egy40V0Ve80",
          title: t("testimonials.ref_title"),
        }}
      />
    </>
  );
}
